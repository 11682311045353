import { gql } from '@apollo/client';

export const FIND_DAYS_TO_SCHEDULE = gql`
    query findDaysToSchedule(
        $installationId: ID!
        $filter: ScheduleDateFilter
        $interventionTime: MaintenanceInterventionTime
    ) {
        findDaysToSchedule(
            installationId: $installationId
            filter: $filter
            interventionTime: $interventionTime
        ) {
            dayDate
            timeSlots {
                start
                end
            }
        }
    }
`;
