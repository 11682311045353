import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ENV, { PATHS } from '../../config/environment';
import HTTP_CLIENT from '../../services/http-client';
import { UserState } from '../../types';

const persistToken = (token: string | null) => {
    if (token) localStorage.setItem(ENV.REACT_APP_TOKEN_KEY, token);
};

const persistedToken: string | null =
    localStorage.getItem(ENV.REACT_APP_TOKEN_KEY) || null;

const cleanToken = () => localStorage.removeItem(ENV.REACT_APP_TOKEN_KEY);

const initialState = {
    access_token: persistedToken,
    pending: false,
    error: false,
} as UserState;

export const login: AsyncThunk<
    UserState,
    {
        email: string;
        password: string;
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
> = createAsyncThunk(
    'users/login',
    async ({ email, password }: { email: string; password: string }) => {
        try {
            const { data } = await HTTP_CLIENT.post(PATHS.LOGIN, {
                email,
                password,
            });
            persistToken(data.access_token);
            return data;
        } catch (err) {
            throw new Error('AUTH.ERROR_AUTHENTICATING');
        }
    },
);

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        logout(state) {
            state.access_token = null;
            cleanToken();
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(login.fulfilled, (state, action) => {
            // Add user to the state array
            state.access_token = action.payload.access_token;
            state.pending = false;
            state.error = false;
        });

        builder.addCase(login.pending, (state) => {
            state.access_token = null;
            state.pending = true;
            state.error = false;
        });

        builder.addCase(login.rejected, (state, { error }) => {
            state.access_token = null;
            state.pending = false;
            state.error = error.message as string;
        });
    },
});

export default userSlice.reducer;
export const { logout } = userSlice.actions;
