import React, { FC, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { State } from 'country-state-city';
import { GET_USER_BY_ID } from '../../graphql/queries/q-get-user-by-id';
import ENV from '../../config/environment';

interface IUserModalProps {
    userId: string;
}

interface IUserItem {
    name: string;
    info?: string;
}

const UserInfoModal: FC<IUserModalProps> = ({ userId }) => {
    const { t } = useTranslation();
    const { data } = useQuery(GET_USER_BY_ID, {
        variables: { id: userId },
    });

    const getDistrict = useCallback((userDistrict: number) => {
        return State.getStatesOfCountry(ENV.REACT_APP_COUNTRY_CODE)[
            userDistrict
        ]?.name;
    }, []);

    const renderItem = useCallback(({ info, name }) => {
        return (
            <>
                <Typography variant="h6" fontWeight="bold">
                    {name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {info}
                </Typography>
            </>
        );
    }, []);

    const itemsList = useMemo<IUserItem[]>(
        () => [
            {
                name: t('GENERAL.FULL_NAME'),
                info: data?.getUserById?.fullname,
            },
            {
                name: t('GENERAL.EMAIL'),
                info: data?.getUserById?.email,
            },
            {
                name: t('GENERAL.PHONE_NUMBER'),
                info: data?.getUserById?.phoneNumber,
            },
            {
                name: t('GENERAL.ADDRESS'),
                info: `${getDistrict(data?.getUserById?.district) ?? ''} ${
                    data?.getUserById?.city
                }`,
            },
        ],
        [data],
    );

    return <>{itemsList?.map((item: IUserItem) => renderItem({ ...item }))}</>;
};

export { UserInfoModal };
