import React, { ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { MAINTENANCE_STATUS, UploadFileType, ViewsPaths } from '../types';
import { FILE_SIZE_LIMIT, VIDEO_SIZE_LIMIT } from '../constants';
import HTTP_CLIENT from '../services/http-client';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import useCommonStyles, { themeColors } from '../style/common';

export const mongodbDateParser = (mongoDate: string) => {
    const date = new Date(mongoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const fileUploadHandler = (
    {
        target: {
            files: [file],
        },
    }: any,
    stateVariable: React.Dispatch<React.SetStateAction<any>>,
    fileType: UploadFileType,
) => {
    const reader = new FileReader();

    reader.onloadend = () => {
        if (file.size > FILE_SIZE_LIMIT) return;

        if (fileType === UploadFileType.IMAGE) {
            stateVariable({ file, avatar: reader.result as ArrayBuffer });
        }

        if (fileType === UploadFileType.TECHNICAL_FILE) {
            stateVariable({ file });
        }
    };

    reader.readAsDataURL(file as Blob);
};

export const fileHandler = ({
    file,
    fileType,
}: {
    file: any;
    fileType: UploadFileType;
}) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const renderFile = (reader.onloadend = () => {
        try {
            const maxFileSize =
                fileType === UploadFileType.IMAGE
                    ? FILE_SIZE_LIMIT
                    : VIDEO_SIZE_LIMIT;

            if (file.size > maxFileSize) throw new Error('FILE_SIZE_EXCEEDED');

            if (fileType === UploadFileType.IMAGE) {
                return { file, avatar: reader.result as ArrayBuffer };
            }

            return { file };
        } catch (e) {}
    });

    return renderFile();
};

export const uploadFileToServer = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await HTTP_CLIENT.post('/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const parseDraftContentToRawJSON = (editorState: EditorState) => {
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
};

export const createDraftContentFromRawJSON = (rawJSON: string) => {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(rawJSON)));
};

export const parseDateToYearMonth = (date: string | number) => {
    return dayjs(date).format('YYYY-MM');
};

export const getFullDate = (date: string | number | Dayjs) => {
    return dayjs(date).format('YYYY-MM-DD');
};

export const getTimeFromDate = (date: number | string) => {
    return dayjs(date).format('HH:mm');
};

export const validForm = (errors: any, dirty: boolean) =>
    !Object.keys(errors).length && dirty;

export const nPages = (length: number, nPerPage: number) =>
    Math.ceil(length / nPerPage);

export const displayConditionaly = (
    assertion: boolean,
    elemOnSuccess: ReactNode,
    elemOnFail: ReactNode,
) => {
    if (assertion) return elemOnSuccess;

    return elemOnFail;
};

export const dataGridDefaults = () => {
    const { dataGridDisabledSeparator } = useCommonStyles();

    return {
        headerClassName: dataGridDisabledSeparator,
        hideSortIcons: true,
        disableColumnMenu: true,
        minWidth: 200,
        width: 250,
        flex: 1,
    };
};

const basePath = '/shell';

export const Routes = {
    LOGIN: `${basePath}/${ViewsPaths.LOGIN}`,
    DASHBOARD: `${basePath}/${ViewsPaths.DASHBOARD}`,
    MAINTENANCES: `${basePath}/${ViewsPaths.MAINTENANCES}`,
    INSTALLATIONS: `${basePath}/${ViewsPaths.INSTALLATIONS}`,
    USERS: `${basePath}/${ViewsPaths.USERS}`,
    SETTINGS: `${basePath}/${ViewsPaths.SETTINGS}`,
    HIGHLIGHTS: `${basePath}/${ViewsPaths.HIGHLIGHTS}`,
};

export const getStatus = (
    params: MAINTENANCE_STATUS,
): { color: string; text: string } => {
    switch (params) {
        case MAINTENANCE_STATUS.DONE:
            return {
                color: themeColors.success,
                text: 'MAINTENANCE.STATUS.DONE',
            };
        case MAINTENANCE_STATUS.TO_SCHEDULE:
            return {
                color: themeColors.pending,
                text: 'MAINTENANCE.STATUS.TO_SCHEDULE',
            };
        case MAINTENANCE_STATUS.SCHEDULED:
            return {
                color: themeColors.success,
                text: 'MAINTENANCE.STATUS.SCHEDULED',
            };
        default:
            return {
                color: themeColors.error,
                text: '',
            };
    }
};

export const getDateWithFullMonth = (date: number) => {
    const month = new Date(date).toLocaleString('default', {
        month: 'long',
    });
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${day} ${month} ${year}`;
};

export const schedulerAppointmentColor = (status: string) => {
    switch (status) {
        case MAINTENANCE_STATUS.DONE:
            return themeColors.success;
        case MAINTENANCE_STATUS.SCHEDULED:
            return themeColors.pending;
        case MAINTENANCE_STATUS.CANCELLED:
            return themeColors.error;
        default:
            return themeColors.black;
    }
};

export const arraysAreEqual = (arr1: any[], arr2: any[]) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
};
