import { ICity, IState } from 'country-state-city';
import {
    GridEditMode,
    GridEventListener,
    GridFeatureMode,
    GridSortDirection,
} from '@mui/x-data-grid';

export type SharedDataGridRows =
    | IInstallationGrid[]
    | IInstallationMaintenanceGrid[]
    | IMaintenanceGrid[]
    | IUserGrid[]
    | IScheduledDataGrid[]
    | IUserInstallationsRow[]
    | IMaintenanceRows[];

export interface ShellMenuListItems {
    text: string;
    icon: JSX.Element;
    linkPath: string;
}

export interface UserState {
    access_token: string | null;
    pending: boolean;
    error: false | string;
}

export interface IInstallationGrid {
    description: string;
    name: string;
    user: {
        fullname: string;
        email: string;
        _id: string;
    };
}
export interface IUserGrid {
    _id: string;
    fullname: string;
    confirmed: boolean;
    email: string;
    createdAt: string;
}

export interface IInstallationMaintenanceGrid {
    name: string;
    frequency: string;
    next_date: string;
    status: string;
}

export interface IUserGrid {
    _id: string;
    fullName: string;
    email: string;
    confirmed: boolean;
    roles: string[];
}
export interface IMaintenanceGrid {
    _id: string;
    name: string;
    periodicity: string;
    interventionTime: string;
}

export interface TechnicalFileItem {
    file: File;
    fileName: string;
    fileID: string;
}

export interface ImageUploadItem {
    file: File;
    avatar: ArrayBuffer;
}
export interface DataGridConfig {
    currentPage: number;
    rowsPerPage: number;
    totalPages: number;
}

export interface DataGridPropsWithDefaultValues {
    autoHeight?: boolean;
    autoPageSize?: boolean;
    checkboxSelection?: boolean;
    checkboxSelectionVisibleOnly?: boolean;
    disableExtendRowFullWidth?: boolean;
    disableColumnFilter?: boolean;
    disableColumnMenu?: boolean;
    disableColumnSelector?: boolean;
    disableMultipleColumnsFiltering?: boolean;
    disableMultipleSelection?: boolean;
    disableMultipleColumnsSorting?: boolean;
    disableSelectionOnClick?: boolean;
    editMode?: GridEditMode;
    filterMode?: GridFeatureMode;
    headerHeight?: number;
    hideFooter?: boolean;
    hideFooterPagination?: boolean;
    hideFooterRowCount?: boolean;
    hideFooterSelectedRowCount?: boolean;
    pagination?: true | undefined;
    paginationMode?: GridFeatureMode;
    rowHeight?: number;
    rowsPerPageOptions?: number[];
    rowSpacingType?: 'margin' | 'border';
    sortingOrder?: GridSortDirection[];
    sortingMode?: GridFeatureMode;
    disableColumnReorder?: boolean;
    disableColumnResize?: boolean;
    onRowClick?: GridEventListener<'rowClick'>;
}

export interface IUserForm {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    city: ICity;
    district: IState;
    address: string;
}

export interface IUser {
    _id: string;
    fullname: string;
    email: string;
    phoneNumber: string;
    confirmed: boolean;
    roles: UserRole[];
}

export interface IInstalations {
    _id: string;
    name: string;
    description: string;
    implementationDate: string;
    image: string;
    files: [TechFile];
    user: IUser;
}

export interface TechFile {
    _id: string;
    uri: string;
    label: string;
}

export interface asd {
    target: {
        files: [File];
    };
}

export enum UploadFileType {
    TECHNICAL_FILE = 'technicalFile',
    IMAGE = 'image',
    VIDEO = 'video',
}

export enum CreateUserControl {
    FIRSTNAME = 'firstName',
    LASTNAME = 'lastName',
    EMAIL = 'email',
    PHONENUMBER = 'phoneNumber',
    CITY = 'city',
    DISTRICT = 'district',
    ADDRESS = 'address',
}

export enum UserRole {
    DEFAULT = 'DEFAULT',
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
}

export enum MaintenanceDataGridFields {
    NAME = 'name',
    PERIODICITY = 'periodicity',
    INTERVENTION_TIME = 'interventionTime',
    ACTIONS = 'actions',
}

export enum UserDataGridFields {
    USER = 'user',
    NEXTAPPOINTMENT = 'nextAppointment',
    EMAIL = 'email',
    CONFIRMED = 'confirmed',
    ACTIONS = 'actions',
}

export enum ScheduleDataGrid {
    NAME = 'name',
    SCHEDULE_DATE = 'scheduleDate',
    SCHEDULE_TIME = 'scheduleTime',
    STATUS = 'status',
    ACTIONS = 'actions',
    UPLOADED_BY = 'reportUploader',
}

export enum MaintenanceAction {
    CREATE = 'create',
    EDIT = 'edit',
}

export interface ISelectItem {
    value: string;
    label: string;
}

export type UploadImages = {
    file: File | undefined;
    _id: string | undefined;
    avatar: ArrayBuffer;
};

export type InstallationsVideos = {
    _id: string;
    url: string;
    title: string;
};

export enum COMPONENT_TASK {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW_ONLY = 'viewOnly',
}

export enum AlertModalSeverity {
    error = 'error',
    warning = 'warning',
    info = 'info',
    success = 'success',
}

export enum DIALOG_STATE {
    CONFIRMED = 'confirmed',
    DISMISSED = 'dismissed',
}
export type IMaintenancesDatagrid = {
    _id: string;
    name: string;
    periodicity: string;
    nextMaintenance: string;
    status: string;
    actions: string;
};

export enum MaintenanceColumns {
    NAME = 'name',
    PERIODICITY = 'periodicity',
    NEXT_MAINTENANCE = 'nextMaintenanceDate',
    STATUS = 'status',
    ACTIONS = 'actions',
}

export interface IUserInstallationsRow {
    name: string;
    nextMaintenanceDate: string;
    status: string;
}

export interface IMaintenanceRows {
    _id: string;
    name: string;
    periodicity: string;
    nextMaintenance: string;
    status: MAINTENANCE_STATUS;
}
export enum MAINTENANCE_STATUS {
    TO_SCHEDULE = 'TO_SCHEDULE',
    DONE = 'DONE',
    SCHEDULED = 'SCHEDULED',
    CANCELLED = 'CANCELLED',
}

export interface IScheduledDataGrid {
    _id: string;
    name: string;
    scheduleDate: string;
    scheduleTime: string;
    status: MAINTENANCE_STATUS;
    actions: ScheduleActions;
}

export enum ScheduleActions {
    SendReport = 'SEND_REPORT',
    ViewReport = 'VIEW_REPORT',
    Confirm = 'CONFIRM',
}

export enum MaintenancePeriodicity {
    Yearly = 'YEARLY',
    SemiAnnual = 'SEMIANNUAL',
    Quarterly = 'QUARTERLY',
    UNIQUE = 'UNIQUE',
    BeWeekly = 'BE_WEEKLY',
    Weekly = 'WEEKLY',
    Daily = 'DAILY',
}

export interface IMaintenance {
    _id: string;
    description: string;
    name: string;
    interventionTime: MaintenanceInterventionTime;
    periodicity: MaintenancePeriodicity;
}

export enum MaintenanceInterventionTime {
    halfHours = 'HALF_HOURS',
    oneHours = 'ONE_HOURS',
    oneHalfHours = 'ONE_HALF_HOURS',
    twoHours = 'TWO_HOURS',
    twoHalfHours = 'TWO_HALF_HOURS',
    threeHours = 'THREE_HOURS',
    threeHalfHours = 'THREE_HALF_HOURS',
    fourHours = 'FOUR_HOURS',
    fourHalfHours = 'FOUR_HALF_HOURS',
}

export interface IPromptActions {
    isVisible: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onClose: () => void;
}

export enum INPUT_TYPE {
    PASSWORD = 'password',
    TEXT = 'text',
    EMAIL = 'email',
    NUMBER = 'number',
}

export interface SettingsData {
    minDaysLeftToCancelSchedules: string;
    maxInterventionsAtTime: string;
}

export enum TypeOfSettings {
    MINDAYS = 'minDaysLeftToCancelSchedules',
    MAXDAYS = 'maxInterventionsAtTime',
}

export const IPromptActionsInitialState = {
    isVisible: false,
    title: '',
    message: '',
    onClose: () => undefined,
    onConfirm: () => undefined,
};

export interface HighlightsData {
    _id: string;
    title: string;
    descriptionHtml: string;
    isStarred: boolean;
    image: string;
}

export interface ListInfo {
    step: number;
    limit: number;
    term: string;
    total: number;
    skip: number;
}

export interface findHighlights {
    currentStarred: HighlightsData[];
    highlights: HighlightsData[];
    listInfo: ListInfo;
}

export enum HighlightViewAction {
    EDIT = 'edit',
    CREATE = 'create',
    VIEW_ONLY = 'view',
}

export enum UsersTypeOfViews {
    NEW = 'new',
}

export enum ViewsPaths {
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    MAINTENANCES = 'maintenances',
    INSTALLATIONS = 'installations',
    USERS = 'users',
    SETTINGS = 'settings',
    HIGHLIGHTS = 'highlights',
}

export interface ISchedulerAppointment {
    id: number;
    title: string;
    startDate: Date;
    endDate: Date;
    location: string;
    startHour: string;
    endHour: string;
    installationId: string;
    status: string;
    image: string;
}

export enum AnalyticsNames {
    NEW_SCHEDULES = 'NEW_SCHEDULES',
    NEW_USERS = 'NEW_USERS',
}

export enum AnalyticsTimePeriod {
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_MONTH = 'LAST_MONTH',
    LAST_3_MONTHS = 'LAST_3_MONTHS',
}

export interface AnalyticToSearch {
    name: AnalyticsNames;
    timePeriod: string;
}

export interface CalculatedAnalytic {
    name: AnalyticsNames;
    value: number;
    count: number;
}

export interface IUploadFileResponse {
    createdAt: string;
    isDeleted: false;
    name: string;
    providerId: string;
    updatedAt: string;
    uri: string;
    _id: string | undefined;
}

export const UploadFileResponse: IUploadFileResponse = {
    createdAt: '',
    isDeleted: false,
    name: '',
    providerId: '',
    updatedAt: '',
    uri: '',
    _id: undefined,
};

export enum CalendarChipAction {
    REMOVE = 'REMOVE',
    ADD = 'ADD',
}

export interface ICalendarDates {
    action?: CalendarChipAction;
    date: Date;
}
