import React, { FC, memo } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import useCommonStyles, {
    themeColors,
    useStructureStyles,
} from '../../style/common';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface ICardProps {
    headerTitle: string | number;
    headerPercentage: number;
    bodyTitle: string | number;
    bodySubtitle: string;
}

const InformationCard: FC<ICardProps> = memo(
    ({ headerTitle, headerPercentage, bodyTitle, bodySubtitle }) => {
        const { FLEX_COL_CENTER } = useStructureStyles();
        const { informationCard } = useCommonStyles();

        const isPercentagePositive = headerPercentage > 0;
        const percentageInfo = {
            color: isPercentagePositive
                ? themeColors.primary
                : themeColors.error,
            icon: isPercentagePositive ? (
                <ArrowUpwardIcon color="primary" />
            ) : (
                <ArrowDownwardIcon color="error" />
            ),
        };

        return (
            <Card className={informationCard}>
                <>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <Box display="flex">
                            <Grid item xs={8}>
                                <Typography variant="body1" fontWeight="bold">
                                    {headerTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    display="flex"
                                    justifyContent="end"
                                    alignItems="center"
                                >
                                    {percentageInfo.icon}
                                    <Typography
                                        variant="body1"
                                        color={percentageInfo.color}
                                        fontWeight="bold"
                                    >
                                        {headerPercentage}%
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                    <Box className={FLEX_COL_CENTER}>
                        <Typography variant="h4">{bodyTitle}</Typography>
                        <Typography variant="subtitle2">
                            {bodySubtitle}
                        </Typography>
                    </Box>
                </>
            </Card>
        );
    },
);

export { InformationCard };
