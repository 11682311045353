import { gql } from '@apollo/client';

export const STARR_HIGHLIGHT = gql`
    mutation starrHighlightById($highlightId: ID!) {
        starrHighlightById(highlightId: $highlightId) {
            _id
            title
            descriptionHtml
            isStarred
            image
        }
    }
`;
