import { City, State } from 'country-state-city';

export const getDistricts = () => {
    const districts = State.getStatesOfCountry('PT').map((state) => {
        return {
            value: state.isoCode,
            label: state.name,
        };
    });

    return districts;
};

export const getCities = (districtCode: string) => {
    const cities = City.getCitiesOfState('PT', districtCode).map((city) => {
        return {
            value: city.name,
            label: city.name,
        };
    });

    return cities;
};

export const getCity = (districtCode: string, cityName: string) => {
    const city = getCities(districtCode).filter(
        (_city) => _city.value === cityName,
    );

    return city[0];
};
