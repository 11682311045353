import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';

interface IUploadButtonProps {
    onChange?: any;
    children: React.ReactNode;
    className?: string;
    variant?: 'contained' | 'outlined' | 'text';
    style?: React.CSSProperties;
    icon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
}

const UploadButton: FC<IUploadButtonProps> = ({
    onChange,
    children,
    className,
    disabled,
    variant,
    style,
    icon,
    isLoading,
}: IUploadButtonProps) => {
    return (
        <LoadingButton
            variant={variant ? 'outlined' : variant}
            component="label"
            className={className}
            sx={style}
            disabled={disabled}
            startIcon={icon}
            loading={isLoading}
        >
            <input
                type="file"
                hidden
                onChange={onChange}
                accept="image/jpg, image/jpeg, image/png, application/pdf, video/mp4, video/mpeg, video/x-msvideo"
            />
            {children}
        </LoadingButton>
    );
};

export { UploadButton };
