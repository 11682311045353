import React, { FC } from 'react';
import clsx from 'clsx';
import {
    FormControl,
    InputProps,
    SxProps,
    TextField,
    TextFieldProps,
    Theme,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../style/common';
import { INPUT_TYPE } from '../../../types';

interface IControlledInputProps {
    name: string;
    control: Control<any>;
    label?: string;
    rules?: any;
    defaultValue?: string | number;
    className?: string;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    params?: TextFieldProps;
    inputProps?: InputProps;
    type?: INPUT_TYPE;
    cValue?: string;
    fullWidth?: boolean;
}

const ControlledInput: FC<IControlledInputProps> = ({
    name,
    control,
    rules,
    label,
    defaultValue,
    className,
    disabled,
    params,
    type,
    inputProps,
    cValue,
    fullWidth = true,
}: IControlledInputProps) => {
    const { t } = useTranslation();
    const { width100 } = useCommonStyles();

    return (
        <FormControl className={clsx(fullWidth && width100)}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ?? ''}
                rules={rules}
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => {
                    return (
                        <TextField
                            type={type ?? INPUT_TYPE.TEXT}
                            error={!!error}
                            name={name}
                            label={label}
                            onChange={onChange}
                            value={cValue ? cValue : value}
                            className={className}
                            disabled={disabled}
                            {...params}
                            helperText={
                                error &&
                                error?.message &&
                                (t(error?.message) as string)
                            }
                            inputProps={
                                inputProps && {
                                    ...inputProps.inputProps,
                                }
                            }
                        />
                    );
                }}
            />
        </FormControl>
    );
};

export { ControlledInput };
