import { useState } from 'react';

const useDebounce = () => {
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const debounceSearch = (
        func?: () => void,
        term?: string,
        setTerm?: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        clearTimeout(timer as NodeJS.Timeout);

        const bounce = setTimeout(() => {
            if (func) func();
            if (setTerm) setTerm(term ? term : '');
        }, 1000);
        setTimer(bounce);
    };

    return [debounceSearch];
};

export { useDebounce };
