import { gql } from '@apollo/client';

export const GET_MAINTENANCES_BY_INSTALLATION_ID = gql`
    query findMaintenancesByInstallationId($installationId: ID!) {
        findMaintenancesByInstallationId(installationId: $installationId) {
            _id
            descriptionHtml
            nextMaintenanceDate
            status
            name
            periodicity
        }
    }
`;
