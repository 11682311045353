import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import clsx from 'clsx';
import { CalendarPicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { SharedHeader } from '../components/shared-components/shared-header';
import { AlertModalContext } from '../context/alertModal-context';

import { GET_SETTINGS } from '../graphql/queries/q-get-settings';
import { UPDATE_SETTINGS } from '../graphql/mutations/m-update-settings';
import useCommonStyles from '../style/common';
import {
    AlertModalSeverity,
    CalendarChipAction,
    ICalendarDates,
    INPUT_TYPE,
    SettingsData,
    TypeOfSettings,
} from '../types';
import { BlockedDates, ControlledInput } from '../components';
import dayjs from 'dayjs';
import { FIND_BLOCKED_DAYS } from '../graphql/queries/q-find-blocked-days';
import { MANAGE_BLOCKED_DATES } from '../graphql/mutations/m-manage-blocked-dates';

const form = [
    {
        id: TypeOfSettings.MINDAYS,
        title: 'SETTINGS.MAX_NUMBER_OF_DAYS',
        description: 'SETTINGS.DESCRIPTION_MAX_NUMBER_OF_DAYS',
        editMode: false,
    },
    {
        id: TypeOfSettings.MAXDAYS,
        title: 'SETTINGS.NUMBER_OF_INTERVENTIONS',
        description: 'SETTINGS.DESCRIPTION_NUMBER_OF_INTERVENTIONS',
        value: false,
    },
];

const today = dayjs();
const todayPlusOneYear = dayjs().add(1, 'year');

const Settings = () => {
    const {
        spacingBottomLarge,
        spacingBottomSmall3,
        spacingLeftSmall1,
        spacingLeftSmall3,
        cursorPointer,
        spacingRightSmall2,
        tooltipInfoIcon,
        calendarWrapper,
        calendarDay,
    } = useCommonStyles();

    const { t } = useTranslation();
    const [formValues, setFormValues] = useState(form);

    const { showAlertModal } = useContext(AlertModalContext);
    const [currentCalendarDate, setCurrentCalendarDate] = useState<Date>(
        new Date(),
    );
    const [blockedDates, setBlockedDates] = useState<ICalendarDates[]>([]);
    const [manageBlockedDates] = useMutation(MANAGE_BLOCKED_DATES);

    const { handleSubmit, control, reset } = useForm();

    const [updateSettings] = useMutation(UPDATE_SETTINGS);

    useQuery(FIND_BLOCKED_DAYS, {
        variables: {
            dateRange: {
                startDate: today,
                endDate: todayPlusOneYear,
            },
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const normalizeData = data?.findBlockedDays.map(
                (_date: string) => ({
                    action: CalendarChipAction.ADD,
                    date: dayjs(_date),
                }),
            );

            setBlockedDates((prev) => [...prev, ...normalizeData]);
        },
    });

    const { data: settingsData, refetch } = useQuery<{
        getSettings: SettingsData;
    }>(GET_SETTINGS, {
        onCompleted(data) {
            const { minDaysLeftToCancelSchedules, maxInterventionsAtTime } =
                data.getSettings;
            reset({
                minDaysLeftToCancelSchedules,
                maxInterventionsAtTime,
            });
        },
    });

    const editButton = (index: number) => {
        form[index].editMode = true;
        setFormValues([...form]);
    };

    const saveEdit = (data: SettingsData) => {
        const body = {
            maxInterventionsAtTime: parseInt(data.maxInterventionsAtTime),
            minDaysLeftToCancelSchedules: parseInt(
                data.minDaysLeftToCancelSchedules,
            ),
        };
        updateSettings({
            variables: {
                input: body,
            },
        })
            .then(() => {
                form.forEach((item, index) => (form[index].editMode = false));
                setFormValues([...form]);
                refetch();
                showAlertModal(
                    t('ALERTS.CHANGES_SUCCESSFULLY_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
            })
            .catch(() => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            });

        manageBlockedDates({
            variables: {
                dateEvents: blockedDates,
            },
        })
            .then(() => {
                showAlertModal(
                    t('ALERTS.CHANGES_SUCCESSFULLY_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
            })
            .catch(() => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            });
    };

    const cancelAction = () => {
        formValues.map((item) => (item.editMode = false));
        setFormValues([...formValues]);
    };

    const onDateSelect = (d: unknown | null) => {
        if (!d) return;

        setBlockedDates((prev) => {
            const date: Date = d as Date;

            const index = prev.findIndex((item) => {
                return (
                    dayjs(item.date).isSame(date, 'day') &&
                    dayjs(item.date).isSame(date, 'month')
                );
            });

            if (index === -1) {
                return [
                    ...prev,
                    {
                        action: CalendarChipAction.ADD,
                        date,
                    },
                ];
            }

            const updatedDates = [...prev];
            updatedDates.splice(index, 1, {
                action: CalendarChipAction.ADD,
                date,
            });

            return updatedDates;
        });
    };

    const onDateRemove = (dateToRemove: Date) => {
        setBlockedDates((prev) => {
            const index = prev.findIndex((item) => {
                return dayjs(item.date).isSame(dateToRemove, 'day');
            });

            if (index === -1) {
                return prev;
            }

            const updatedDates = [...prev];
            updatedDates.splice(index, 1, {
                action: CalendarChipAction.REMOVE,
                date: dateToRemove,
            });

            return updatedDates;
        });
    };

    const isDayDisabled = (d: unknown) => {
        const date = d as Date;

        const isWeekend = dayjs(date).day() === 6 || dayjs(date).day() === 0;
        if (isWeekend) return true;

        const blockedDateIndex = blockedDates.findIndex((selectedDate) => {
            return dayjs(selectedDate.date).isSame(date, 'day');
        });

        if (blockedDateIndex === -1) return false;

        const isAddAction =
            blockedDates[blockedDateIndex].action === CalendarChipAction.ADD;

        return blockedDates.some((selectedDate) => {
            return dayjs(selectedDate.date).isSame(date, 'day') && isAddAction;
        });
    };

    return (
        <Box>
            <SharedHeader
                title={{
                    content: t('GENERAL.GENERAL_SETTINGS'),
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            >
                <Box>
                    <Button
                        variant="outlined"
                        className={spacingRightSmall2}
                        onClick={cancelAction}
                        size="large"
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleSubmit(saveEdit as any)}
                        size="large"
                    >
                        {t('GENERAL.SAVE')}
                    </Button>
                </Box>
            </SharedHeader>

            {formValues.map((item, index) => {
                const description = t(item.description);
                return (
                    <Box key={index} sx={{ mt: index !== 0 ? 3 : 0 }}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" fontWeight="bold">
                                {t(item.title)}
                            </Typography>
                            <Tooltip title={description} arrow placement="top">
                                <InfoIcon
                                    className={clsx(
                                        spacingBottomSmall3,
                                        spacingLeftSmall1,
                                        cursorPointer,
                                        tooltipInfoIcon,
                                    )}
                                    fontSize="small"
                                />
                            </Tooltip>
                        </Box>

                        {item.editMode ? (
                            <ControlledInput
                                name={item.id}
                                label=""
                                defaultValue=""
                                control={control}
                                type={INPUT_TYPE.NUMBER}
                                inputProps={{ inputProps: { min: 0 } }}
                                fullWidth={false}
                            />
                        ) : (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">
                                    {index === 0
                                        ? settingsData?.getSettings
                                              .minDaysLeftToCancelSchedules
                                        : settingsData?.getSettings
                                              .maxInterventionsAtTime}
                                </Typography>
                                <EditIcon
                                    className={clsx(
                                        spacingLeftSmall3,
                                        cursorPointer,
                                    )}
                                    fontSize="small"
                                    onClick={() => {
                                        editButton(index);
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                );
            })}

            <Grid item xs mt={4}>
                <Box display="flex" gap={1}>
                    <Typography variant="body1" fontWeight="bold">
                        {t('GENERAL.BLOCK_DATES')}
                    </Typography>
                    <Tooltip
                        title={t('GENERAL.BLOCKED_DATES_INFO')}
                        arrow
                        placement="top"
                    >
                        <InfoIcon
                            className={clsx(
                                spacingBottomSmall3,
                                spacingLeftSmall1,
                                cursorPointer,
                                tooltipInfoIcon,
                            )}
                            fontSize="small"
                        />
                    </Tooltip>
                </Box>
                <Box className={calendarWrapper}>
                    <CalendarPicker
                        date={null}
                        onChange={onDateSelect}
                        shouldDisableDate={isDayDisabled}
                        disableHighlightToday
                        maxDate={dayjs().add(1, 'year')}
                        minDate={dayjs().add(1, 'day')}
                        onMonthChange={(date) =>
                            setCurrentCalendarDate(date.toDate())
                        }
                        className={calendarDay}
                    />
                    <BlockedDates
                        dates={blockedDates.filter((day) =>
                            dayjs(day.date).isSame(
                                currentCalendarDate,
                                'month',
                            ),
                        )}
                        onDateRemove={onDateRemove}
                    />
                </Box>
            </Grid>
        </Box>
    );
};

export { Settings };
