import React, { useContext } from 'react';
import useCommonStyles, { alertsStyle, themeColors } from '../../style/common';
import { AlertModalContext } from '../../context/alertModal-context';
import { AlertModalSeverity } from '../../types';
import {
    Alert,
    AlertTitle,
    Box,
    Grid,
    IconButton,
    Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AlertModalComponent() {
    const alertStyles = alertsStyle();
    const utilsClasses = useCommonStyles();
    const alertModal = useContext(AlertModalContext);
    const alertSideColor =
        alertModal.severity === AlertModalSeverity.error
            ? themeColors.error
            : themeColors.success;

    return (
        <Snackbar
            open={alertModal.visibility}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                variant="filled"
                icon={false}
                severity={alertModal.severity}
                className={alertStyles.root}
            >
                <Box display="flex" className={alertStyles.alertWrapper}>
                    <Grid item xs={10} className={utilsClasses.paddingSmall3}>
                        <AlertTitle
                            className={alertStyles.alertTitle}
                            style={{
                                color: alertSideColor,
                            }}
                        >
                            {alertModal.title}
                        </AlertTitle>
                        {alertModal.message}
                    </Grid>
                    <Grid item xs={2} className={utilsClasses.height100}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                            className={utilsClasses.height100}
                        >
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={alertModal.hideAlertModal}
                                className={alertStyles.alertCloseButton}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Box>
            </Alert>
        </Snackbar>
    );
}
