import {
    AnalyticToSearch,
    AnalyticsNames,
    AnalyticsTimePeriod,
} from '../types';

export const INIT_ANALYTICS_STATE: Array<AnalyticToSearch> = [
    {
        name: AnalyticsNames.NEW_SCHEDULES,
        timePeriod: AnalyticsTimePeriod.LAST_7_DAYS,
    },
    {
        name: AnalyticsNames.NEW_USERS,
        timePeriod: AnalyticsTimePeriod.LAST_7_DAYS,
    },
];

export const SELECTS_CONTENT = [
    {
        label: 'Last 7 days',
        value: AnalyticsTimePeriod.LAST_7_DAYS,
    },
    {
        label: 'Last Month',
        value: AnalyticsTimePeriod.LAST_MONTH,
    },
    {
        label: 'Last 3 Months',
        value: AnalyticsTimePeriod.LAST_3_MONTHS,
    },
];
