import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const blockedDatesStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipsWrapper: {
            marginTop: '1vh',
            display: 'flex',
            alignItems: 'center',

            alignContent: 'flex-start',
            flexWrap: 'wrap',
            flex: 1,
            gap: '2vh',

            maxWidth: '60%',
            maxHeight: '32vh',

            [theme.breakpoints.down('sm')]: {
                maxWidth: 'unset',
            },
        },
    }),
);
