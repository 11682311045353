import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { EditorState, convertToRaw } from 'draft-js';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../style/common';
import { Box, Button, Grid } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { REQUIRED_FIELD, defaultDraftValue } from '../../constants';
import { AlertModalContext } from '../../context/alertModal-context';
import { HIGHLIGHTS_FORM_NAMES } from '../../constants/highlights-constants';
import {
    CREATE_HIGHLIGHT,
    DELETE_HIGHLIGHT_BY_ID,
    GET_HIGHLIGHT_BY_ID,
    UPDATE_HIGHLIGHT_BY_ID,
} from '../../graphql';
import {
    AlertModalSeverity,
    IPromptActions,
    IPromptActionsInitialState,
    UploadImages,
} from '../../types';
import {
    ControlledInput,
    ImagePlaceholder,
    SharedHeader,
    TextEditor,
} from '../../components';
import {
    Routes,
    createDraftContentFromRawJSON,
    displayConditionaly,
    parseDraftContentToRawJSON,
    uploadFileToServer,
} from '../../core/utils';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import { SharedLoading } from '../../components/shared-components/loading';
import * as _ from 'lodash';

interface ViewUrlParams {
    id: string;
}

export enum HighlightViewAction {
    EDIT = 'edit',
    CREATE = 'create',
    VIEW_ONLY = 'view',
}

const CreateEditHighlight = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { id: highlightId } = useParams<ViewUrlParams>();
    const { showAlertModal } = useContext(AlertModalContext);
    const {
        spacingTopSmall3,
        width100,
        spacingRightSmall2,
        createHighlightFormWrapper,
        createHighlightFormWrapperInput,
    } = useCommonStyles();
    const { control, reset, handleSubmit } = useForm();
    const [, setEditorContenChanged] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStarred, setIsStarred] = useState<boolean>(false);
    const [highlightImage, setHighlightImage] = useState<UploadImages>();
    const [promptActions, setPromptActions] = useState<IPromptActions>(
        IPromptActionsInitialState,
    );
    const [viewTask, setViewTask] = useState<HighlightViewAction>(
        highlightId !== HighlightViewAction.CREATE
            ? HighlightViewAction.VIEW_ONLY
            : HighlightViewAction.CREATE,
    );
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    const {
        data: highlightData,
        refetch: refetchHighlightData,
        loading: getHighlightLoading,
    } = useQuery(GET_HIGHLIGHT_BY_ID, {
        fetchPolicy: 'no-cache',
        skip: highlightId === HighlightViewAction.CREATE,
        variables: {
            highlightId,
        },
        onCompleted: (data) => {
            const { image: avatar, descriptionHtml } = data?.findHighlightById;
            reset(data?.findHighlightById);
            setIsStarred(data?.findHighlightById?.isStarred);
            setEditorState(createDraftContentFromRawJSON(descriptionHtml));
            setHighlightImage((prev: any) => ({
                ...prev,
                avatar,
            }));
        },
        onError: (err) => {
            showAlertModal(
                t(`ALERTS.${err.message}`),
                t('ALERTS.ERROR'),
                AlertModalSeverity.error,
            );
        },
    });

    const [createHighlight] = useMutation(CREATE_HIGHLIGHT);
    const [deleteHighlightById] = useMutation(DELETE_HIGHLIGHT_BY_ID);
    const [updateHighlightById] = useMutation(UPDATE_HIGHLIGHT_BY_ID);

    const onFormUpdate = async (
        title: string,
        descriptionHtml: string,
        imageId: string,
    ) => {
        updateHighlightById({
            variables: {
                highlightId,
                input: {
                    title,
                    descriptionHtml,
                    ...(imageId && { image: imageId }),
                },
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.HIGHLIGHT_UPDATED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                setViewTask(HighlightViewAction.VIEW_ONLY);
            },
        });
    };

    const onFormCreate = async (
        title: string,
        descriptionHtml: string,
        imageId: string,
    ) => {
        if (!imageId) {
            showAlertModal(
                t('ALERTS.IMAGE_REQUIRED'),
                t('ALERTS.ERROR'),
                AlertModalSeverity.error,
            );
            return;
        }

        createHighlight({
            variables: {
                input: {
                    title,
                    isStarred,
                    descriptionHtml,
                    image: imageId,
                },
            },
            onCompleted: (data) => {
                showAlertModal(
                    t('ALERTS.HIGHLIGHT_CREATED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                history.push(
                    `${Routes.HIGHLIGHTS}/${data?.createHighlight?._id}`,
                );
                setViewTask(HighlightViewAction.VIEW_ONLY);
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERROR_HIGHLIGHT_CREATED_FAILED_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
            },
        });
    };

    const onFormSubmission = async (data: { title: string }) => {
        setIsLoading(true);
        let imageId;
        const descriptionHtml =
            parseDraftContentToRawJSON(editorState) ?? defaultDraftValue;

        if (highlightImage?.file) {
            imageId = await uploadFileToServer(highlightImage?.file as File)
                .then((res) => res?.data?._id)
                .catch(() =>
                    showAlertModal(
                        t('ALERTS.UPLOAD_IMAGE_FAILED_MESSAGE'),
                        t('ALERTS.ERROR'),
                        AlertModalSeverity.error,
                    ),
                );
        }

        if (viewTask === HighlightViewAction.EDIT) {
            await onFormUpdate(data.title, descriptionHtml, imageId);
        } else {
            await onFormCreate(data.title, descriptionHtml, imageId);
        }

        refetchHighlightData();
        setIsLoading(false);
    };

    const handleDeleteHighlight = async (highlightId: string) => {
        deleteHighlightById({
            variables: {
                highlightId,
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.HIGHLIGHT_DELETED_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                history.push('/shell/highlights');
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERROR_HIGHLIGHT_DELETED_MESSAGE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    const editorContentChangeHandler = () => {
        const descriptionHtml =
            highlightId !== HighlightViewAction.CREATE &&
            JSON.parse(highlightData?.findHighlightById?.descriptionHtml);

        const imageChanged = !_.isEqual(
            descriptionHtml.entityMap,
            convertToRaw(editorState.getCurrentContent()).entityMap,
        );
        setEditorContenChanged(imageChanged);

        if (!imageChanged) {
            descriptionHtml?.blocks.map((block: any) => {
                const convertRawIndex = convertToRaw(
                    editorState.getCurrentContent(),
                ).blocks.findIndex(
                    (rawBlock: any) => rawBlock.text === block.text,
                );
                return setEditorContenChanged(convertRawIndex === -1);
            });
        }
    };

    function renderSharedHeaderContent() {
        return {
            [HighlightViewAction.CREATE]: (
                <>
                    <Button
                        size="large"
                        variant="outlined"
                        className={spacingRightSmall2}
                        onClick={() => history.goBack()}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={!!isLoading}
                        onClick={() => handleSubmit(onFormSubmission as any)()}
                        form={HIGHLIGHTS_FORM_NAMES.FORM_NAME}
                    >
                        {t('GENERAL.CREATE')}
                    </LoadingButton>
                </>
            ),
            [HighlightViewAction.VIEW_ONLY]: (
                <>
                    <Button
                        startIcon={<EditIcon />}
                        size="large"
                        variant="outlined"
                        className={spacingRightSmall2}
                        onClick={() => setViewTask(HighlightViewAction.EDIT)}
                    >
                        {t('GENERAL.EDIT_DATA')}
                    </Button>
                    <Button
                        startIcon={<DeleteForeverIcon />}
                        variant="outlined"
                        size="large"
                        color="error"
                        onClick={() =>
                            setPromptActions({
                                isVisible: true,
                                title: t('PROMPTS.REMOVE_HIGHLIGHT_TITLE'),
                                message: t('PROMPTS.REMOVE_HIGHLIGHT_MESSAGE'),
                                onClose: () => onDialogPromptClose,
                                onConfirm: () =>
                                    handleDeleteHighlight(highlightId),
                            })
                        }
                    >
                        {t('HIGHLIGHTS.DELETE_HIGHLIGHT')}
                    </Button>
                </>
            ),
            [HighlightViewAction.EDIT]: (
                <>
                    <Button
                        variant="outlined"
                        size="large"
                        className={spacingRightSmall2}
                        onClick={() => {
                            setViewTask(HighlightViewAction.VIEW_ONLY);
                        }}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        loading={!!isLoading}
                        form={HIGHLIGHTS_FORM_NAMES.FORM_NAME}
                        onClick={() => handleSubmit(onFormSubmission as any)()}
                    >
                        {t('GENERAL.SAVE')}
                    </LoadingButton>
                </>
            ),
        };
    }

    function onDialogPromptClose() {
        setPromptActions((prev: any) => ({
            ...prev,
            isVisible: false,
        }));
    }
    return (
        <>
            <SharedHeader
                title={{
                    content: highlightData?.findHighlightById?.title
                        ? highlightData?.findHighlightById?.title
                        : t('HIGHLIGHTS.NEW_HIGHLIGHT'),
                }}
            >
                <Box>{renderSharedHeaderContent()[viewTask]}</Box>
            </SharedHeader>

            {displayConditionaly(
                getHighlightLoading,
                <SharedLoading />,
                <Grid container className={spacingTopSmall3}>
                    <Box className={width100}>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            display="flex"
                            alignItems="center"
                            className={createHighlightFormWrapper}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={clsx(
                                    width100,
                                    createHighlightFormWrapperInput,
                                )}
                            >
                                <ControlledInput
                                    name={HIGHLIGHTS_FORM_NAMES.TITLE}
                                    label={t('GENERAL.TITLE')}
                                    control={control}
                                    rules={REQUIRED_FIELD}
                                    className={width100}
                                    disabled={
                                        viewTask ===
                                        HighlightViewAction.VIEW_ONLY
                                    }
                                    defaultValue={
                                        highlightData?.findHighlightById
                                            ?.title || ''
                                    }
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ImagePlaceholder
                                    image={highlightImage}
                                    setImage={setHighlightImage}
                                    disabled={
                                        viewTask ===
                                        HighlightViewAction.VIEW_ONLY
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12} className={spacingTopSmall3}>
                            <TextEditor
                                editorState={editorState}
                                setEditorState={setEditorState}
                                disabled={
                                    viewTask === HighlightViewAction.VIEW_ONLY
                                }
                                onChange={editorContentChangeHandler}
                            />
                        </Grid>
                    </Box>
                </Grid>,
            )}

            <ConfirmationDialog
                isOpen={promptActions?.isVisible}
                close={onDialogPromptClose}
                onConfirm={promptActions?.onConfirm}
                title={promptActions?.title}
                message={promptActions?.message ?? ''}
            />
        </>
    );
};

export { CreateEditHighlight };
