import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

const useWindowSize = () => {
    const theme = useTheme();
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

    useEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', updateSize);

        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return {
        windowWidth: size[0],
        windowHeight: size[1],
        isOnMobile: useMediaQuery(theme.breakpoints.down('sm')),
    };
};

export { useWindowSize };
