import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AlertModalContext } from '../../context/alertModal-context';
import {
    ControlledInput,
    ImagePlaceholder,
    SharedHeader,
    TextEditor,
} from '../../components';
import { ControlledSelect } from '../../components/shared-components/controlled-components/controlled-select';
import {
    MAINTENANCES_FORM_NAMES,
    MAINTENANCES_INTERVENTION_TIME,
    MAINTENANCES_PERIODICITY,
    REQUIRED_FIELD,
} from '../../constants';
import useCommonStyles, { useStructureStyles } from '../../style/common';
import { EditorState } from 'draft-js';
import {
    AlertModalSeverity,
    COMPONENT_TASK,
    INPUT_TYPE,
    IPromptActions,
    UploadFileResponse,
    UploadImages,
} from '../../types';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import {
    CREATE_MAINTENANCE,
    DELETE_MAINTENANCE,
    GET_MAINTENANCE_BY_ID,
    UPDATE_MAINTENANCE_BY_ID,
} from '../../graphql';
import {
    Routes,
    createDraftContentFromRawJSON,
    displayConditionaly,
    parseDraftContentToRawJSON,
    uploadFileToServer,
} from '../../core/utils';

import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import { SharedLoading } from '../../components/shared-components/loading';

export interface IMaintenanceActionsForm {
    name: string;
    interventionTime: string;
    periodicity: string;
    descriptionHtml: string;
    estimatedCost: number;
}

export interface CreateMaintenanceResponse {
    createMaintenance: {
        _id: string;
    };
}

const AddEditMaintenances = () => {
    const { FLEX_ROW_CENTER_END, FLEX_COL_START } = useStructureStyles();
    const {
        maintenanceFormWrapper,
        spacingBottomLarge,
        spacingBottomSmall3,
        spacingRightSmall2,
        maintenanceImage,
        maintenanceFormInputs,
    } = useCommonStyles();
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );
    const { id: maintenanceId, action: componentTask } = useParams<{
        id: string;
        action: string;
    }>();

    const history = useHistory();
    const { t } = useTranslation();
    const [image, setImage] = useState<UploadImages>();
    const { showAlertModal } = useContext(AlertModalContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handleSubmit, control, reset } = useForm<IMaintenanceActionsForm>();
    const [isViewOnlyMode, setIsViewOnlyMode] = useState<boolean>(
        componentTask === COMPONENT_TASK.VIEW_ONLY,
    );
    const [promptActions, setPromptActions] = useState<IPromptActions>({
        isVisible: false,
        title: '',
        message: '',
        onClose: () => undefined,
        onConfirm: () => undefined,
    });

    const [deleteMaintenance] = useMutation(DELETE_MAINTENANCE);
    const [updateMaintenanceById] = useMutation(UPDATE_MAINTENANCE_BY_ID);
    const [createMaintenance] = useMutation(CREATE_MAINTENANCE);

    const { data, loading: getMaintenanceLoading } = useQuery(
        GET_MAINTENANCE_BY_ID,
        {
            variables: { maintenanceId },
            skip: componentTask === COMPONENT_TASK.CREATE,
            onCompleted: (data) => {
                reset(data?.findMaintenanceById);
                setImage({
                    avatar: data?.findMaintenanceById?.image?.uri,
                    _id: data?.findMaintenanceById?.image?._id,
                    file: undefined,
                });
                setEditorState(
                    createDraftContentFromRawJSON(
                        data?.findMaintenanceById?.descriptionHtml,
                    ),
                );
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
                history.push(`${Routes.MAINTENANCES}`);
            },
        },
    );

    const formSubmitHandler = async (formData: IMaintenanceActionsForm) => {
        if (componentTask === COMPONENT_TASK.CREATE)
            await submitForCreateHandler(formData);

        if (componentTask === COMPONENT_TASK.EDIT)
            await submitForUpdateHandler(formData);
    };

    const submitForCreateHandler = async (
        formData: IMaintenanceActionsForm,
    ) => {
        setIsLoading(true);
        let data = UploadFileResponse;
        if (image) {
            await uploadFileToServer(image?.file as File).then((res) => {
                data = res.data;
            });
        }
        createMaintenance({
            variables: {
                input: {
                    ...formData,
                    descriptionHtml: parseDraftContentToRawJSON(editorState),
                    image: data?._id,
                    estimatedCost: Number(formData.estimatedCost),
                },
            },
        })
            .then((data: FetchResult<CreateMaintenanceResponse>) => {
                showAlertModal(
                    t('MAINTENANCE.MAINTENANCE_CREATED'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                history.push(
                    `${Routes.MAINTENANCES}/viewOnly/${data.data?.createMaintenance._id}`,
                );
            })
            .catch(() => {
                showAlertModal(
                    t('ALERTS.UPLOAD_IMAGE_FAILED_MESSAGE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const submitForUpdateHandler = async (
        formData: IMaintenanceActionsForm,
    ) => {
        setIsLoading(true);

        let helper;
        if (image?.file)
            helper = await uploadFileToServer(image?.file as File)
                .then((res) => res)
                .catch(() => {
                    showAlertModal(
                        t('ALERTS.UPLOAD_IMAGE_FAILED_MESSAGE'),
                        t('ALERTS.ERROR'),
                        AlertModalSeverity.error,
                    );
                });

        const { name, interventionTime, periodicity } = formData;

        const currentImage = helper?.data?._id || image?._id;

        updateMaintenanceById({
            variables: {
                maintenanceId,
                input: {
                    name,
                    interventionTime,
                    periodicity,
                    descriptionHtml: parseDraftContentToRawJSON(editorState),
                    image: currentImage ? currentImage : undefined,
                    estimatedCost: Number(formData.estimatedCost),
                },
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.MAINTENANCE_UPDATED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                setIsLoading(false);
                history.push(
                    `${Routes.MAINTENANCES}/viewOnly/${maintenanceId}`,
                );
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    const sharedHeaderContent = (): React.ReactNode => {
        return {
            [COMPONENT_TASK.CREATE]: (
                <Box>
                    <Button
                        variant="outlined"
                        className={spacingRightSmall2}
                        onClick={() =>
                            history.push(
                                `${Routes.MAINTENANCES}/viewOnly/${maintenanceId}`,
                            )
                        }
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={!!isLoading}
                        form={MAINTENANCES_FORM_NAMES.FORM_NAME}
                    >
                        {t('GENERAL.CREATE')}
                    </LoadingButton>
                </Box>
            ),
            [COMPONENT_TASK.VIEW_ONLY]: (
                <Box>
                    <Button
                        startIcon={<EditIcon />}
                        className={spacingRightSmall2}
                        variant="outlined"
                        onClick={() => {
                            setIsViewOnlyMode(false);
                            history.push(
                                `${Routes.MAINTENANCES}/edit/${maintenanceId}`,
                            );
                        }}
                    >
                        {t('GENERAL.EDIT_DATA')}
                    </Button>
                    <Button
                        startIcon={<DeleteForeverIcon />}
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            setPromptActions({
                                isVisible: true,
                                title: t('PROMPTS.REMOVE_MAINTENANCE_TITLE'),
                                message: t(
                                    'PROMPTS.REMOVE_MAINTENANCE_MESSAGE',
                                ),
                                onClose: onDialogPromptClose,
                                onConfirm: () =>
                                    removeMaintenanceHandler(maintenanceId),
                            });
                            e.stopPropagation();
                        }}
                    >
                        {t('MAINTENANCE.DELETE_MAINTENANCE')}
                    </Button>
                </Box>
            ),
            [COMPONENT_TASK.EDIT]: (
                <Box>
                    <Button
                        variant="outlined"
                        className={spacingRightSmall2}
                        onClick={() => {
                            setIsViewOnlyMode(true);
                            history.push(
                                `${Routes.MAINTENANCES}/viewOnly/${maintenanceId}`,
                            );
                        }}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={!!isLoading}
                        form={MAINTENANCES_FORM_NAMES.FORM_NAME}
                    >
                        {t('GENERAL.SAVE')}
                    </LoadingButton>
                </Box>
            ),
        };
    };

    const removeMaintenanceHandler = (id: string) => {
        deleteMaintenance({
            variables: {
                maintenanceId: id,
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.MAINTENANCE_DELETED_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                history.push(`${Routes.MAINTENANCES}`);
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.MAINTENANCE_DELETED_FAILED_MESSAGE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    function onDialogPromptClose() {
        setPromptActions((prev: any) => ({
            ...prev,
            isVisible: false,
        }));
    }

    useEffect(() => {
        setIsViewOnlyMode(componentTask === COMPONENT_TASK.VIEW_ONLY);
    }, [componentTask]);

    return (
        <>
            <SharedHeader
                title={{
                    content:
                        componentTask === COMPONENT_TASK.CREATE
                            ? t('MAINTENANCE.NEW_MAINTENANCE')
                            : data?.findMaintenanceById?.name,
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            >
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {(sharedHeaderContent() as any)[componentTask]}
            </SharedHeader>
            {displayConditionaly(
                getMaintenanceLoading,
                <SharedLoading />,
                <React.Fragment>
                    <Grid container>
                        <Grid
                            item
                            xs
                            onSubmit={handleSubmit(formSubmitHandler)}
                            id={MAINTENANCES_FORM_NAMES.FORM_NAME}
                            component="form"
                            className={maintenanceFormWrapper}
                        >
                            <Grid
                                item
                                xs={12}
                                md={4}
                                display="flex"
                                flexDirection="column"
                            >
                                <Box className={FLEX_COL_START}>
                                    <Box
                                        className={clsx(
                                            spacingBottomSmall3,
                                            maintenanceFormInputs,
                                        )}
                                    >
                                        <ControlledInput
                                            name={MAINTENANCES_FORM_NAMES.NAME}
                                            label={t('GENERAL.NAME')}
                                            control={control}
                                            rules={REQUIRED_FIELD}
                                            disabled={isViewOnlyMode}
                                        />
                                    </Box>
                                    <Box
                                        className={clsx(
                                            spacingBottomSmall3,
                                            maintenanceFormInputs,
                                        )}
                                    >
                                        <ControlledInput
                                            name={
                                                MAINTENANCES_FORM_NAMES.ESTIMATED_COST
                                            }
                                            label={t('GENERAL.ESTIMATED_COST')}
                                            control={control}
                                            type={INPUT_TYPE.NUMBER}
                                            disabled={isViewOnlyMode}
                                            inputProps={{
                                                inputProps: {
                                                    min: 0,
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        className={clsx(
                                            spacingBottomSmall3,
                                            maintenanceFormInputs,
                                        )}
                                    >
                                        <ControlledSelect
                                            name={
                                                MAINTENANCES_FORM_NAMES.INTERVENTION_TIME
                                            }
                                            items={
                                                MAINTENANCES_INTERVENTION_TIME
                                            }
                                            control={control}
                                            label={t(
                                                'MAINTENANCE.INTERVENTION_TIME',
                                            )}
                                            rules={REQUIRED_FIELD}
                                            disabled={isViewOnlyMode}
                                        />
                                    </Box>
                                    <Box
                                        className={clsx(
                                            spacingBottomSmall3,
                                            maintenanceFormInputs,
                                        )}
                                    >
                                        <ControlledSelect
                                            name={
                                                MAINTENANCES_FORM_NAMES.PERIODICITY
                                            }
                                            items={MAINTENANCES_PERIODICITY}
                                            control={control}
                                            label={t('MAINTENANCE.PERIODICITY')}
                                            rules={REQUIRED_FIELD}
                                            disabled={isViewOnlyMode}
                                            translate
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={clsx(
                                    FLEX_ROW_CENTER_END,
                                    maintenanceImage,
                                )}
                            >
                                <ImagePlaceholder
                                    image={image}
                                    setImage={setImage}
                                    disabled={isViewOnlyMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <TextEditor
                                editorState={editorState}
                                setEditorState={setEditorState}
                                disabled={isViewOnlyMode}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>,
            )}

            <ConfirmationDialog
                isOpen={promptActions?.isVisible}
                close={onDialogPromptClose}
                onConfirm={promptActions?.onConfirm}
                title={promptActions?.title}
                message={promptActions?.message ?? ''}
            />
        </>
    );
};

export { AddEditMaintenances };
