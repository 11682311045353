import { gql } from '@apollo/client';

export const REMOVE_MAINTENANCE_FROM_INSTALLATION = gql`
    mutation removeMaintenanceFromInstallation(
        $maintenanceId: ID!
        $installationId: ID!
    ) {
        removeMaintenanceFromInstallation(
            maintenanceId: $maintenanceId
            installationId: $installationId
        ) {
            _id
        }
    }
`;
