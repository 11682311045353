import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DIALOG_STATE } from '../../types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

interface IConfirmDialogProps {
    isOpen: boolean;
    title?: string;
    message: string;
    close: (state: DIALOG_STATE) => void;
    onConfirm?: () => void;
}

const ConfirmationDialog: FC<IConfirmDialogProps> = ({
    isOpen,
    title,
    message,
    close,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t(title || 'GENERAL.CONFIRMATION')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    size="large"
                    onClick={() => close(DIALOG_STATE.DISMISSED)}
                >
                    {t('GENERAL.CANCEL')}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        onConfirm && onConfirm();
                        close(DIALOG_STATE.CONFIRMED);
                    }}
                >
                    {t('GENERAL.CONFIRM')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
