import { useMutation } from '@apollo/client';
import React, { FC, useCallback } from 'react';
import { DELETE_SCHEDULE_REPORT } from '../../graphql/mutations/m-delete-schedule-report';
import { ReportItem } from './ReportItem/ReportItem';
import { PropTypes } from './Reports.types';

const Reports: FC<PropTypes> = ({ reports, scheduleId, refetchReports }) => {
    const [deleteItem] = useMutation(DELETE_SCHEDULE_REPORT);

    const deleteReportHandler = useCallback((url: string) => {
        return deleteItem({
            variables: {
                scheduleId,
                url,
            },
            onCompleted: async () => {
                await refetchReports();
            },
        });
    }, []);

    return (
        <>
            {!!reports?.length &&
                reports?.map((url: string, index: number) => (
                    <ReportItem
                        url={url}
                        index={index}
                        key={index}
                        onDelete={deleteReportHandler}
                    />
                ))}
        </>
    );
};

export { Reports };
