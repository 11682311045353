import React, { FC, useCallback, useState } from 'react';
import { Chip } from '@mui/material';
import { getTimeFromDate } from '../../core/utils';

interface IPropType {
    onClick: (hour: string) => void;
    hours: {
        start: string;
        end: string;
    }[];
}

const LooseAppointmentsHours: FC<IPropType> = ({ hours, onClick }) => {
    const [selectedHour, setSelectedHour] = useState<number>();

    const onHourSelect = useCallback(
        (index: number) => {
            setSelectedHour(index);
            onClick(hours[index].start);
        },
        [hours, onClick],
    );

    const normalizeData = useCallback(
        ({ start, end }: { start: string; end: string }) => {
            return {
                startHour: getTimeFromDate(start),
                endHour: getTimeFromDate(end),
            };
        },
        [],
    );

    const getVariant = useCallback(
        (index: number, currentSelectedIndex: number | undefined) => {
            return index === currentSelectedIndex ? 'filled' : 'outlined';
        },
        [],
    );

    return (
        <>
            {hours.map((hour, index) => {
                const { startHour, endHour } = normalizeData(hour);
                return (
                    <Chip
                        key={index}
                        label={`${startHour} - ${endHour}`}
                        color="primary"
                        clickable
                        variant={getVariant(index, selectedHour)}
                        onClick={() => onHourSelect(index)}
                        sx={{
                            marginRight: '2%',
                            marginTop: '2vh',
                        }}
                    />
                );
            })}
        </>
    );
};

export { LooseAppointmentsHours };
