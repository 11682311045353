import React, { FC } from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PropTypes } from './ReportItem.types';
import { useTranslation } from 'react-i18next';

const ReportItem: FC<PropTypes> = ({ url, index, onDelete }) => {
    const { t } = useTranslation();

    return (
        <Grid
            item
            xs
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            Upload #{index + 1}
            <Box>
                <IconButton color="warning" onClick={() => onDelete(url)}>
                    <DeleteForeverIcon />
                </IconButton>
                <Button
                    color="primary"
                    onClick={() => window.open(url, '_blank')}
                    startIcon={<RemoveRedEyeIcon />}
                >
                    {t('GENERAL.VIEW_REPORT')}
                </Button>
            </Box>
        </Grid>
    );
};

export { ReportItem };
