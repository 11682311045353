import { getTimeFromDate } from '../core/utils';

export const parseDataForScheduler = (data: any) => {
    return data?.map((calendar: any) => {
        return calendar.schedules.map((day: any) => {
            return {
                id: day?.schedule?._id,
                status: day?.schedule?.status,
                image: day?.schedule?.image,
                title: day?.schedule?.maintenance?.name,
                endDate: +day?.schedule?.scheduleEndDate,
                startDate: +day?.schedule?.scheduledDate,
                installationId: day?.schedule?.installationId,
                startHour: getTimeFromDate(+day?.schedule?.scheduledDate),
                endHour: getTimeFromDate(+day?.schedule?.scheduleEndDate),
            };
        });
    });
};
