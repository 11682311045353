import React, { memo, useContext } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { uploadFileToServer } from '../../core/utils';
import { EditorState } from 'draft-js';
import { AlertModalSeverity } from '../../types';
import { useTranslation } from 'react-i18next';
import { AlertModalContext } from '../../context/alertModal-context';

const ACCEPTED_IMAGES_TYPES = 'image/jpeg,image/jpg,image/png';

interface ITextEditorProps {
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    disabled?: boolean;
    onChange?: any;
}

interface IDraftFile {
    file: File;
    localSrc: string | null;
}

const TextEditor: React.FC<ITextEditorProps> = memo(
    ({ editorState, setEditorState, disabled, onChange }) => {
        const { t } = useTranslation();
        const { showAlertModal } = useContext(AlertModalContext);

        const uploadImageCallBack = async (file: File) => {
            const draftFile: IDraftFile = {
                file: file,
                localSrc: null,
            };

            try {
                const { data } = await uploadFileToServer(draftFile.file);
                draftFile.localSrc = data?.uri;

                return new Promise((resolve) => {
                    resolve({ data: { link: draftFile?.localSrc } });
                });
            } catch (error) {
                showAlertModal(
                    t('ALERTS.UPLOAD_IMAGE_FAILED_MESSAGE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            }
        };

        return (
            <>
                <Editor
                    readOnly={disabled}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    toolbarHidden={disabled}
                    toolbar={{
                        options: ['blockType', 'image', 'link', 'history'],
                        blockType: {
                            inDropdown: true,
                            options: ['H2', 'Normal'],
                        },
                        link: {
                            defaultTargetOption: '_blank',
                            showOpenOptionOnHover: true,
                        },
                        image: {
                            inputAccept: ACCEPTED_IMAGES_TYPES,
                            uploadCallback: uploadImageCallBack,
                            uploadEnabled: true,
                            previewImage: true,
                            alignmentEnabled: false,
                            screenLeft: 0,
                            defaultSize: {
                                height: 'auto',
                                width: '200px',
                            },
                        },
                    }}
                    onContentStateChange={onChange}
                />
            </>
        );
    },
);

export { TextEditor };
