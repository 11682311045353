import { gql } from '@apollo/client';

export const GET_MAINTENANCE_BY_ID = gql`
    query findMaintenanceById($maintenanceId: ID!) {
        findMaintenanceById(maintenanceId: $maintenanceId) {
            _id
            image {
                _id
                uri
            }
            name
            descriptionHtml
            periodicity
            interventionTime
            estimatedCost
        }
    }
`;
