import { gql } from '@apollo/client';

export const GET_MAINTENANCES = gql`
    query findMaintenances($filter: ListInput!) {
        findMaintenances(filter: $filter) {
            maintenances {
                _id
                name
                descriptionHtml
                periodicity
                interventionTime
            }
            listInfo {
                step
                limit
                term
                total
            }
        }
    }
`;
