import React, { FC } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useSchedulerStyles } from '../../../style/common';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler';

import CloseIcon from '@mui/icons-material/Close';

const placeholder =
    'https://images.squarespace-cdn.com/content/v1/5e279c1ad8a7ad41e680b1d4/1618434839543-5VPR43SCIXE05A7ME3CU/PresPoolsOct2018-58.jpg';

const SchedulerTooltipHeader: FC<AppointmentTooltip.HeaderProps> = ({
    children,
    appointmentData,
    ...restProps
}) => {
    const {
        tooltipHeaderImage,
        tooltipHeaderGroupButton,
        tooltipImageDegrade,
    } = useSchedulerStyles();

    return (
        <Box {...restProps}>
            <Box className={tooltipHeaderGroupButton}>
                <IconButton onClick={restProps.onHide} sx={{ zIndex: 100 }}>
                    <CloseIcon color="primary" />
                </IconButton>
            </Box>
            <Grid item xs sx={{ position: 'relative', maxHeight: '15vh' }}>
                <Box className={tooltipImageDegrade}></Box>
                <img
                    alt="Schedule Maintenance"
                    src={
                        appointmentData?.image
                            ? appointmentData?.image
                            : placeholder
                    }
                    className={tooltipHeaderImage}
                />
            </Grid>
        </Box>
    );
};

export { SchedulerTooltipHeader };
