import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';

import { createTheme } from '@mui/material/styles';

import ENV from '../config/environment';

import translationPT from '../translations/pt.json';
import { themeColors } from '../style/common';

const httpLink = new HttpLink({ uri: `${ENV.REACT_APP_GRAPHQL_ENDPOINT}` });

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(ENV.REACT_APP_TOKEN_KEY);

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : '',
        },
    });

    return forward(operation);
});

export const setupApolloClient = () =>
    new ApolloClient({
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only',
            },
            query: {
                fetchPolicy: 'network-only',
            },
        },
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

export const setupI18n = () => {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // the translations
            // (tip move them in a JSON file and import them,
            // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            resources: {
                pt: {
                    translation: translationPT,
                },
            },
            lng: 'pt', // if you're using a language detector, do not define the lng option
            fallbackLng: 'pt',

            interpolation: {
                escapeValue: false,
            },
        });
};

export const setupTheme = () => {
    const theme = createTheme();
    return createTheme({
        palette: {
            primary: {
                main: themeColors.primary,
                contrastText: themeColors.generalContrastText,
            },
            secondary: {
                main: themeColors.secondary,
            },
            divider: themeColors.divider,
            background: {
                default: themeColors.background,
            },
        },
        typography: {
            fontFamily: ['"Opens Sans"', 'sans-serif'].join(','),
            h4: {
                fontWeight: 'bold',

                [theme.breakpoints.down('sm')]: {
                    fontSize: '1.5rem',
                },
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow:
                            '0px 0px 20px rgba(0, 0, 0, 0.05) !important',
                    },
                    rounded: {
                        borderRadius: theme.spacing(2.25),
                    },
                },
            },
        },
    });
};
