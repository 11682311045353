import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';

import { useApolloClient } from '@apollo/client';

import {
    Box,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import CompanieLogo from '../assets/brand/GUIM-logo.png';

import { useAppDispatch } from '../store';
import { logout } from '../store/reducers';
import { shellStyle } from '../style/common';

import { ShellMenuListItems, ViewsPaths } from '../types';
import { Routes } from '../core/utils';
import { useWindowSize } from '../hooks';

const menuListItems: ShellMenuListItems[] = [
    {
        text: 'GENERAL.DASHBOARD',
        icon: <DashboardIcon />,
        linkPath: ViewsPaths.DASHBOARD,
    },
    {
        text: 'GENERAL.USERS',
        icon: <PersonIcon />,
        linkPath: ViewsPaths.USERS,
    },
    {
        text: 'GENERAL.MAINTENANCES',
        icon: <ConstructionIcon />,
        linkPath: ViewsPaths.MAINTENANCES,
    },
    {
        text: 'GENERAL.HIGHLIGHTS',
        icon: <StarIcon />,
        linkPath: ViewsPaths.HIGHLIGHTS,
    },
    {
        text: 'GENERAL.SETTINGS',
        icon: <SettingsIcon />,
        linkPath: ViewsPaths.SETTINGS,
    },
];

const Shell = (props: { children?: React.ReactNode; basePath: string }) => {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const classes = shellStyle();
    const dispatch = useAppDispatch();
    const { windowWidth, isOnMobile } = useWindowSize();

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(
        windowWidth > 600,
    );

    const logingOut = () => {
        apolloClient.resetStore();
        dispatch(logout());
        history.push(Routes.LOGIN);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            {isOnMobile && !isDrawerOpen && (
                <Grid className={classes.openDrawerButton}>
                    <Box onClick={() => setIsDrawerOpen(true)}>
                        <MenuIcon />
                    </Box>
                </Grid>
            )}
            <Drawer
                open={isDrawerOpen}
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: isDrawerOpen,
                    [classes.drawerClose]: !isDrawerOpen,
                })}
                classes={{
                    paper: clsx(classes.paper, {
                        [classes.drawerOpen]: isDrawerOpen,
                        [classes.drawerClose]: !isDrawerOpen,
                    }),
                }}
            >
                {isOnMobile && isDrawerOpen && (
                    <Grid className={classes.closeDrawerButton}>
                        <Box onClick={() => setIsDrawerOpen(false)}>
                            <CloseIcon />
                        </Box>
                    </Grid>
                )}
                <Grid
                    container
                    justifyContent="center"
                    className={classes.companyLogoWrapper}
                >
                    <img
                        src={CompanieLogo}
                        alt="Company Logo"
                        className={classes.companyLogo}
                    />
                </Grid>
                <Divider />

                <Box
                    display="flex"
                    alignItems="start"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <List sx={{ pt: 5, width: 1 }}>
                        {menuListItems.map((item: ShellMenuListItems) => (
                            <ListItem
                                selected={path.endsWith(item.linkPath)}
                                button
                                key={item.text}
                                onClick={() => {
                                    history.push(
                                        `${props.basePath}/${item.linkPath}`,
                                    );
                                    if (isOnMobile) setIsDrawerOpen(false);
                                }}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={t(item.text)} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List sx={{ width: 1 }}>
                        {[
                            [
                                t('GENERAL.LOGOUT'),
                                <ExitToAppIcon key="logout" />,
                            ],
                        ].map(([text, icon]: any) => (
                            <ListItem button key={text} onClick={logingOut}>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <main className={classes.content}>{props.children}</main>
        </div>
    );
};

export { Shell };
