import React, {
    forwardRef,
    useCallback,
    useContext,
    useImperativeHandle,
    useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { LooseAppointmentsHours } from './LooseAppointmentsHours';
import { ControlledInput, ControlledSelect } from '../shared-components';
import { CREATE_LOOSE_SCHEDULE, FIND_DAYS_TO_SCHEDULE } from '../../graphql';
import { LOSE_APPOINTMENTS_FORM } from '../../constants/lose-appointments-constants';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useLooseAppointmentStyles } from '../../style/common';
import { AlertModalContext } from '../../context/alertModal-context';
import { AlertModalSeverity } from '../../types';
import {
    MAINTENANCES_INTERVENTION_TIME,
    REQUIRED_FIELD,
} from '../../constants';
import { getFullDate } from '../../core/utils';

interface ILooseScheduleForm {
    name: string;
    interventionTime: string;
}

interface IDayToSchedule {
    dayDate: Dayjs;
    timeSlots: {
        start: string;
        end: string;
    }[];
}

interface IProps {
    installationId: string;
    closeModal: () => void;
    refetch: () => void;
}

const TODAY = dayjs(new Date());

const LooseAppointments = forwardRef(
    ({ installationId, closeModal, refetch }: IProps, ref) => {
        const {
            control,
            watch,
            handleSubmit,
            formState: { isValid },
        } = useForm<ILooseScheduleForm>();

        const { t } = useTranslation();
        const { showAlertModal } = useContext(AlertModalContext);
        const [selectedHour, setSelectedHour] = useState<string>();
        const [currentDate, setCurrentDate] = useState<Dayjs>(TODAY);
        const [availableDays, setAvailableDays] = useState<Dayjs[]>([]);
        const { fieldsWrapper, chipsWrapper } = useLooseAppointmentStyles();
        const [datePickerValues, setDatePickerValues] = useState<Dayjs>(TODAY);

        const [createLooseSchedule] = useMutation(CREATE_LOOSE_SCHEDULE, {
            onCompleted: async () => {
                showAlertModal(
                    t('ALERTS.LOOSE_APPOINTMENT_CREATED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );

                await refetch();
                closeModal();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.SWW_WHILE_CREATING_LOOSE_SCHEDULE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });

        const { data, loading } = useQuery<{
            findDaysToSchedule: IDayToSchedule[];
        }>(FIND_DAYS_TO_SCHEDULE, {
            skip: !watch(LOSE_APPOINTMENTS_FORM.INTERVENTION_TIME),
            variables: {
                installationId,
                filter: {
                    month: currentDate?.month() + 1,
                    year: currentDate?.year(),
                },
                interventionTime: watch(
                    LOSE_APPOINTMENTS_FORM.INTERVENTION_TIME,
                ),
            },
            onCompleted: (data) => {
                const freeDays: Dayjs[] = data?.findDaysToSchedule?.map(
                    (day: { dayDate: Dayjs }) => day?.dayDate,
                );

                setAvailableDays(freeDays);
            },
        });

        useImperativeHandle(ref, () => ({
            submitForm() {
                if (!selectedHour) {
                    return showAlertModal(
                        t('ALERTS.PLEASE_SELECT_HOUR'),
                        t('ALERTS.ERROR'),
                        AlertModalSeverity.error,
                    );
                }
                if (isValid) handleSubmit(onFormSubmission)();
            },
        }));

        const onFormSubmission = ({
            name,
            interventionTime,
        }: ILooseScheduleForm) => {
            createLooseSchedule({
                variables: {
                    installationId,
                    input: {
                        name,
                        interventionTime,
                        scheduledDate: selectedHour,
                    },
                },
            });
        };

        const setSelectedHourCallback = useCallback((hour: string) => {
            setSelectedHour(hour);
        }, []);

        const handleDatePickerChange = useCallback((date: Dayjs | null) => {
            setDatePickerValues(dayjs(date));
        }, []);

        const isSelectedDate = useCallback(
            (date: Dayjs, comparingDate: Dayjs) => {
                return getFullDate(date) === getFullDate(comparingDate);
            },
            [],
        );

        const shouldBeDisabled = useCallback(
            (day: Dayjs, freeDates: Dayjs[]) => {
                return !freeDates.some((disabledDate) => {
                    return day.isSame(disabledDate, 'day');
                });
            },
            [],
        );

        return (
            <form id={LOSE_APPOINTMENTS_FORM.FORM_NAME}>
                <Box className={fieldsWrapper}>
                    <ControlledInput
                        name={LOSE_APPOINTMENTS_FORM.NAME}
                        label={t('GENERAL.NAME')}
                        control={control}
                        rules={REQUIRED_FIELD}
                    />
                </Box>
                <Box className={fieldsWrapper}>
                    <ControlledSelect
                        name={LOSE_APPOINTMENTS_FORM.INTERVENTION_TIME}
                        items={MAINTENANCES_INTERVENTION_TIME}
                        control={control}
                        label={t('MAINTENANCE.INTERVENTION_TIME')}
                        rules={REQUIRED_FIELD}
                    />
                </Box>
                {watch('interventionTime') && (
                    <Box className={fieldsWrapper}>
                        <DesktopDatePicker
                            label={t('INSTALLATIONS.INSTALLATION_DATE')}
                            inputFormat="YYYY/MM/DD"
                            value={datePickerValues.format('YYYY-MM-DD')}
                            onChange={handleDatePickerChange}
                            disablePast
                            loading={loading}
                            onMonthChange={(date) =>
                                setCurrentDate(dayjs(date))
                            }
                            shouldDisableDate={(day) =>
                                shouldBeDisabled(day, availableDays)
                            }
                            renderInput={(params: any) => (
                                <ControlledInput
                                    params={params}
                                    name={LOSE_APPOINTMENTS_FORM.SCHEDULE_DATE}
                                    label={t('GENERAL.NAME')}
                                    control={control}
                                    sx={{ width: '10vw' }}
                                    cValue={datePickerValues.format(
                                        'YYYY-MM-DD',
                                    )}
                                    disabled={true}
                                />
                            )}
                        />
                    </Box>
                )}
                <Box width="60%" className={chipsWrapper}>
                    {data?.findDaysToSchedule.map((day, index) => {
                        const isSameDate = isSelectedDate(
                            day.dayDate,
                            datePickerValues,
                        );

                        if (!isSameDate) return null;

                        return (
                            <LooseAppointmentsHours
                                hours={day.timeSlots}
                                key={index}
                                onClick={setSelectedHourCallback}
                            />
                        );
                    })}
                </Box>
            </form>
        );
    },
);

export { LooseAppointments };
