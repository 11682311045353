import React from 'react';
import { Provider } from 'react-redux';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ApolloProvider } from '@apollo/client';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import Login from './containers/login';
import { setupApolloClient, setupI18n, setupTheme } from './core/general-setup';
import ShellRouting from './routing/shell';
import store from './store';

import './App.css';
import AlertModalContextProvider from './context/alertModal-context';
import AlertModalComponent from './components/alerts/AlertModal';

setupI18n();
const theme = setupTheme();
const apolloClient = setupApolloClient();

function App() {
    return (
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <AlertModalContextProvider>
                                <Router>
                                    <>
                                        <Switch>
                                            <Route path="/login">
                                                <Login />
                                            </Route>
                                            <Route path="/shell">
                                                <ShellRouting />
                                            </Route>
                                            <Route
                                                exact
                                                path="/"
                                                render={() => {
                                                    return (
                                                        <Redirect to="/login" />
                                                    );
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="*"
                                                render={() => {
                                                    return (
                                                        <Redirect to="/shell/dashboard" />
                                                    );
                                                }}
                                            />
                                        </Switch>
                                    </>
                                </Router>
                                <AlertModalComponent />
                            </AlertModalContextProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </LocalizationProvider>
            </ApolloProvider>
        </Provider>
    );
}

export default App;
