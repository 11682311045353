import { gql } from '@apollo/client';

export const UPDATE_INSTALLATION = gql`
    mutation updateInstallation(
        $installationId: ID!
        $input: InstallationUpdateInput!
    ) {
        updateInstallation(installationId: $installationId, input: $input) {
            _id
        }
    }
`;
