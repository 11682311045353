import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { Routes } from '../../core/utils';
import { UNIQUE_MAINTENANCES_NAME } from '../../constants';
import useCommonStyles from '../../style/common';
import { COMPONENT_TASK } from '../../types';

export interface IMaintenance {
    _id: string;
    name: string;
    periodicity: string;
    updatedAt: string;
    validDateHash?: string;
    isDeleted: false;
}

interface IAssociateMaintenanceProps {
    callback: (maintenance: string[]) => void;
    maintenances: IMaintenance[];
    alreadySelected?: IMaintenance[];
}

const AssociateMaintenance: FC<IAssociateMaintenanceProps> = ({
    maintenances,
    callback,
    alreadySelected,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { associateNewMaintenanceItem } = useCommonStyles();

    const filter = () => {
        const filtered = maintenances.filter((maintenance) => {
            return (
                alreadySelected &&
                alreadySelected.find((selected) => {
                    return (
                        selected.validDateHash === maintenance.updatedAt &&
                        !selected.isDeleted
                    );
                })
            );
        });

        return _.flatten(filtered) as IMaintenance[];
    };

    const [selectedMaintenances, setSelectedMaintenances] = useState<
        IMaintenance[]
    >(filter() ?? []);

    const onCheckboxChange = (
        e: ChangeEvent<HTMLInputElement>,
        maintenance: IMaintenance,
    ) => {
        if (e?.target.checked) {
            callback(
                [...selectedMaintenances, maintenance]?.map((item) => item._id),
            );

            setSelectedMaintenances((prev) => [...prev, maintenance]);
        }
    };

    return (
        <>
            {maintenances.map((maintenance, index: number) => {
                const isSelected = selectedMaintenances?.find((m) => {
                    return m._id === maintenance._id;
                });

                return (
                    <Grid
                        key={index}
                        xs={12}
                        item
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            className={associateNewMaintenanceItem}
                        >
                            <Checkbox
                                checked={!!isSelected}
                                disabled={!!isSelected}
                                onChange={(e) => {
                                    onCheckboxChange(e, maintenance);
                                }}
                            />
                            <Typography variant="body1">
                                {maintenance?.name === UNIQUE_MAINTENANCES_NAME
                                    ? t('MAINTENANCE.UNIQUE_MAINTENANCES_NAME')
                                    : maintenance?.name}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {t(`MAINTENANCE.TIME.${maintenance?.periodicity}`)}
                        </Typography>
                        <Button
                            size="large"
                            onClick={() =>
                                history.push(
                                    `${Routes.MAINTENANCES}/${COMPONENT_TASK.VIEW_ONLY}/${maintenance._id}`,
                                )
                            }
                        >
                            {t('GENERAL.VIEW_MORE')}
                        </Button>
                    </Grid>
                );
            })}
        </>
    );
};

export { AssociateMaintenance };
