import { ISelectItem } from '../types';

/* eslint-disable no-unused-vars */
export enum MAINTENANCES_FORM_NAMES {
    NAME = 'name',
    DESCRIPTION = 'description',
    INTERVENTION_TIME = 'interventionTime',
    PERIODICITY = 'periodicity',
    ESTIMATED_COST = 'estimatedCost',
    FORM_NAME = 'maintenancesActions',
}

export const MAINTENANCES_INTERVENTION_TIME: ISelectItem[] = [
    {
        label: '00:30',
        value: 'HALF_HOURS',
    },
    {
        label: '01:00',
        value: 'ONE_HOURS',
    },
    {
        label: '01:30',
        value: 'ONE_HALF_HOURS',
    },
    {
        label: '02:00',
        value: 'TWO_HOURS',
    },
    {
        label: '02:30',
        value: 'TWO_HALF_HOURS',
    },
    {
        label: '03:00',
        value: 'THREE_HOURS',
    },
    {
        label: '03:30',
        value: 'THREE_HALF_HOURS',
    },
    {
        label: '04:00',
        value: 'FOUR_HOURS',
    },
    {
        label: '04:30',
        value: 'FOUR_HALF_HOURS',
    },
];

export const MAINTENANCES_PERIODICITY: ISelectItem[] = [
    {
        label: 'Yearly',
        value: 'YEARLY',
    },
    {
        label: 'Semiannual',
        value: 'SEMIANNUAL',
    },
    {
        label: 'Quarterly',
        value: 'QUARTERLY',
    },
    {
        label: 'BiWeekly',
        value: 'BI_WEEKLY',
    },
    {
        label: 'Weekly',
        value: 'WEEKLY',
    },
    {
        label: 'DAILY',
        value: 'DAILY',
    },
    {
        label: 'NoPeriodicity',
        value: 'NO_PERIODICITY',
    },
];

export const defaultDraftValue =
    '{"blocks":[{"key":"8fpdr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

export const UNIQUE_MAINTENANCES_NAME = 'UNIQUE_MAINTENANCE';
