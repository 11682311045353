import React, { useContext, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseDataForScheduler } from '../services';
import { AlertModalContext } from '../context/alertModal-context';
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import { FIND_ANALYTICS, GET_DAYS_WITH_SCHEDULES } from '../graphql';
import { INIT_ANALYTICS_STATE, SELECTS_CONTENT } from '../constants';
import {
    AlertModalSeverity,
    AnalyticToSearch,
    AnalyticsTimePeriod,
    CalculatedAnalytic,
    ISchedulerAppointment,
} from '../types';
import {
    Box,
    Button,
    Grid,
    Paper,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import {
    ControlledSelect,
    DxScheduler,
    InformationCard,
    SharedHeader,
} from '../components';
import useCommonStyles, {
    dashboardCreateUserCard,
    themeColors,
} from '../style/common';

const Dashboard = () => {
    const history = useHistory();
    const { control } = useForm();
    const { t } = useTranslation();
    const { showAlertModal } = useContext(AlertModalContext);
    const dashboardCreateUserCardClass = dashboardCreateUserCard();
    const { spacingBottomLarge, spacingBottomSmall3 } = useCommonStyles();

    const [currentDate, setCurrentDate] = useState<SchedulerDateTime>();
    const [schedulerData, setSchedulerData] = useState<ISchedulerAppointment[]>(
        [],
    );
    const [analyticsToSearch, setAnalyticsToSearch] =
        useState<AnalyticToSearch[]>(INIT_ANALYTICS_STATE);

    useQuery(GET_DAYS_WITH_SCHEDULES, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                month: dayjs(currentDate).month() + 1,
                year: dayjs(currentDate).year(),
            },
        },
        onCompleted: (data) => {
            const dataForScheduler = parseDataForScheduler(
                data?.findDaysWithSchedules,
            );

            setSchedulerData(_.flatten(dataForScheduler));
        },
    });

    const { data: analytics, refetch } = useQuery(FIND_ANALYTICS, {
        variables: {
            analytics: analyticsToSearch,
        },
        onError: () => {
            showAlertModal(
                t('ALERTS.ERRORS_REQUEST_FAILED'),
                t('ALERTS.ERROR'),
                AlertModalSeverity.error,
            );
        },
    });

    const handleAnalyticsChange = (e: SelectChangeEvent<any>) => {
        const name = e.target.name;
        const timePeriod = e.target.value;

        setAnalyticsToSearch((prev) => {
            const analyticToSearch = prev.find(
                (analytic) => analytic.name === name,
            );
            if (analyticToSearch) {
                analyticToSearch.timePeriod = timePeriod;
            }
            refetch();
            return [...prev];
        });
    };

    return (
        <Grid container>
            <SharedHeader
                title={{
                    content: t('GENERAL.DASHBOARD'),
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            />

            <Grid container spacing={6} sx={{ marginBottom: '5vh' }}>
                {analytics &&
                    analytics?.findAnalytics?.map(
                        (card: CalculatedAnalytic, index: number) => (
                            <Grid item md={4} xs={12} key={index}>
                                <ControlledSelect
                                    name={card?.name}
                                    control={control}
                                    items={SELECTS_CONTENT}
                                    translate
                                    label={t('DASHBOARD.TIME_PERIOD')}
                                    className={spacingBottomSmall3}
                                    cOnChange={handleAnalyticsChange}
                                    defaultValue={
                                        AnalyticsTimePeriod.LAST_7_DAYS
                                    }
                                />
                                <InformationCard
                                    headerTitle={
                                        t(`DASHBOARD.${card?.name}`) as string
                                    }
                                    headerPercentage={card?.value}
                                    bodyTitle={card?.count}
                                    bodySubtitle={''}
                                />
                            </Grid>
                        ),
                    )}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ display: 'flex', alignItems: 'flex-end' }}
                >
                    <Paper
                        sx={{
                            p: 3,
                            pb: 4,
                            width: 1,
                            backgroundColor: themeColors.primary,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                style={{ color: themeColors.textLight }}
                            >
                                {t('DASHBOARD.START_HERE')}
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{ color: themeColors.textLight }}
                            >
                                {t('DASHBOARD.CREATE_USER_AND_SCHEDULE')}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{ mt: 4.43 }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={dashboardCreateUserCardClass.root}
                                onClick={() => history.push('/shell/users/new')}
                            >
                                {t('USERS.CREATE_USER')}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid sx={{ maxWidth: '90vw' }}>
                <DxScheduler
                    data={_.flatten(schedulerData) ?? []}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                />
            </Grid>
        </Grid>
    );
};

export { Dashboard };
