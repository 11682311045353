import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInput!, $id: ID!) {
        updateUser(input: $input, id: $id) {
            _id
            fullname
            email
            phoneNumber
            roles
            confirmed
        }
    }
`;
