import { gql } from '@apollo/client';

export const GET_INSTALLATIONS_BY_USER_ID = gql`
    query getInstallationsByUserId($userId: ID!) {
        getInstallationsByUserId(userId: $userId) {
            _id
            name
            maintenances {
                nextMaintenanceDate
                status
            }
        }
    }
`;
