import React, { useContext, useState } from 'react';
import { SharedDataGrid, SharedHeader } from '../../components';
import { Button, Grid, OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import useCommonStyles from '../../style/common';
import {
    DELETE_MAINTENANCE,
    DELETE_MULTIPLE_MAINTENANCES,
    GET_MAINTENANCES,
} from '../../graphql';
import {
    AlertModalSeverity,
    DataGridConfig,
    IMaintenanceGrid,
    IPromptActions,
    MaintenanceDataGridFields,
} from '../../types';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@apollo/client';
import { useDebounce } from '../../hooks';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { AlertModalContext } from '../../context/alertModal-context';
import {
    MAINTENANCES_INTERVENTION_TIME,
    UNIQUE_MAINTENANCES_NAME,
} from '../../constants';
import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import {
    Routes,
    dataGridDefaults,
    displayConditionaly,
    nPages,
} from '../../core/utils';
import { SharedPagination } from '../../components/shared-components/pagination';
import { SharedLoading } from '../../components/shared-components/loading';
import clsx from 'clsx';

const Maintenances = () => {
    const {
        sharedDataGrid,
        spacingBottomLarge,
        spacingBottomSmall3,
        spacingRightSmall2,
        maintenancesSearchWrapper,
    } = useCommonStyles();

    const history = useHistory();
    const { t } = useTranslation();
    const [debounce] = useDebounce();
    const [term, setTerm] = useState<string>('');
    const { showAlertModal } = useContext(AlertModalContext);
    const [deleteMaintenance] = useMutation(DELETE_MAINTENANCE);
    const [dataGridConfig, setDataGridConfig] = useState<DataGridConfig>({
        currentPage: 1,
        totalPages: 0,
        rowsPerPage: 3,
    });
    const [dataGridRows, setDataGridRows] = useState<IMaintenanceGrid[]>([]);
    const [promptActions, setPromptActions] = useState<IPromptActions>({
        isVisible: false,
        title: '',
        message: '',
        onClose: () => undefined,
        onConfirm: () => undefined,
    });
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteMultipleMaintenances] = useMutation(
        DELETE_MULTIPLE_MAINTENANCES,
        {
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.SELECTED_MAINTENANCES_WERE_DELETED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                refetch();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.SWW_WHILE_DELETING_THE_MAINTENANCES'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        },
    );
    const { refetch, loading } = useQuery(GET_MAINTENANCES, {
        variables: {
            filter: {
                term,
                step: dataGridConfig?.currentPage - 1,
                limit: dataGridConfig?.rowsPerPage,
            },
        },
        onCompleted: (data) => {
            setDataGridConfig({
                currentPage: dataGridConfig.currentPage,
                rowsPerPage: 10,
                totalPages: nPages(
                    data?.findMaintenances?.listInfo?.total,
                    dataGridConfig.rowsPerPage,
                ),
            });

            const removeUniqueMaintenance =
                data?.findMaintenances?.maintenances.filter(
                    (_item: IMaintenanceGrid) =>
                        _item.name !== UNIQUE_MAINTENANCES_NAME,
                );

            setDataGridRows(removeUniqueMaintenance ?? []);
        },
    });

    const removeMaintenanceHandler = (id: string) => {
        deleteMaintenance({
            variables: {
                maintenanceId: id,
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.MAINTENANCE_DELETED_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                refetch();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.MAINTENANCE_DELETED_FAILED_MESSAGE'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    const columns: GridColDef[] = [
        {
            field: MaintenanceDataGridFields.NAME,
            headerName: t('GENERAL.NAME'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return params?.row?.name === UNIQUE_MAINTENANCES_NAME
                    ? t('MAINTENANCE.UNIQUE_MAINTENANCES_NAME')
                    : params?.row?.name;
            },
        },
        {
            field: MaintenanceDataGridFields.PERIODICITY,
            headerName: t('MAINTENANCE.PERIODICITY'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return t(`MAINTENANCE.TIME.${params?.row?.periodicity}`);
            },
        },
        {
            field: MaintenanceDataGridFields.INTERVENTION_TIME,
            headerName: t('MAINTENANCE.INTERVENTION_TIME'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return MAINTENANCES_INTERVENTION_TIME.find(
                    (item) => item.value === params?.row?.interventionTime,
                )?.label;
            },
        },
        {
            field: MaintenanceDataGridFields.ACTIONS,
            headerName: '',
            align: 'right',
            ...dataGridDefaults(),
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Button
                        size="large"
                        variant="text"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={(e) => {
                            setPromptActions({
                                isVisible: true,
                                title: t('PROMPTS.REMOVE_MAINTENANCE_TITLE'),
                                message: t(
                                    'PROMPTS.REMOVE_TECHNICAL_FILE_MESSAGE',
                                ),
                                onClose: onDialogPromptClose,
                                onConfirm: () =>
                                    removeMaintenanceHandler(params?.row?._id),
                            });
                            e.stopPropagation();
                        }}
                    >
                        {t('GENERAL.DELETE')}
                    </Button>
                );
            },
        },
    ];

    const renderPromptToDeleteMultiple = () => {
        setPromptActions({
            isVisible: true,
            title: t('PROMPTS.REMOVE_MAINTENANCES_TITLE'),
            message: t(
                'PROMPTS.DO_YOU_WANT_TO_DELETE_THE_SELECTED_MAINTENANCES',
            ),
            onClose: onDialogPromptClose,
            onConfirm: () =>
                deleteMultipleMaintenances({
                    variables: {
                        maintenanceIds: selectedIds,
                    },
                }),
        });
    };

    function onDialogPromptClose() {
        setPromptActions((prev: any) => ({
            ...prev,
            isVisible: false,
        }));
    }

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setDataGridConfig({
            ...dataGridConfig,
            currentPage: page,
        });
    };

    return (
        <>
            <SharedHeader
                title={{
                    content: t('MAINTENANCE.MAINTENANCE_LIST'),
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            >
                <Button
                    variant="contained"
                    size="large"
                    onClick={() =>
                        history.push(`${Routes.MAINTENANCES}/create/`)
                    }
                >
                    {t('MAINTENANCE.CREATE_NEW_MAINTENANCE')}
                </Button>
            </SharedHeader>
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                className={clsx(spacingBottomSmall3, maintenancesSearchWrapper)}
            >
                {!!selectedIds.length && (
                    <Button
                        className={spacingRightSmall2}
                        variant="outlined"
                        onClick={renderPromptToDeleteMultiple}
                    >
                        {t('USERS.DELETE_SELECTED')}
                    </Button>
                )}
                <OutlinedInput
                    onChange={(e) =>
                        debounce(undefined, e.target.value, setTerm)
                    }
                    placeholder={t('GENERAL.SEARCH_BY_NAME')}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                />
            </Grid>
            {displayConditionaly(
                loading,
                <SharedLoading />,
                <React.Fragment>
                    <SharedDataGrid
                        onSelectionModelChange={(selectedIds: string[]) => {
                            setSelectedIds(selectedIds);
                        }}
                        checkboxSelection
                        className={sharedDataGrid}
                        rows={dataGridRows}
                        columns={columns}
                        onRowClick={(e) => {
                            history.push(
                                `${Routes.MAINTENANCES}/viewOnly/${e?.id}`,
                            );
                        }}
                        configuration={{
                            hideFooter: true,
                            autoHeight: true,
                        }}
                    />
                    <SharedPagination
                        count={dataGridConfig?.totalPages}
                        onChange={handleChangePage}
                        page={dataGridConfig?.currentPage}
                    />
                </React.Fragment>,
            )}
            <ConfirmationDialog
                isOpen={promptActions?.isVisible}
                close={onDialogPromptClose}
                onConfirm={promptActions?.onConfirm}
                title={promptActions?.title}
                message={promptActions?.message ?? ''}
            />
        </>
    );
};

export { Maintenances };
