import React, { FC, memo } from 'react';
import useCommonStyles from '../../style/common';
import { Box, Grid, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import clsx from 'clsx';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ISharedHeaderProps {
    title: {
        content: string;
        variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    };
    subtitle?: string;
    children?: React.ReactNode;
    className?: string;
    actions?: React.ReactNode;
    onBack?: () => void;
    userInformationCta?: () => void;
}

const SharedHeader: FC<ISharedHeaderProps> = memo(
    ({
        title,
        children,
        subtitle,
        className,
        actions,
        onBack,
        userInformationCta,
    }) => {
        const {
            spacingRightSmall2,
            cursorPointer,
            icon,
            iconWrapper,
            sharedHeader,
        } = useCommonStyles();

        return (
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                className={clsx(className, sharedHeader)}
            >
                <Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexDirection="row"
                        flexWrap="wrap"
                    >
                        {onBack && (
                            <ArrowBackIcon
                                onClick={onBack}
                                className={clsx(
                                    spacingRightSmall2,
                                    cursorPointer,
                                )}
                            />
                        )}
                        <Typography variant={title.variant ?? 'h4'}>
                            {title.content}
                        </Typography>
                        {actions}
                    </Box>
                    <Box className={iconWrapper}>
                        {userInformationCta && (
                            <InfoIcon
                                onClick={userInformationCta}
                                className={icon}
                            />
                        )}
                        <Typography variant="body2">{subtitle}</Typography>
                    </Box>
                </Box>

                {children}
            </Grid>
        );
    },
);

export { SharedHeader };
