import React, { FC, memo } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { DIALOG_STATE } from '../../types';
import { SharedHeader } from './shared-header';

//react node arrays
// > first children is for the header
// > second children is for the content

interface IModalProps {
    isOpen: boolean;
    children: React.ReactNode[];
    close?: (state: DIALOG_STATE) => void;
    title?: string;
    onConfirm?: () => void;
    hideCancel?: boolean;
}

const Modal: FC<IModalProps> = memo(
    ({ isOpen, close, title, children, onConfirm, hideCancel }) => {
        const { t } = useTranslation();
        const theme = useTheme();

        return (
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    <SharedHeader
                        title={{
                            content: title ?? '',
                            variant: 'h6',
                        }}
                    >
                        {children[0]}
                    </SharedHeader>
                </DialogTitle>
                <DialogContent>{children[1]}</DialogContent>
                <DialogActions>
                    {!hideCancel && (
                        <Button
                            color="secondary"
                            size="large"
                            onClick={() => {
                                if (close) close(DIALOG_STATE.DISMISSED);
                            }}
                            sx={{ margin: theme.spacing(1) }}
                        >
                            {t('GENERAL.CANCEL')}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            if (onConfirm) onConfirm();
                        }}
                        sx={{ margin: theme.spacing(1) }}
                    >
                        {t('GENERAL.CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

export { Modal };
