import { gql } from '@apollo/client';

export const GET_SCHEDULES_BY_INSTALLATION_ID = gql`
    query findSchedulesByInstallationId(
        $installationId: ID!
        $filter: Filter!
    ) {
        findSchedulesByInstallationId(
            installationId: $installationId
            filter: $filter
        ) {
            schedules {
                _id
                installationId
                scheduledDate
                status
                report
                reportUploader
                maintenance {
                    name
                }
            }
            listInfo {
                limit
                step
                term
                total
            }
        }
    }
`;
