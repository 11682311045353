import { gql } from '@apollo/client';

export const UPDATE_MAINTENANCE_BY_ID = gql`
    mutation updateMaintenanceById(
        $maintenanceId: ID!
        $input: UpdateMaintenanceInput!
    ) {
        updateMaintenanceById(maintenanceId: $maintenanceId, input: $input) {
            _id
        }
    }
`;
