import { gql } from '@apollo/client';

export const GET_INSTALLATION_BY_ID = gql`
    query getInstallationById($installationId: ID!) {
        getInstallationById(installationId: $installationId) {
            _id
            name
            implementationDate
            user {
                _id
            }
            files {
                uri
                label
                _id
            }
            videos {
                _id
                url
                title
            }
            schedules {
                _id
                maintenance {
                    name
                }
                status
                scheduledDate
                report
                reportUploader
            }
            maintenances {
                _id
                name
                updatedAt
                validDateHash
                isDeleted
            }
            image {
                _id
                uri
            }
            user {
                _id
                fullname
                email
            }
        }
    }
`;
