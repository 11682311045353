import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query getAllUsers($input: ListInput, $filter: UsersFilterInput) {
        getAllUsers(input: $input, filter: $filter) {
            users {
                _id
                fullname
                email
                confirmed
                roles
                phoneNumber
                nextSchedule
            }
            listInfo {
                step
                limit
                term
                total
            }
        }
    }
`;
