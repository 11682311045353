import { gql } from '@apollo/client';

export const UPDATE_SCHEDULE_BY_ID = gql`
    mutation updateScheduleById(
        $scheduleId: ID!
        $input: UpdateScheduleInput!
    ) {
        updateScheduleById(scheduleId: $scheduleId, input: $input) {
            _id
        }
    }
`;
