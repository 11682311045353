/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AlertModalSeverity } from '../types';

export const AlertModalContext = React.createContext({
    visibility: false,
    severity: AlertModalSeverity.success,
    message: '',
    title: '',
    showAlertModal: (
        message: string,
        title: string,
        severity: AlertModalSeverity,
    ) => {
        return;
    },
    hideAlertModal: () => {
        return;
    },
});

const AlertModalContextProvider = (props: { children: any }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [severity, setSeverity] = useState<AlertModalSeverity>(
        AlertModalSeverity.success,
    );
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    const showAlertModal = (
        message: string,
        title: string,
        severity: AlertModalSeverity,
    ) => {
        setMessage(message);
        setTitle(title);
        setSeverity(severity);
        setIsVisible(true);
        setTimeout(() => {
            hideAlertModal();
        }, 6000);
    };

    const hideAlertModal = () => {
        setIsVisible(false);
    };

    return (
        <AlertModalContext.Provider
            value={{
                showAlertModal: showAlertModal,
                hideAlertModal,
                visibility: isVisible,
                severity,
                message,
                title,
            }}
        >
            {props.children}
        </AlertModalContext.Provider>
    );
};

export default AlertModalContextProvider;
