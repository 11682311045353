import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { SharedHeader } from '../../components/shared-components/shared-header';
import { Controller, useForm } from 'react-hook-form';
import ENV, { PATHS } from '../../config/environment';
import HTTP_CLIENT from '../../services/http-client';
import {
    AlertModalSeverity,
    CreateUserControl,
    DIALOG_STATE,
    IPromptActions,
    IUserForm,
    IUserInstallationsRow,
    UserRole,
    UsersTypeOfViews,
} from '../../types';
import useCommonStyles from '../../style/common';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_USER_BY_ID } from '../../graphql/queries/q-get-user-by-id';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import { AxiosResponse } from 'axios';
import { getCities, getDistricts } from '../../services';
import { UPDATE_USER } from '../../graphql/mutations/m-update-user';
import { DELETE_USERS } from '../../graphql/mutations/m-delete-users';
import { AlertModalContext } from '../../context/alertModal-context';
import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import { INVITE_USER } from '../../graphql/queries/q-invite-user';
import { Modal } from '../../components/shared-components/modal';
import {
    ControlledInput,
    ControlledSelect,
    SharedDataGrid,
} from '../../components';
import {
    CREATE_NEW_INSTALLATION_FORM_NAMES,
    REQUIRED_FIELD,
    defaultDraftValue,
} from '../../constants';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import {
    CREATE_INSTALLATION,
    DELETE_INSTALLATION,
} from '../../graphql/mutations/';
import { GET_INSTALLATIONS_BY_USER_ID } from '../../graphql';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
    Routes,
    dataGridDefaults,
    displayConditionaly,
    getStatus,
    validForm,
} from '../../core/utils';
import { SharedLoading } from '../../components/shared-components/loading';

interface ICreateInstallationForm {
    name: string;
    implementationDate: string;
}

enum DataGridColumnsNames {
    NAME = 'name',
    NEXT_MAINTENANCE_DATE = 'nextMaintenanceDate',
    STATUS = 'status',
    ACTIONS = 'actions',
}

const FormUsers = () => {
    const theme = useTheme();
    const history = useHistory();
    const styles = useCommonStyles();
    const { id } = useParams<{ id: string }>();
    const [disabled, setDisabled] = useState(false);
    const [createDialogState, setCreateDialogState] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState(false);
    const [isCreateInstallationVisible, setIsCreateInstallationVisible] =
        useState<boolean>(false);
    const [isChecked, setIsChecked] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);
    const [datePickerValues, setDatePickerValues] = useState<Dayjs | string>(
        dayjs(new Date()).format('YYYY-MM-DD'),
    );
    const [dataGridRows, setDataGridRows] = useState<IUserInstallationsRow[]>(
        [],
    );
    const [promptActions, setPromptActions] = useState<IPromptActions>({
        isVisible: false,
        title: '',
        message: '',
        onClose: () => undefined,
        onConfirm: () => undefined,
    });

    const [currentDistrict, setCurrentDistrict] = useState<string>('');

    const { width100, sharedHeaderWrapper } = useCommonStyles();
    const { t } = useTranslation();
    const { showAlertModal } = useContext(AlertModalContext);
    const { handleSubmit: submitCreation, control: controlCreationForm } =
        useForm<ICreateInstallationForm>();
    const { data: userData, loading: getUserLoading } = useQuery(
        GET_USER_BY_ID,
        {
            variables: {
                id,
            },
            skip: id === UsersTypeOfViews.NEW,
        },
    );
    const [inviteUser] = useLazyQuery(INVITE_USER, {
        variables: {
            id,
        },
    });
    const [deleteUsers] = useMutation(DELETE_USERS);
    const [createInstallation] = useMutation(CREATE_INSTALLATION);
    const [deleteInstallation] = useMutation(DELETE_INSTALLATION);
    const {
        refetch: RefetchAssociatedInstallations,
        loading: getInstallationsLoading,
    } = useQuery(GET_INSTALLATIONS_BY_USER_ID, {
        skip: id === UsersTypeOfViews.NEW,
        variables: {
            userId: id,
        },
        onCompleted: (data) => {
            setDataGridRows(
                data?.getInstallationsByUserId?.map((installation: any) => {
                    return {
                        _id: installation?._id,
                        name: installation?.name,
                        status: installation?.maintenances[0]?.status,
                        nextMaintenanceDate:
                            installation?.maintenances[0]?.nextMaintenanceDate,
                    };
                }),
            );
        },
    });

    useEffect(() => {
        if (!id || !userData) return;
        setDisabled(true);
        const fullNameArray = userData?.getUserById?.fullname.split(' ');
        const firstName = fullNameArray?.shift();
        const lastName = fullNameArray?.join(' ');
        setIsConfirmed(userData?.getUserById?.confirmed);
        setCurrentDistrict(userData?.getUserById?.district);
        reset({
            ...userData?.getUserById,
            firstName: firstName,
            lastName: lastName,
        });
    }, [userData, id]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            city: '',
            district: '',
            address: '',
        },
    });

    const onSubmit = (data: IUserForm) => {
        const body = {
            fullname: `${data.firstName} ${data.lastName}`,
            email: data.email,
            phoneNumber: data.phoneNumber,
            roles: [UserRole.DEFAULT],
            dob: new Date(),
            city: data.city,
            district: data.district,
            address: data.address,
        };

        HTTP_CLIENT.post(`${ENV.REACT_APP_AUTH_ENDPOINT}${PATHS.SIGNUP}`, body)
            .then(async (data: AxiosResponse<{ id: string }>) => {
                const userId = data.data.id;
                history.push(`${Routes.USERS}/${userId}`);
                if (isChecked) {
                    await inviteUser();
                }
                showAlertModal(
                    t('ALERTS.USER_CREATED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                reset();
            })
            .catch(() => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            });
    };

    const handleUpdateUser = (data: IUserForm) => {
        const body = {
            fullname: `${data.firstName} ${data.lastName}`,
            phoneNumber: Number(data.phoneNumber),
            city: data.city,
            district: data.district,
            address: data.address,
        };

        updateUser({
            variables: {
                id: id,
                input: body,
            },
        })
            .then(() => {
                showAlertModal(
                    t('ALERTS.CHANGES_SUCCESSFULLY_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
            })
            .catch(() => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            });
    };

    const removeUserHandler = () => {
        setDeleteDialogState(true);
    };

    const onDeleteDialogCloses = (state: DIALOG_STATE) => {
        setDeleteDialogState(false);
        if (state === DIALOG_STATE.CONFIRMED)
            deleteUsers({
                variables: {
                    id: [id],
                },
            })
                .then(() => {
                    history.push(`${Routes.USERS}`);
                    showAlertModal(
                        t('ALERTS.DELETED_SUCCESSFULLY'),
                        t('ALERTS.SUCCESS'),
                        AlertModalSeverity.success,
                    );
                })
                .catch(() => {
                    showAlertModal(
                        t('ALERTS.ERRORS_REQUEST_FAILED'),
                        t('ALERTS.ERROR'),
                        AlertModalSeverity.error,
                    );
                });
    };

    const onCreateDialogCloses = (state: DIALOG_STATE) => {
        setCreateDialogState(false);
        if (state === DIALOG_STATE.CONFIRMED) {
            handleSubmit(onSubmit as any)();
        }
    };

    const submitCreateInstallationHandler = (data: ICreateInstallationForm) => {
        const { name } = data;

        createInstallation({
            variables: {
                input: {
                    name,
                    implementationDate: dayjs(datePickerValues).toISOString(),
                    descriptionHtml: defaultDraftValue,
                },
                userId: userData.getUserById?._id,
            },
            onCompleted: (res) => {
                showAlertModal(
                    t('ALERTS.INSTALLATION_CREATED_SUCCESSFULLY_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                const installationId = res?.createInstallation?._id;
                history.push(`${Routes.INSTALLATIONS}/${installationId}`);
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    const handleDatePickerChange = (date: Dayjs | null) => {
        setDatePickerValues(dayjs(date).format('YYYY-MM-DD'));
    };

    const handleDeleteInstallation = (installationId: string) => {
        deleteInstallation({
            variables: {
                installationId,
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.INSTALLATION_DELETED_SUCCESSFULLY_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                RefetchAssociatedInstallations();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERROR_INSTALLATION_NOT_REMOVED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    const closeModal = () => {
        setPromptActions((prev: any) => ({
            ...prev,
            isVisible: false,
        }));
    };

    const dataGridColumns: GridColDef[] = [
        {
            field: DataGridColumnsNames.NAME,
            headerName: t('INSTALLATIONS.NAME'),
            ...dataGridDefaults(),
        },
        {
            field: DataGridColumnsNames.NEXT_MAINTENANCE_DATE,
            headerName: t('MAINTENANCE.NEXT_MAINTENANCE'),
            ...dataGridDefaults(),
            renderCell: (params) =>
                dayjs(params.row.nextMaintenanceDate).format('YYYY-MM'),
        },
        {
            field: DataGridColumnsNames.STATUS,
            headerName: t('GENERAL.STATUS'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                const status = getStatus(params.row.status);
                return (
                    <>
                        <Typography color={status.color}>
                            {t(status.text)}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: DataGridColumnsNames.ACTIONS,
            headerName: '',
            align: 'right',
            ...dataGridDefaults(),
            renderCell: (params: GridCellParams) => {
                const installationId: string = params.row._id;

                return (
                    <Button
                        onClick={(
                            event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                            >,
                        ) => {
                            setPromptActions({
                                isVisible: true,
                                title: t('PROMPTS.REMOVE_INSTALLATION_TITLE'),
                                message: t(
                                    'PROMPTS.REMOVE_INSTALLATION_MESSAGE',
                                ),
                                onClose: () => closeModal,
                                onConfirm: () =>
                                    handleDeleteInstallation(installationId),
                            });
                            event.stopPropagation();
                        }}
                        color="error"
                    >
                        {t('GENERAL.REMOVE')}
                    </Button>
                );
            },
        },
    ];

    const loading = getInstallationsLoading || getUserLoading;

    const switchDistrict = (district: string) => setCurrentDistrict(district);

    return (
        <>
            <Grid item xs={12}>
                <form>
                    <SharedHeader
                        title={{
                            content: !userData
                                ? t('GENERAL.FORM_USERS')
                                : disabled
                                ? t('GENERAL.CLIENT_FILE')
                                : t('GENERAL.EDIT_CLIENT'),
                            variant: 'h4',
                        }}
                        actions={
                            <>
                                {id && !isConfirmed && (
                                    <>
                                        <Tooltip
                                            title={
                                                t(
                                                    'USERS.NOT_REGISTED_MESSAGE',
                                                ) as string
                                            }
                                            placement="top-start"
                                            arrow
                                            className={clsx(
                                                styles.spacingSmall2,
                                                styles.tooltipInfoIcon,
                                            )}
                                        >
                                            <IconButton>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {id !== UsersTypeOfViews.NEW && (
                                            <Button
                                                className={
                                                    styles.textTransformNone
                                                }
                                                onClick={() => {
                                                    inviteUser()
                                                        .then(() => {
                                                            showAlertModal(
                                                                t(
                                                                    'ALERTS.INVITED_SUCCESSFULLY',
                                                                ),
                                                                t(
                                                                    'ALERTS.SUCCESS',
                                                                ),
                                                                AlertModalSeverity.success,
                                                            );
                                                        })
                                                        .catch(() => {
                                                            showAlertModal(
                                                                t(
                                                                    'ALERTS.ERRORS_REQUEST_FAILED',
                                                                ),
                                                                t(
                                                                    'ALERTS.ERROR',
                                                                ),
                                                                AlertModalSeverity.error,
                                                            );
                                                        });
                                                }}
                                            >
                                                <SendIcon
                                                    fontSize={'small'}
                                                    color="primary"
                                                />
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                                                    }}
                                                    color="primary"
                                                >
                                                    {t('USERS.SEND_INVITE')}
                                                </Typography>
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        }
                    >
                        {!id || !userData ? (
                            <Box>
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    onClick={() => history.goBack()}
                                    size="large"
                                >
                                    {t('GENERAL.CANCEL')}
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleSubmit(onSubmit as any)();
                                    }}
                                    disabled={!validForm(errors, isDirty)}
                                    size="large"
                                >
                                    {t('GENERAL.CREATE')}
                                </Button>
                            </Box>
                        ) : !disabled ? (
                            <Box>
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    onClick={() => {
                                        setDisabled(true);
                                    }}
                                    size="large"
                                >
                                    {t('GENERAL.CANCEL')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    startIcon={<SaveIcon />}
                                    onClick={() => {
                                        handleSubmit(handleUpdateUser as any)();
                                        setDisabled(true);
                                    }}
                                    disabled={!validForm(errors, isDirty)}
                                    size="large"
                                >
                                    {t('GENERAL.SAVE')}
                                </Button>
                            </Box>
                        ) : (
                            <Box>
                                <Button
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    startIcon={<EditOutlinedIcon />}
                                    onClick={() => {
                                        setDisabled(false);
                                    }}
                                    size="large"
                                >
                                    {t('GENERAL.EDIT_DATA')}
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        removeUserHandler();
                                    }}
                                    startIcon={<DeleteForeverRoundedIcon />}
                                    size="large"
                                >
                                    {t('USERS.DELETE_USER')}
                                </Button>
                            </Box>
                        )}
                    </SharedHeader>
                    <Grid container className={styles.spacingTopSmall3}>
                        {displayConditionaly(
                            loading,
                            <SharedLoading />,
                            <React.Fragment>
                                <Grid item md={4} xs={12}>
                                    {id === UsersTypeOfViews.NEW && (
                                        <FormControlLabel
                                            value="start"
                                            control={
                                                <Checkbox
                                                    defaultChecked
                                                    value={isChecked}
                                                    onChange={() => {
                                                        setIsChecked(
                                                            !isChecked,
                                                        );
                                                    }}
                                                />
                                            }
                                            label={t('USERS.CREATE_N_INVITE')}
                                            labelPlacement="start"
                                        />
                                    )}
                                    <FormControl fullWidth>
                                        <Controller
                                            name={CreateUserControl.FIRSTNAME}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    disabled={disabled}
                                                    id={
                                                        CreateUserControl.FIRSTNAME
                                                    }
                                                    className={
                                                        styles.formControl
                                                    }
                                                    fullWidth
                                                    label={t('USERS.FIRSTNAME')}
                                                    variant="outlined"
                                                    error={!!error}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={CreateUserControl.LASTNAME}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    disabled={disabled}
                                                    id={
                                                        CreateUserControl.LASTNAME
                                                    }
                                                    className={
                                                        styles.formControl
                                                    }
                                                    fullWidth
                                                    label={t('USERS.LASTNAME')}
                                                    variant="outlined"
                                                    error={!!error}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={CreateUserControl.ADDRESS}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    disabled={disabled}
                                                    id={
                                                        CreateUserControl.ADDRESS
                                                    }
                                                    className={
                                                        styles.formControl
                                                    }
                                                    fullWidth
                                                    label={t('USERS.ADDRESS')}
                                                    variant="outlined"
                                                    error={!!error}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Box className={styles.formControl}>
                                        <ControlledSelect
                                            name={CreateUserControl.DISTRICT}
                                            items={getDistricts()}
                                            control={control}
                                            label={t('GENERAL.DISTRICT')}
                                            rules={REQUIRED_FIELD}
                                            disabled={disabled}
                                            cOnChange={(value: any) => {
                                                switchDistrict(
                                                    value.target.value,
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Box className={styles.formControl}>
                                        <ControlledSelect
                                            name={CreateUserControl.CITY}
                                            items={
                                                getCities(currentDistrict) ?? []
                                            }
                                            control={control}
                                            label={t('GENERAL.CITY')}
                                            rules={REQUIRED_FIELD}
                                            disabled={disabled}
                                        />
                                    </Box>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={CreateUserControl.EMAIL}
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: ENV.REACT_APP_EMAIL_REGEX,
                                                    message: t(
                                                        'GENERAL.INVALID_EMAIL_ADDRESS',
                                                    ) as string,
                                                },
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    disabled={
                                                        id !==
                                                        UsersTypeOfViews.NEW
                                                    }
                                                    className={
                                                        styles.formControl
                                                    }
                                                    variant="outlined"
                                                    fullWidth
                                                    id={CreateUserControl.EMAIL}
                                                    label={t('USERS.EMAIL')}
                                                    error={!!error}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        error
                                                            ? error.message
                                                            : null
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <Controller
                                            name={CreateUserControl.PHONENUMBER}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    disabled={disabled}
                                                    className={
                                                        styles.formControl
                                                    }
                                                    id={
                                                        CreateUserControl.PHONENUMBER
                                                    }
                                                    label={t('Contacto')}
                                                    variant="outlined"
                                                    error={!!error}
                                                    value={value}
                                                    onChange={(evt) =>
                                                        onChange(
                                                            (
                                                                evt.target
                                                                    .value || ''
                                                            ).replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            ),
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    {id && userData && (
                                        <Box
                                            className={styles.spacingTopSmall3}
                                        >
                                            <Typography variant="subtitle1">
                                                {t('USERS.CONFIRMED')}
                                                {userData?.getUserById
                                                    ?.confirmed
                                                    ? t('GENERAL.YES')
                                                    : t('GENERAL.NO')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                                {id && userData && disabled && (
                                    <Grid
                                        item
                                        xs
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        className={
                                            styles.associatedInstallationsWrapper
                                        }
                                    >
                                        {!!dataGridRows.length && (
                                            <>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    className={clsx(
                                                        width100,
                                                        sharedHeaderWrapper,
                                                    )}
                                                >
                                                    <SharedHeader
                                                        className={
                                                            styles.spacingBottomSmall1
                                                        }
                                                        title={{
                                                            content: t(
                                                                'USERS.ASSOCIATED_INSTALLATIONS',
                                                            ),
                                                            variant: 'h5',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            onClick={() =>
                                                                setIsCreateInstallationVisible(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                'USERS.ASSOCIATE_NEW_INSTALLATION',
                                                            )}
                                                        </Button>
                                                    </SharedHeader>
                                                </Box>
                                                <Box
                                                    className={width100}
                                                    sx={{ height: '10vh' }}
                                                >
                                                    <SharedDataGrid
                                                        generateOwnIds
                                                        className={
                                                            styles.sharedDataGrid
                                                        }
                                                        rows={
                                                            dataGridRows ?? []
                                                        }
                                                        columns={
                                                            dataGridColumns
                                                        }
                                                        configuration={{
                                                            hideFooter: true,
                                                            autoHeight: true,
                                                        }}
                                                        disableSelectionOnClick
                                                        onRowClick={(
                                                            params,
                                                            event: any,
                                                        ) => {
                                                            if (!event.ignore) {
                                                                history.push(
                                                                    `${Routes.INSTALLATIONS}/${params?.row?._id}`,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                        {!dataGridRows.length && (
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                flexDirection="column"
                                            >
                                                <Typography variant="h6">
                                                    {t(
                                                        'USERS.NO_INSTALLATIONS',
                                                    )}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<MenuIcon />}
                                                    onClick={() =>
                                                        setIsCreateInstallationVisible(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        'USERS.ASSOCIATE_NEW_INSTALLATION',
                                                    )}
                                                </Button>
                                            </Box>
                                        )}
                                    </Grid>
                                )}
                            </React.Fragment>,
                        )}
                    </Grid>
                </form>
                <Modal
                    isOpen={isCreateInstallationVisible}
                    close={() => setIsCreateInstallationVisible(false)}
                    onConfirm={() =>
                        submitCreation(submitCreateInstallationHandler)()
                    }
                    title={t('INSTALLATIONS.NEW_INSTALLATION')}
                >
                    <></>
                    <>
                        <form
                            id={CREATE_NEW_INSTALLATION_FORM_NAMES.FORM_NAME}
                            onSubmit={submitCreation(
                                submitCreateInstallationHandler,
                            )}
                        >
                            <Box
                                className={styles.spacingTopSmall3}
                                sx={{ maxWidth: '50%' }}
                            >
                                <ControlledInput
                                    name={
                                        CREATE_NEW_INSTALLATION_FORM_NAMES.NAME
                                    }
                                    label={t('GENERAL.NAME')}
                                    control={controlCreationForm}
                                    rules={REQUIRED_FIELD}
                                />
                            </Box>
                            <Box
                                className={styles.spacingTopSmall3}
                                sx={{ maxWidth: '50%' }}
                            >
                                <DesktopDatePicker
                                    label={t('INSTALLATIONS.INSTALLATION_DATE')}
                                    inputFormat="DD/MM/YYYY"
                                    value={datePickerValues}
                                    onChange={handleDatePickerChange}
                                    renderInput={(params) => (
                                        <ControlledInput
                                            params={params}
                                            name={
                                                CREATE_NEW_INSTALLATION_FORM_NAMES.INSTALLATION_DATE
                                            }
                                            label={t('GENERAL.NAME')}
                                            control={controlCreationForm}
                                            sx={{ width: '20vw' }}
                                            cValue={datePickerValues?.toString()}
                                        />
                                    )}
                                />
                            </Box>
                        </form>
                    </>
                </Modal>
                <ConfirmationDialog
                    isOpen={deleteDialogState}
                    close={onDeleteDialogCloses}
                    title={t('USERS.DELETE_USER_TITLE')}
                    message={t('USERS.DELETE_USER_MESSAGE')}
                />
                <ConfirmationDialog
                    isOpen={createDialogState}
                    close={onCreateDialogCloses}
                    title={t('USERS.CREATED_SUCCESSFULLY_TITLE')}
                    message={t('USERS.CREATED_SUCCESSFULLY')}
                />
            </Grid>
            <ConfirmationDialog
                isOpen={promptActions?.isVisible}
                close={closeModal}
                onConfirm={promptActions?.onConfirm}
                title={promptActions?.title}
                message={promptActions?.message ?? ''}
            />
        </>
    );
};

export { FormUsers };
