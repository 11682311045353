import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Grid, TextField } from '@mui/material';
import useCommonStyles from '../style/common';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface TechFile {
    uri: string;
    label: string;
}

interface ITechFileProps {
    TechFile: TechFile[];
    removeFile: (fileIndex: number) => void;
    updateFileLabel: (fileIndex: number, label: string) => void;
}

const TechnicalFiles: FC<ITechFileProps> = ({
    TechFile,
    removeFile,
    updateFileLabel,
}: ITechFileProps) => {
    const { t } = useTranslation();
    const { cursorPointer } = useCommonStyles();
    const { spacingLeftSmall3 } = useCommonStyles();
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const openFile = (url: string) => window.open(url, '_blank');

    const saveFile = () => setSelectedIndex(null);

    return (
        <Grid
            item
            display="flex"
            alignItems="space-between"
            flexDirection="column"
        >
            {TechFile?.length &&
                TechFile.map((techFile, index) => (
                    <Box key={index}>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="space-between"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <Grid item xs display="flex">
                                <Grid
                                    item
                                    xs
                                    display="flex"
                                    alignItems="center"
                                >
                                    {selectedIndex === index && (
                                        <SaveIcon
                                            color="primary"
                                            className={cursorPointer}
                                            onClick={saveFile}
                                        />
                                    )}
                                    {selectedIndex !== index && (
                                        <VisibilityIcon
                                            color="primary"
                                            onClick={() =>
                                                openFile(techFile?.uri)
                                            }
                                            className={cursorPointer}
                                        />
                                    )}
                                    <TextField
                                        className={spacingLeftSmall3}
                                        defaultValue={techFile?.label}
                                        variant="standard"
                                        onChange={(e) => {
                                            updateFileLabel(
                                                index,
                                                e.target.value,
                                            );
                                        }}
                                        onDoubleClick={() =>
                                            setSelectedIndex(index)
                                        }
                                        InputProps={{
                                            disableUnderline:
                                                index !== selectedIndex,
                                        }}
                                    />
                                </Grid>
                                <Button
                                    size="large"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => removeFile(index)}
                                >
                                    {t('GENERAL.REMOVE')}
                                </Button>
                            </Grid>
                            <hr />
                        </Grid>
                    </Box>
                ))}
        </Grid>
    );
};

export { TechnicalFiles };
