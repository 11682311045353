import React, { FC, memo, useContext } from 'react';
import clsx from 'clsx';
import { Box, Grid } from '@mui/material';
import { UploadButton } from './upload-button';
import { useTranslation } from 'react-i18next';
import { fileUploadHandler } from '../../core/utils';
import { AlertModalSeverity, UploadFileType, UploadImages } from '../../types';
import useCommonStyles, { useStructureStyles } from '../../style/common';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AlertModalContext } from '../../context/alertModal-context';

interface IImagePlaceholderProps {
    image: UploadImages | undefined;
    setImage: React.Dispatch<React.SetStateAction<UploadImages | undefined>>;
    className?: string;
    disabled?: boolean;
}

const ImagePlaceholder: FC<IImagePlaceholderProps> = memo(
    ({ image, setImage, disabled, className }) => {
        const { t } = useTranslation();
        const { FLEX_COL_START, FLEX_COL_START_CENTER } = useStructureStyles();
        const { showAlertModal } = useContext(AlertModalContext);

        const {
            uploadImagePlaceholderContent,
            uploadImagePlaceholder,
            spacingBottomSmall1,
            spacingSmall2,
            cursorPointer,
            width100,
        } = useCommonStyles();

        const imageDeletionHandler = () => {
            setImage(undefined);
            showAlertModal(
                t('ALERTS.REMOVE_IMAGE_SUCCESS_MESSAGE'),
                t('ALERTS.SUCCESS'),
                AlertModalSeverity.success,
            );
        };

        return (
            <Grid
                width="100%"
                className={clsx(FLEX_COL_START_CENTER, className)}
            >
                <Box
                    className={clsx(FLEX_COL_START_CENTER, !image && width100)}
                >
                    <Box className={clsx(uploadImagePlaceholder)}>
                        {image && image?.avatar && (
                            <img
                                src={image?.avatar as unknown as string}
                                className={uploadImagePlaceholderContent}
                            />
                        )}
                        {!image?.avatar && (
                            <UploadButton
                                variant="contained"
                                disabled={disabled}
                                className={spacingSmall2}
                                onChange={(file: File) => {
                                    fileUploadHandler(
                                        file,
                                        setImage,
                                        UploadFileType.IMAGE,
                                    );
                                    showAlertModal(
                                        t(
                                            'ALERTS.UPLOAD_IMAGE_SUCCESS_MESSAGE',
                                        ),
                                        t('ALERTS.SUCCESS'),
                                        AlertModalSeverity.success,
                                    );
                                }}
                            >
                                {t('GENERAL.ADD_IMAGE')}
                            </UploadButton>
                        )}
                    </Box>
                </Box>
                {image && image?.avatar && !disabled && (
                    <Box className={FLEX_COL_START}>
                        <DeleteForeverIcon
                            className={clsx(spacingBottomSmall1, cursorPointer)}
                            onClick={imageDeletionHandler}
                            color="primary"
                        />
                    </Box>
                )}
            </Grid>
        );
    },
);

export { ImagePlaceholder };
