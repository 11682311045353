import React from 'react';
import Lottie from 'react-lottie';
import { Grid } from '@mui/material';
import useCommonStyles from '../../style/common';

const SharedLoading = () => {
    const { loadingSpinner } = useCommonStyles();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../assets/loading.json'),
        name: 'loading',
    };

    return (
        <Grid
            item
            xs
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={loadingSpinner}
        >
            <Lottie options={defaultOptions} width="100%" height="100%" />
        </Grid>
    );
};

export { SharedLoading };
