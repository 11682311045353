import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
    Dashboard,
    InstallationDetails,
    Settings,
    Shell,
    Users,
} from '../containers';
import { FormUsers } from '../containers/users/form-users';
import { AddEditMaintenances, Maintenances } from '../containers/maintenances';
import { Highlights } from '../containers/highlights/highlights';

import { useAppSelector } from '../store';
import useCommonStyles from '../style/common';
import { CreateEditHighlight } from '../containers/highlights/create-edit-highlight';
import { Routes } from '../core/utils';

export default function ShellRouting() {
    const { path } = useRouteMatch();
    const userState = useAppSelector((state) => state.user);
    const { contentSpacing } = useCommonStyles();

    if (!userState.access_token) return <Redirect to="/login" />;

    const routes = [
        {
            path: Routes.DASHBOARD,
            component: <Dashboard />,
        },
        {
            path: `${Routes.MAINTENANCES}/:action/:id?`,
            component: <AddEditMaintenances />,
        },
        {
            path: Routes.MAINTENANCES,
            component: <Maintenances />,
        },
        {
            path: `${Routes.INSTALLATIONS}/:id`,
            component: <InstallationDetails />,
        },
        {
            path: `${Routes.USERS}/:id`,
            component: <FormUsers />,
        },
        {
            path: Routes.USERS,
            component: <Users />,
        },
        {
            path: Routes.SETTINGS,
            component: <Settings />,
        },
        {
            path: `${Routes.HIGHLIGHTS}/:id`,
            component: <CreateEditHighlight />,
        },
        {
            path: `${Routes.HIGHLIGHTS}/create`,
            component: <CreateEditHighlight />,
        },
        {
            path: Routes.HIGHLIGHTS,
            component: <Highlights />,
        },
    ];

    return (
        <div className={contentSpacing}>
            <Switch>
                <Route
                    exact
                    path={path}
                    render={() => {
                        return <Redirect to={Routes.DASHBOARD} />;
                    }}
                />
                {routes.map((route) => {
                    return (
                        <Route key={route.path} path={route.path}>
                            <Shell basePath={path}>{route.component}</Shell>
                        </Route>
                    );
                })}
            </Switch>
        </div>
    );
}
