import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
    query getUserById($id: ID!) {
        getUserById(id: $id) {
            _id
            fullname
            email
            confirmed
            createdAt
            phoneNumber
            roles
            city
            district
            address
        }
    }
`;
