export enum INSTALLATIONS_FORM_NAMES {
    NAME = 'name',
    INSTALLATION_DATE = 'implementationDate',
    FORM_NAME = 'maintenancesActions',
}

export enum CREATE_NEW_INSTALLATION_FORM_NAMES {
    NAME = 'name',
    INSTALLATION_DATE = 'implementationDate',
    FORM_NAME = 'installationForm',
}

export enum INSTALLATIONS_VIDEOS_FORM_NAMES {
    TITLE = 'title',
    URL = 'url',
}
