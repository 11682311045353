import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    Box,
    Button,
    ClassNameMap,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { SharedHeader } from '../../components/shared-components/shared-header';
import useCommonStyles, {
    hightlightCard,
    useStructureStyles,
} from '../../style/common';

import { Routes, displayConditionaly, nPages } from '../../core/utils';
import { useMutation, useQuery } from '@apollo/client';
import { GET_HIGHLIGHTS } from '../../graphql/queries/q-get-highlights';
import {
    AlertModalSeverity,
    HighlightViewAction,
    HighlightsData,
    findHighlights,
} from '../../types';
import { useDebounce } from '../../hooks';
import { STARR_HIGHLIGHT } from '../../graphql/mutations/m-starr-highlight';
import { HighlightCard } from '../../components/highlights/highlight-card';
import { SharedPagination } from '../../components/shared-components/pagination';
import { SharedLoading } from '../../components/shared-components/loading';
import { AlertModalContext } from '../../context/alertModal-context';

const HighlightsCards = (props: {
    currentStarred: HighlightsData[];
    highlights: HighlightsData[];
    nOfHighlights: number;
    currentPage: number;
    chooseFav: (highlightId: string) => void;
    handleChangePage: (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => void;
}) => {
    const highlightCardClasses: ClassNameMap<
        'root' | 'nonStarredBorder' | 'starredBorder' | 'bannerImage'
    > = hightlightCard();

    return (
        <React.Fragment>
            {!!props?.currentStarred.length &&
                props?.currentStarred?.map((item, index) => {
                    return (
                        <HighlightCard
                            key={index}
                            id={item?._id}
                            image={item?.image}
                            title={item?.title}
                            descriptionHtml={item?.descriptionHtml}
                            isStarred={item?.isStarred}
                            chooseFav={props?.chooseFav}
                            borderClass={highlightCardClasses?.starredBorder}
                        />
                    );
                })}
            {!!props?.highlights.length &&
                props?.highlights?.map((item, index) => {
                    return (
                        <HighlightCard
                            key={index}
                            id={item?._id}
                            image={item?.image}
                            title={item?.title}
                            descriptionHtml={item?.descriptionHtml}
                            isStarred={item?.isStarred}
                            chooseFav={props?.chooseFav}
                            borderClass={highlightCardClasses?.nonStarredBorder}
                        />
                    );
                })}

            <SharedPagination
                count={nPages(props.nOfHighlights, 3)}
                onChange={props.handleChangePage}
                page={props.currentPage}
            />
        </React.Fragment>
    );
};

const NoHighlightsCards = (props: { description: string }) => {
    const useStructureStylesClasses = useStructureStyles();

    const { t } = useTranslation();

    const history = useHistory();

    return (
        <Box className={useStructureStylesClasses.FLEX_COL_CENTER}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                {props.description}
            </Typography>
            <Button
                variant="contained"
                size="large"
                onClick={() =>
                    history.push(
                        `${Routes.HIGHLIGHTS}/${HighlightViewAction.CREATE}`,
                    )
                }
            >
                {t('HIGHLIGHTS.CREATE_NEW_HIGHLIGHTS')}
            </Button>
        </Box>
    );
};

const Highlights = () => {
    const { spacingBottomLarge, spacingBottomSmall3, highlightsInputMargin } =
        useCommonStyles();

    const { t } = useTranslation();

    const history = useHistory();

    const [debounce] = useDebounce();

    const { showAlertModal } = useContext(AlertModalContext);
    const [highlights, setHighlights] = useState<HighlightsData[]>([]);
    const [currentStarred, setCurrentStarred] = useState<HighlightsData[]>([
        {
            _id: '',
            title: '',
            descriptionHtml: '',
            isStarred: true,
            image: '',
        },
    ]);
    const [term, setTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [nOfHighlights, setNOfHighlights] = useState(0);
    const [searchUsed, setSearchUsed] = useState(false);

    const { refetch, loading } = useQuery<{
        findHighlights: findHighlights;
    }>(GET_HIGHLIGHTS, {
        variables: {
            filter: {
                term,
                step: currentPage - 1,
                limit: 3,
            },
        },
        onCompleted: (data) => {
            setHighlights(data?.findHighlights?.highlights);
            setCurrentStarred(data?.findHighlights?.currentStarred);

            if (data.findHighlights.listInfo.total === 0 && currentStarred)
                return setNOfHighlights(1);

            setNOfHighlights(data.findHighlights.listInfo.total);
        },
    });

    const [starrHighlight] = useMutation(STARR_HIGHLIGHT);

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentPage(value);
    };

    const chooseFav = (highlightId: string) => {
        starrHighlight({
            variables: {
                highlightId,
            },
            onCompleted: () => {
                refetch();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.HIGHLIGHT_LIMIT_REACHED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });
    };

    return (
        <Box>
            <SharedHeader
                title={{
                    content: t('GENERAL.HIGHLIGHTS_LIST'),
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            >
                {nOfHighlights > 0 && (
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() =>
                            history.push(
                                `${Routes.HIGHLIGHTS}/${HighlightViewAction.CREATE}`,
                            )
                        }
                    >
                        {t('HIGHLIGHTS.CREATE_NEW_HIGHLIGHTS')}
                    </Button>
                )}
            </SharedHeader>
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                className={clsx(spacingBottomSmall3, highlightsInputMargin)}
            >
                <TextField
                    variant="outlined"
                    placeholder={t('GENERAL.SEARCH_BY_NAME')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        debounce(undefined, e.target.value, setTerm);
                        setSearchUsed(true);
                        setCurrentPage(1);
                    }}
                />
            </Grid>
            {displayConditionaly(
                loading,
                <SharedLoading />,
                <Box>
                    {displayConditionaly(
                        nOfHighlights > 0,
                        <HighlightsCards
                            currentStarred={currentStarred}
                            chooseFav={chooseFav}
                            highlights={highlights}
                            nOfHighlights={nOfHighlights}
                            handleChangePage={handleChangePage}
                            currentPage={currentPage}
                        />,
                        <NoHighlightsCards
                            description={
                                searchUsed
                                    ? t(
                                          'HIGHLIGHTS.DOES_NOT_EXIST_HIGHLIGHT_FILTER',
                                      )
                                    : t(
                                          'HIGHLIGHTS.DOES_NOT_EXIST_HIGHLIGHT_YET',
                                      )
                            }
                        />,
                    )}
                </Box>,
            )}
        </Box>
    );
};

export { Highlights };
