import { gql } from '@apollo/client';

export const GET_HIGHLIGHT_BY_ID = gql`
    query findHighlightById($highlightId: ID!) {
        findHighlightById(highlightId: $highlightId) {
            _id
            title
            descriptionHtml
            image
            isStarred
        }
    }
`;
