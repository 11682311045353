import { gql } from '@apollo/client';

export const GET_HIGHLIGHTS = gql`
    query findHighlights($filter: Filter) {
        findHighlights(filter: $filter) {
            currentStarred {
                _id
                title
                descriptionHtml
                isStarred
                image
            }
            highlights {
                _id
                title
                descriptionHtml
                isStarred
                image
            }
            listInfo {
                step
                limit
                term
                total
                skip
            }
        }
    }
`;
