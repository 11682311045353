import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import {
    getDateWithFullMonth,
    schedulerAppointmentColor,
} from '../../../core/utils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler';
import { useSchedulerStyles, useStructureStyles } from '../../../style/common';
import { useQuery } from '@apollo/client';
import { GET_INSTALLATION_BY_ID } from '../../../graphql';

const SchedulerTooltipContent: FC<AppointmentTooltip.ContentProps> = ({
    appointmentData,
    ...restProps
}) => {
    const history = useHistory();
    const { FLEX_ROW_CENTER_START } = useStructureStyles();
    const {
        tooltipContentScheduleStatus,
        clickableText,
        dxItemInfoWrapper,
        marginInfoAndStatus,
    } = useSchedulerStyles();

    const { data } = useQuery(GET_INSTALLATION_BY_ID, {
        variables: {
            installationId: appointmentData?.installationId,
        },
    });

    const navigateToInstallation = useCallback(() => {
        history.push(`/shell/installations/${appointmentData?.installationId}`);
    }, []);

    return (
        <Box sx={{ padding: '1.5vh 0vw' }}>
            <Grid
                item
                xs
                display="flex"
                alignItems="center"
                className={dxItemInfoWrapper}
            >
                <Grid item xs={1}>
                    <Box
                        className={tooltipContentScheduleStatus}
                        sx={{
                            backgroundColor: schedulerAppointmentColor(
                                appointmentData?.status,
                            ),
                        }}
                    ></Box>
                </Grid>
                <Grid item xs={10} className={marginInfoAndStatus}>
                    <Box display="flex" flexDirection="column">
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="GrayText"
                            className={clickableText}
                            onClick={navigateToInstallation}
                        >
                            {appointmentData?.title}
                        </Typography>
                        <Box display="flex">
                            <Typography
                                variant="body2"
                                onClick={navigateToInstallation}
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {data?.getInstallationById?.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body2">
                        {appointmentData?.startDate &&
                            getDateWithFullMonth(+appointmentData?.startDate)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                xs
                display="flex"
                alignItems="center"
                className={dxItemInfoWrapper}
            >
                <Grid item xs={1} className={FLEX_ROW_CENTER_START}>
                    <AccessTimeIcon color="action" fontSize="small" />
                </Grid>
                <Grid item xs={10} className={marginInfoAndStatus}>
                    <Typography variant="body2">
                        {appointmentData?.startHour}
                        {' - '}
                        {appointmentData?.endHour}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export { SchedulerTooltipContent };
