import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useCommonStyles = makeStyles((theme: Theme) =>
    createStyles({
        displayHeight: { height: '100vh' },
        height100: {
            height: '100%',
        },
        width100: {
            width: '100%',
        },
        dataGridDisabledSeparator: {
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
        },
        spacingSmall: {
            margin: theme.spacing(1),
        },
        spacingSmall2: {
            margin: theme.spacing(2),
        },
        spacingSmall3: {
            margin: theme.spacing(3),
        },
        spacingRightSmall2: {
            marginRight: theme.spacing(2),
        },
        spacingMedium: {
            margin: theme.spacing(4),
        },
        spacingMedium2: {
            margin: theme.spacing(5),
        },
        spacingMedium3: {
            margin: theme.spacing(6),
        },
        spacingTopSmall: {
            marginTop: theme.spacing(1),
        },
        spacingBottomLarge: {
            marginBottom: theme.spacing(8),
        },
        spacingTopSmall3: {
            marginTop: theme.spacing(3),
        },
        spacingBottomSmall1: {
            marginBottom: theme.spacing(1),
        },
        spacingBottomSmall3: {
            marginBottom: theme.spacing(3),
        },
        spacingLeftSmall3: {
            marginLeft: theme.spacing(3),
        },
        spacingLeftSmall1: {
            marginLeft: theme.spacing(1),
        },
        paddingSmall3: {
            padding: theme.spacing(3),
        },
        contentSpacing: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(5),
            },
        },
        textTransformNone: { textTransform: 'none' },
        sharedDataGrid: {
            rowHeight: theme.spacing(12),
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: themeColors.primary,
                color: themeColors.textLight,
            },
            '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
                {
                    outline: 'none',
                },
            '& .MuiDataGrid-cell': {
                cursor: 'pointer',
            },
        },
        imageInsidePlaceholder: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
        },
        imagePlaceholderUploadButton: {
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
        imagePlaceholderBorder: {
            border: '1px dashed grey',
            borderRadius: theme.spacing(1),
        },
        imagePlaceholder: {
            position: 'relative',
            width: '100%',
            height: '25vh',
            overflow: 'hidden',
        },
        cursorPointer: { cursor: 'pointer' },
        leftSpacing: {
            marginLeft: theme.spacing(2),
        },
        formControl: {
            marginTop: theme.spacing(3),
        },
        uploadImagePlaceholder: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        uploadImagePlaceholderContent: {
            maxHeight: '20vh',
            maxWidth: '25vh',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
        },

        tooltipInfoIcon: {
            fill: 'rgba(0, 0, 0, 0.54)',
        },
        icon: {
            marginRight: theme.spacing(1),
            color: themeColors.neutral07,
            cursor: 'pointer',
            fontSize: '1.2rem',

            '&:hover': {
                color: themeColors.primary,
            },
        },
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
        },
        informationCard: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
        usersNavBar: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        usersFilters: {
            [theme.breakpoints.down('sm')]: {
                flexGrow: 1,
                flexDirection: 'column',
                maxWidth: '100vw',
                alignItems: 'flex-start',
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
                flexDirection: 'row',
            },
        },
        loadingSpinner: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            [theme.breakpoints.down('sm')]: {
                width: '70vw',
                height: '70vw',
            },
            [theme.breakpoints.up('md')]: {
                width: '35vw',
                height: '35vw',
            },
        },
        associatedInstallationsWrapper: {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                margin: theme.spacing(5, 0, 5, 0),
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(12),
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: theme.spacing(10),
            },
        },
        sharedHeaderWrapper: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(2),
            },
        },
        sharedHeader: {
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: theme.spacing(1),
            },
        },
        installationInfoWrapper: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                flexGrow: 1,
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '60%',
            },
        },
        installationNameWrapper: {
            [theme.breakpoints.up('md')]: {
                width: '20vw',
                color: 'blue',
            },
        },
        installationFormWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(5),
            },
        },
        maintenancesSearchWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(12),
            },
        },
        maintenanceFormWrapper: {
            display: 'flex',
            flexDirection: 'row',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                width: '100%',
                marginTop: theme.spacing(5),
            },
        },
        maintenanceFormInputs: {
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        maintenanceImage: {
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(3, 0, 4, 0),
            },
        },
        highlightsInputMargin: {
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(6),
            },
        },
        createHighlightFormWrapper: {
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        createHighlightFormWrapperInput: {
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(5),
            },
        },
        associateNewMaintenanceItem: {
            width: '45%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        calendarWrapper: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        calendarDay: {
            margin: 0,
            '& .Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.24) !important',
            },
            '& .MuiPickersDay-root': {
                color: `${themeColors.primary}`,
            },
        },
    }),
);

export const useStructureStyles = makeStyles(() =>
    createStyles({
        FLEX: {
            display: 'flex',
        },
        FLEX_COL_CENTER: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        FLEX_ROW_CENTER: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        FLEX_COL_START: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        FLEX_ROW_CENTER_END: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        FLEX_ROW_CENTER_START: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        FLEX_ROW_START_END: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
        },
        FLEX_COL_START_CENTER: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
    }),
);

export const alertsStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&.MuiAlert-root': {
                padding: theme.spacing(0),
                paddingLeft: theme.spacing(1),
            },
            '& .MuiAlert-message': {
                padding: '0px',
            },
        },

        alertWrapper: {
            backgroundColor: themeColors.neutral01,
            color: themeColors.neutral07,
            paddingLeft: theme.spacing(6),
            boxShadow: themeElevation.elevationFive,
            minWidth: theme.spacing(50),
        },
        alertTitle: {
            fontWeight: 'bolder',
            fontSize: '1rem',
        },
        alertMessage: {
            fontFamily: 'Inter',
        },
        alertCloseButton: {
            paddingTop: theme.spacing(1),
        },
    }),
);

export const themeElevation = {
    elevationOne:
        '0px 1px 2px rgba(0, 0, 0, 0.07), 0px 1px 3px 1px rgba(0, 0, 0, 0.05)',
    elevationTwo:
        '0px 1px 2px rgba(0, 0, 0, 0.07), 0px 2px 6px 2px rgba(0, 0, 0, 0.05)',
    elevationThree:
        '0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.07)',
    elevationFour:
        '0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.07)',
    elevationFive:
        '0px 8px 12px 6px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.07)',
};

export const themeColors = {
    primaryLight01: '#2596b9',
    textLight: '#fff',
    primary: '#2d9bf0',
    secondary: '#637897',
    generalContrastText: '#fff',
    divider: '#f2f2f2',
    background: '#fffefe', // N03
    tableHeader: '#f5f4f4', // N05
    error: '#d53c3e',
    neutral01: '#FFFFFF',
    neutral07: '#767676',
    success: '#47C86B',
    pending: '#f9a825',
    black: '#000',
    gray93: '#f9f9f9',
};

export const hightlightCard = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 15,
            padding: theme.spacing(2.5),

            '&:hover': {
                backgroundColor: themeColors.gray93,
            },
        },
        nonStarredBorder: {
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        },
        starredBorder: {
            boxShadow: '0px 0px 20px rgba(249, 168, 36, 0.3)',
            marginBottom: theme.spacing(2),
        },
        bannerImage: {
            width: theme.spacing(37.5),
            height: theme.spacing(18.75),
            objectFit: 'cover',

            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(2),
            },
        },
        bannerDescription: {
            height: theme.spacing(18.75),
            overflow: 'hidden',
        },
        contentWrapper: {
            flexDirection: 'row',

            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        descriptionWrapper: {
            paddingTop: 2.2,
            width: '100%',

            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }),
);

const drawerWidth = 254;

export const shellStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        paper: {
            background: themeColors.background,
        },
        drawer: {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            border: 0,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            position: 'fixed',
            top: 0,
            right: 0,
        },
        content: {
            flexGrow: 1,
            boxSizing: 'border-box',
            maxWidth: '100vw',

            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(5, 8, 5, 8),
            },
        },
        companyLogoWrapper: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),

            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
            },
        },
        closeDrawerButton: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: 1000,
        },
        openDrawerButton: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: 1000,
        },
        companyLogo: {
            width: theme.spacing(18),

            [theme.breakpoints.down('sm')]: {
                width: theme.spacing(17),
            },
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),

            [theme.breakpoints.down('sm')]: {
                width: '100vw',
                height: '100vh',
                //remove the browser's url bar
                position: 'fixed',
                top: 0,
                zIndex: 10000,
            },
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
        },
    }),
);

export const dashboardCreateUserCard = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: themeColors.textLight,
            color: themeColors.textLight,
        },
    }),
);

export const useSchedulerStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltipHeaderImage: {
            width: '100%',
            height: 'auto',
            maxHeight: '15vh',
            objectFit: 'cover',
            zIndex: 1,
        },
        tooltipImageDegrade: {
            background:
                'linear-gradient(180deg, rgba(0,0,0,1) 33%, rgba(0,0,0,0.737154236694678) 65%, rgba(0,212,255,0) 100%);',
            width: '100vw',
            height: '15vh',
            position: 'absolute',
            zIndex: 1,
            opacity: 0.5,
        },
        tooltipHeaderGroupButton: {
            position: 'absolute',
            zIndex: 2,
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        tooltipContentScheduleStatus: {
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
        },
        clickableText: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
        schedulerAppointment: {
            width: '100%',
            height: '100%',
            paddingTop: '.5vh',
            paddingLeft: '.5vh',
        },
        dxItemInfoWrapper: {
            padding: '1vh 3vw 1vh 1vw',

            [theme.breakpoints.down('md')]: {
                paddingLeft: '2vw',
            },
        },
        marginInfoAndStatus: {
            marginLeft: '0.5vw',

            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing(2),
            },
        },
        calendarChipsWrapper: {
            maxWidth: '20vw',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'space-around',
            justifyContent: 'space-around',
            gap: '1vh',
        },
    }),
);

export const useLooseAppointmentStyles = makeStyles((theme: Theme) =>
    createStyles({
        fieldsWrapper: {
            width: '60%',
            marginTop: '3vh',

            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        chipsWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '60%',

            [theme.breakpoints.down('md')]: {
                width: '100%',
                justifyContent: 'space-evenly',
            },
        },
    }),
);

export default useCommonStyles;
