import { gql } from '@apollo/client';

export const GET_INSTALLATIONS = gql`
    query getInstallations($filter: InstallationFilters) {
        getInstallations(filter: $filter) {
            installations {
                _id
                name
                implementationDate
                user {
                    _id
                    fullname
                    email
                }
            }
            listInfo {
                skip
                limit
                term
            }
        }
    }
`;
