import React from 'react';
import clsx from 'clsx';
import { INPUT_TYPE } from '../types';
import ENV from '../config/environment';
import { createStyles, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { login } from '../store/reducers/user';
import { ControlledInput } from '../components';
import { useAppDispatch, useAppSelector } from '../store';
import { LOGIN_FORM_NAMES, REQUIRED_FIELD } from '../constants';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useCommonStyles, { useStructureStyles } from '../style/common';
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    Link,
    Snackbar,
    Theme,
    Typography,
} from '@mui/material';

import logo from '../assets/brand/GUIM-logo.png';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                {ENV.REACT_APP_BRAND}
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(8),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(3),
        },
        submit: {
            margin: theme.spacing(0, 0, 'auto'),
            width: '50%',
        },
        brand: {
            width: '50%',
            marginBottom: theme.spacing(3),
        },
        inputs: {
            marginBottom: theme.spacing(2),
            width: '100%',
        },
    }),
);

export default function Login() {
    const dispatch = useAppDispatch();

    const userState = useAppSelector((state) => state.user);
    const classes = useStyles();
    const { FLEX_ROW_CENTER } = useStructureStyles();
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm();
    const utilsClasses = useCommonStyles();

    const submit = (data: { email: string; password: string }) => {
        dispatch(login(data));
    };

    if (userState.access_token) return <Redirect to="/shell" />;

    return (
        <Container
            component="main"
            maxWidth="xs"
            className={utilsClasses.displayHeight}
        >
            <Grid
                className={clsx(utilsClasses.height100, FLEX_ROW_CENTER)}
                container
            >
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="Logo" className={classes.brand} />
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit(submit as any)}
                    >
                        <Grid item xs={12}>
                            <Grid item xs={12} className={FLEX_ROW_CENTER}>
                                <ControlledInput
                                    name={LOGIN_FORM_NAMES.EMAIL}
                                    label={t('GENERAL.EMAIL')}
                                    defaultValue=""
                                    control={control}
                                    className={classes.inputs}
                                    rules={{
                                        required: t(
                                            'GENERAL.REQUIRED_FIELD',
                                        ) as string,
                                        pattern: {
                                            value: ENV.REACT_APP_EMAIL_REGEX,
                                            message: t(
                                                'GENERAL.INVALID_EMAIL_ADDRESS',
                                            ) as string,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ControlledInput
                                    name={LOGIN_FORM_NAMES.PASSWORD}
                                    label={t('GENERAL.PASSWORD')}
                                    type={INPUT_TYPE.PASSWORD}
                                    defaultValue=""
                                    control={control}
                                    className={classes.inputs}
                                    rules={REQUIRED_FIELD}
                                />
                            </Grid>
                            <Grid item xs={12} className={FLEX_ROW_CENTER}>
                                <Button
                                    disableElevation
                                    size="large"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {userState.pending ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        t('GENERAL.LOGIN')
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box>
                    <Copyright />
                </Box>

                <Snackbar open={!!userState.error} autoHideDuration={6000}>
                    <Alert variant="filled" severity="warning">
                        {t(userState.error as string)}
                    </Alert>
                </Snackbar>
            </Grid>
        </Container>
    );
}
