import dayjs, { ManipulateType } from 'dayjs';
import { MaintenancePeriodicity, TechFile } from '../types';

interface IInstallationService {
    parseFilesToUpload: (
        files: TechFile[] | null,
    ) => { label: string; _id: string }[];
    updateFileLabel: (
        files: TechFile[],
        fileIndex: number,
        label: string,
    ) => TechFile[];
    removeFile: (files: TechFile[], fileIndex: number) => TechFile[];
    calculateNextDate: (
        date: string,
        periodicity: MaintenancePeriodicity,
    ) => string;
}

const InstallationService: IInstallationService = {
    parseFilesToUpload: (files) => {
        if (!files) return [];

        return files.map((file: TechFile) => {
            return {
                label: file?.label,
                _id: file?._id,
            };
        });
    },
    updateFileLabel: (files, fileIndex, label) => {
        const updateHelper = [...files];
        updateHelper[fileIndex].label = label;

        return updateHelper;
    },
    removeFile: (files, fileIndex) => {
        const updateHelper = [...files];
        updateHelper.splice(fileIndex, 1);

        return updateHelper;
    },
    calculateNextDate: (date, periodicity) => {
        let calcPeriodicity: [ManipulateType, number];

        switch (periodicity) {
            case MaintenancePeriodicity.Quarterly:
                calcPeriodicity = ['month', 3];
                break;
            case MaintenancePeriodicity.SemiAnnual:
                calcPeriodicity = ['month', 6];
                break;
            case MaintenancePeriodicity.Yearly:
                calcPeriodicity = ['year', 1];
                break;
            case MaintenancePeriodicity.BeWeekly:
                calcPeriodicity = ['week', 2];
                break;
            case MaintenancePeriodicity.Weekly:
                calcPeriodicity = ['week', 1];
                break;
            case MaintenancePeriodicity.Daily:
                calcPeriodicity = ['day', 1];
                break;
            default:
                calcPeriodicity = ['month', 1];
        }

        return dayjs(date)
            .add(
                calcPeriodicity[1],
                calcPeriodicity[0] as ManipulateType | undefined,
            )
            .toISOString();
    },
};

export { InstallationService };
