import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { SharedDataGrid } from '../../components';
import useCommonStyles from '../../style/common';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    OutlinedInput,
    Switch,
} from '@mui/material';
import { SharedHeader } from '../../components/shared-components/shared-header';
import {
    AlertModalSeverity,
    DIALOG_STATE,
    DataGridConfig,
    IUserGrid,
    UserDataGridFields,
} from '../../types';
import { GET_USERS } from '../../graphql/queries/q-get-users';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce, useWindowSize } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { DELETE_USERS } from '../../graphql/mutations/m-delete-users';
import { AlertModalContext } from '../../context/alertModal-context';
import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import {
    Routes,
    dataGridDefaults,
    displayConditionaly,
    nPages,
} from '../../core/utils';
import { SharedPagination } from '../../components/shared-components/pagination';
import { SharedLoading } from '../../components/shared-components/loading';
import dayjs from 'dayjs';
import clsx from 'clsx';

const Users = () => {
    const history = useHistory();
    const { showAlertModal } = useContext(AlertModalContext);
    const { t } = useTranslation();
    const {
        sharedDataGrid,
        spacingBottomLarge,
        spacingBottomSmall3,
        usersNavBar,
        usersFilters,
    } = useCommonStyles();
    const [isHidden, setIsHidden] = useState(true);
    const [deleteDialogState, setDeleteDialogState] = useState(false);
    const [deleteUsers] = useMutation(DELETE_USERS);
    const [term, setTerm] = useState<string>('');
    const [filters, setFilters] = useState<{
        confirmed: boolean;
        onlyWithInstallations: boolean;
        withAppointments: boolean;
    }>({
        confirmed: false,
        onlyWithInstallations: false,
        withAppointments: false,
    });
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [dataGridRows, setDataGridRows] = useState<IUserGrid[]>([]);
    const [dataGridConfig, setDataGridConfig] = useState<DataGridConfig>({
        currentPage: 1,
        totalPages: 0,
        rowsPerPage: 10,
    });

    const { isOnMobile } = useWindowSize();

    const columns: GridColDef[] = [
        {
            field: UserDataGridFields.USER,
            headerName: t('USERS.USER'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        justifyContent="center"
                    >
                        <span>{params?.row?.fullname}</span>
                        {params?.row?.email}
                    </Box>
                );
            },
        },
        {
            field: UserDataGridFields.NEXTAPPOINTMENT,
            headerName: t('USERS.NEXT_MAINTENANCE'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                const date = params?.row?.nextSchedule;
                return date ? dayjs(date).format('DD/MM/YYYY') : 'N/A';
            },
        },
        {
            field: UserDataGridFields.CONFIRMED,
            headerName: t('USERS.CONFIRMED'),
            ...dataGridDefaults(),
            renderCell: (params) =>
                t(params?.row?.confirmed ? 'GENERAL.YES' : 'GENERAL.NO'),
        },
        {
            field: UserDataGridFields.ACTIONS,
            headerName: '',
            ...dataGridDefaults(),
            align: 'right',
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={(event: any) => {
                        event.ignore = true;
                        removeUserHandler([params?.row?._id]);
                    }}
                >
                    {t('GENERAL.DELETE')}
                </Button>
            ),
        },
    ];
    const { refetch, loading } = useQuery(GET_USERS, {
        variables: {
            input: {
                term,
                step: dataGridConfig.currentPage - 1,
                limit: dataGridConfig?.rowsPerPage,
            },
            filter: {
                confirmed: filters.confirmed,
                onlyWithInstallations: filters.onlyWithInstallations,
                withAppointments: filters.withAppointments,
            },
        },
        onCompleted: (data) => {
            setDataGridConfig({
                currentPage: dataGridConfig.currentPage,
                rowsPerPage: 10,
                totalPages: nPages(
                    data?.getAllUsers?.listInfo?.total,
                    dataGridConfig.rowsPerPage,
                ),
            });
            setDataGridRows(data?.getAllUsers.users ?? []);
        },
    });

    const removeUserHandler = (id: string[]) => {
        setDeleteDialogState(true);
        setSelectedIds(id);
    };

    const onDeleteDialogCloses = (state: DIALOG_STATE) => {
        setDeleteDialogState(false);
        if (state === DIALOG_STATE.CONFIRMED)
            deleteUsers({
                variables: {
                    id: selectedIds,
                },
            }).then(() => {
                showAlertModal(
                    t('ALERTS.DELETED_SUCCESSFULLY'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                refetch();
            });
    };

    const [debounce] = useDebounce();

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setDataGridConfig({
            ...dataGridConfig,
            currentPage: page,
        });
    };

    return (
        <>
            <SharedHeader
                title={{
                    content: t('GENERAL.USERS_LIST'),
                    variant: 'h4',
                }}
                className={spacingBottomLarge}
            >
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push(`${Routes.USERS}/new`)}
                >
                    {t('USERS.CREATE_USER')}
                </Button>
            </SharedHeader>
            <Grid container spacing={2} className={usersNavBar} display="flex">
                <Grid
                    item
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={6}
                    className={clsx(spacingBottomSmall3, usersFilters)}
                >
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={(e) => {
                                            setFilters({
                                                ...filters,
                                                confirmed: e.target.checked,
                                            });
                                        }}
                                    />
                                }
                                label={t('GENERAL.REGISTERED')}
                                labelPlacement={isOnMobile ? 'end' : 'start'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={(e) => {
                                            setFilters({
                                                ...filters,
                                                onlyWithInstallations:
                                                    e.target.checked,
                                            });
                                        }}
                                    />
                                }
                                label={t('USERS.INSTALLATIONS')}
                                labelPlacement={isOnMobile ? 'end' : 'start'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={(e) => {
                                            setFilters({
                                                ...filters,
                                                withAppointments:
                                                    e.target.checked,
                                            });
                                        }}
                                    />
                                }
                                label={t('USERS.AGENDED_LABEL')}
                                labelPlacement={isOnMobile ? 'end' : 'start'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid
                    item
                    display="flex"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    className={clsx(spacingBottomSmall3)}
                    xs={12}
                    md={6}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <OutlinedInput
                            sx={{ marginLeft: '1vw' }}
                            fullWidth
                            onChange={(e) => {
                                debounce(undefined, e.target.value, setTerm);
                            }}
                            placeholder={t('GENERAL.SEARCH_BY_NAME')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    {!isHidden && (
                        <Grid
                            item
                            xs={12}
                            md={3}
                            display="flex"
                            justifyContent="flex-start"
                            sx={{ marginLeft: '1vw' }}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={() => removeUserHandler(selectedIds)}
                                sx={{ marginTop: isOnMobile ? '1vh' : 0 }}
                            >
                                {t('USERS.DELETE_SELECTED')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {displayConditionaly(
                loading,
                <SharedLoading />,
                <React.Fragment>
                    <SharedDataGrid
                        checkboxSelection={true}
                        className={sharedDataGrid}
                        rows={dataGridRows}
                        columns={columns}
                        configuration={{
                            hideFooter: true,
                            autoHeight: true,
                        }}
                        disableSelectionOnClick={true}
                        onSelectionModelChange={(ids: string[]) => {
                            setSelectedIds(ids);
                            setIsHidden(!ids.length);
                        }}
                        onRowClick={(params, event: any) => {
                            if (!event.ignore) {
                                history.push(
                                    `${Routes.USERS}/${params?.row?._id}`,
                                );
                            }
                        }}
                    />
                    <SharedPagination
                        count={dataGridConfig?.totalPages}
                        onChange={handleChangePage}
                        page={dataGridConfig?.currentPage}
                    />
                </React.Fragment>,
            )}
            <ConfirmationDialog
                isOpen={deleteDialogState}
                close={onDeleteDialogCloses}
                title={t('USERS.DELETE_USER_TITLE')}
                message={t('USERS.DELETE_USER_MESSAGE')}
            />
        </>
    );
};

export { Users };
