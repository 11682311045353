import { gql } from '@apollo/client';

export const FIND_ANALYTICS = gql`
    query findAnalytics($analytics: [CalculateAnalytic]!) {
        findAnalytics(analytics: $analytics) {
            name
            value
            count
        }
    }
`;
