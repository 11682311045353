import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Box, Grid, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { Routes, createDraftContentFromRawJSON } from '../../core/utils';

import useCommonStyles, {
    hightlightCard,
    themeColors,
    useStructureStyles,
} from '../../style/common';

const HighlightCard = (props: {
    id: string;
    image: string;
    title: string;
    descriptionHtml: string;
    isStarred: boolean;
    chooseFav: (highlightId: string) => void;
    borderClass: string;
}) => {
    const { cursorPointer } = useCommonStyles();
    const useStructureStylesClasses = useStructureStyles();

    const highlightCardClasses = hightlightCard();

    const history = useHistory();

    const goToHighlightDetail = () => {
        history.push(`${Routes.HIGHLIGHTS}/${props.id}`);
    };

    const description = createDraftContentFromRawJSON(props.descriptionHtml);

    return (
        <Box
            className={clsx(
                highlightCardClasses.root,
                cursorPointer,
                props.borderClass,
            )}
            sx={{ mt: props.isStarred ? 0 : 4 }}
        >
            <Grid item xs={12}>
                <Box
                    display="flex"
                    alignItems="center"
                    className={highlightCardClasses.contentWrapper}
                >
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={useStructureStylesClasses.FLEX}
                        onClick={goToHighlightDetail}
                    >
                        <img
                            src={props.image}
                            alt=""
                            className={highlightCardClasses.bannerImage}
                        />
                    </Grid>
                    <Grid item xs={9} sx={{ ml: 3, width: 1 }}>
                        <Box
                            display="flex"
                            className={highlightCardClasses.bannerDescription}
                        >
                            <Box
                                sx={{ width: 1 }}
                                onClick={goToHighlightDetail}
                            >
                                <Typography variant="h5" fontWeight="bold">
                                    {props.title}
                                </Typography>

                                <Box
                                    className={
                                        highlightCardClasses.descriptionWrapper
                                    }
                                >
                                    <Typography variant="body1">
                                        {description
                                            .getCurrentContent()
                                            .getPlainText()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <StarIcon
                                    sx={{
                                        fill: props.isStarred
                                            ? themeColors.pending
                                            : themeColors.black,
                                    }}
                                    onClick={() => props.chooseFav(props.id)}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export { HighlightCard };
