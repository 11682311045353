import { env } from 'process';

const ENV = {
    REACT_APP_BRAND: process.env.REACT_APP_BRAND || 'InflightIT',
    REACT_APP_BRAND_WEBSITE:
        process.env.REACT_APP_BRAND_WEBSITE || '"https://inflightit.com/"',
    REACT_APP_AUTH_ENDPOINT:
        process.env.REACT_APP_AUTH_ENDPOINT || 'http://3.13.130.149:8001',
    REACT_APP_GRAPHQL_ENDPOINT:
        process.env.REACT_APP_GRAPHQL_ENDPOINT ||
        'http://3.13.130.149:8001/graphql',
    REACT_APP_EMAIL_REGEX:
        process.env.REACT_APP_EMAIL_REGEX ||
        process.env.NODE_ENV === 'development'
            ? new RegExp(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i)
            : new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i),
    REACT_APP_LANG: process.env.REACT_APP_LANG || 'en',
    REACT_APP_TOKEN_KEY:
        process.env.REACT_APP_TOKEN_KEY || 'asaf87uihkkdsf98df',
    REACT_APP_DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT || 'DD/MM/YYYY',
    REACT_APP_COUNTRY_CODE: process.env.REACT_APP_COUNTRY_CODE || 'PT',
};

export const PATHS = {
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
};

export default ENV;
