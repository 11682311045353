import { Box, Chip } from '@mui/material';
import React, { FC } from 'react';
import { CalendarChipAction } from '../../types';
import { blockedDatesStyles } from './BlockedDates.styles';
import { PropTypes } from './BlockedDates.types';
import dayjs from 'dayjs';

const BlockedDates: FC<PropTypes> = ({ dates, onDateRemove }) => {
    const { chipsWrapper } = blockedDatesStyles();
    return (
        <Box className={chipsWrapper}>
            {dates &&
                dates?.map(({ action, date }, index: number) => {
                    if (action === CalendarChipAction.REMOVE) return null;

                    return (
                        <Chip
                            key={index}
                            label={dayjs(date).format('DD-MM-YYYY')}
                            onDelete={() => onDateRemove(date)}
                        />
                    );
                })}
        </Box>
    );
};

export { BlockedDates };
