import React, { FC, memo } from 'react';

import { Grid, Pagination } from '@mui/material';

interface ISharedPaginationProps {
    count: number;
    onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    page?: number;
}

const SharedPagination: FC<ISharedPaginationProps> = memo(
    ({ count, onChange, page }: ISharedPaginationProps) => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4 }}
            >
                <Pagination
                    count={count}
                    shape="rounded"
                    variant="outlined"
                    color="primary"
                    onChange={onChange}
                    page={page}
                />
            </Grid>
        );
    },
);

export { SharedPagination };
