import { Grid, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface ISpacerProps {
    space: number;
}

const Spacer: FC<ISpacerProps> = ({ space }) => {
    const theme = useTheme();

    return <Grid sx={{ margin: `${theme.spacing(space)} 0` }}></Grid>;
};

export { Spacer };
