import { gql } from '@apollo/client';

export const CREATE_LOOSE_SCHEDULE = gql`
    mutation createLooseSchedule(
        $installationId: ID!
        $input: LooseScheduleInput
    ) {
        createLooseSchedule(installationId: $installationId, input: $input) {
            _id
        }
    }
`;
