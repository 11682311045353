import { gql } from '@apollo/client';

export const UPDATE_HIGHLIGHT_BY_ID = gql`
    mutation updateHighlightById(
        $highlightId: ID!
        $input: UpdateHighlightInput!
    ) {
        updateHighlightById(highlightId: $highlightId, input: $input) {
            _id
        }
    }
`;
