import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { SchedulerTooltipHeader } from './scheduler-tooltip-header';
import { SchedulerTooltipContent } from './scheduler-tooltip-content';
import { SchedulerDateTime, ViewState } from '@devexpress/dx-react-scheduler';
import {
    AppointmentTooltip,
    Appointments,
    DateNavigator,
    DayView,
    MonthView,
    Scheduler as MuiSchedular,
    Toolbar,
    ViewSwitcher,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { SchedulerAppointment } from './scheduler-appointment-content';
import { ISchedulerAppointment, MAINTENANCE_STATUS } from '../../../types';

enum ViewNames {
    DAY = 'Day',
    WEEK = 'Week',
}

interface IDxSchedulerProps {
    data?: ISchedulerAppointment[];
    currentDate?: SchedulerDateTime;
    setCurrentDate: (date: SchedulerDateTime) => void;
    color?: string;
}

export interface ISchedulerAppointmentContent {
    data: {
        endHour: string;
        startHour: string;
        title: string;
        status: MAINTENANCE_STATUS;
    };
}

const DxScheduler: FC<IDxSchedulerProps> = ({
    data,
    currentDate,
    setCurrentDate,
}) => {
    const changeDate = (date: SchedulerDateTime) => {
        setCurrentDate(date ? date : new Date());
    };

    return (
        <Paper>
            {data && (
                <MuiSchedular
                    data={data}
                    locale="pt-PT"
                    height="auto"
                    firstDayOfWeek={0}
                >
                    <Toolbar />
                    <ViewState
                        currentDate={currentDate}
                        onCurrentDateChange={(date) => changeDate(date)}
                    />
                    <DateNavigator />
                    <ViewSwitcher />
                    <WeekView
                        name={ViewNames.WEEK}
                        startDayHour={9}
                        endDayHour={19}
                    />
                    <DayView
                        name={ViewNames.DAY}
                        startDayHour={9}
                        endDayHour={19}
                    />
                    <MonthView />
                    <Appointments
                        appointmentContentComponent={(data: any) =>
                            SchedulerAppointment(data)
                        }
                    />
                    <AppointmentTooltip
                        showCloseButton
                        headerComponent={SchedulerTooltipHeader}
                        contentComponent={SchedulerTooltipContent}
                    />
                </MuiSchedular>
            )}
        </Paper>
    );
};

export { DxScheduler };
