import React, { FC } from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { ISelectItem } from '../../../types';
import { useTranslation } from 'react-i18next';
import useCommonStyles, { themeColors } from '../../../style/common';

interface IControlledSelectProps {
    name: string;
    control: Control<any>;
    items: ISelectItem[];
    label?: string;
    rules?: { [key: string]: string };
    defaultValue?: any;
    className?: string;
    style?: React.CSSProperties;
    translate?: boolean;
    disabled?: boolean;
    cOnChange?: any;
    cValue?: any;
}

const ControlledSelect: FC<IControlledSelectProps> = ({
    name,
    control,
    items,
    rules,
    label,
    defaultValue,
    className,
    translate,
    disabled,
    cOnChange,
    cValue,
}: IControlledSelectProps) => {
    const { t } = useTranslation();
    const { width100 } = useCommonStyles();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ?? ''}
                rules={rules && rules}
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => {
                    return (
                        <>
                            <FormControl className={width100}>
                                <InputLabel id={name}>{label}</InputLabel>
                                <Select
                                    defaultValue={cValue ? cValue : value}
                                    error={!!error}
                                    name={name}
                                    label={label}
                                    onChange={(e) => {
                                        onChange(e);
                                        // eslint-disable-next-line no-unused-expressions
                                        cOnChange && cOnChange(e);
                                    }}
                                    value={cValue ? cValue : value}
                                    className={className}
                                    id={name}
                                    disabled={disabled}
                                >
                                    {items.map(
                                        (item: ISelectItem, index: number) => {
                                            return (
                                                <MenuItem
                                                    value={item?.value}
                                                    key={index}
                                                >
                                                    {translate
                                                        ? t(
                                                            `MAINTENANCE.TIME.${item?.value}`,
                                                        )
                                                        : item?.label}
                                                </MenuItem>
                                            );
                                        },
                                    )}
                                </Select>
                                {error && error?.message && (
                                    <FormHelperText
                                        sx={{ color: themeColors.error }}
                                    >
                                        {t(error?.message) as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </>
                    );
                }}
            />
        </>
    );
};

export { ControlledSelect };
