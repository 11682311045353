import React, { FC, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridSortModel,
} from '@mui/x-data-grid';
import {
    DataGridPropsWithDefaultValues,
    SharedDataGridRows,
} from '../../types';

interface ISharedDataGridProps {
    rows: SharedDataGridRows;
    columns: GridColDef[];
    pageSize?: number;
    configuration?: DataGridPropsWithDefaultValues;
    style?: React.CSSProperties;
    className?: string;
    checkboxSelection?: boolean;
    disableSelectionOnClick?: boolean;
    onSelectionModelChange?: any;
    onRowClick?: GridEventListener<'rowClick'>;
    sortModel?: GridSortModel;
    generateOwnIds?: boolean;
}

const SharedDataGrid: FC<ISharedDataGridProps> = memo(
    ({
        rows,
        style,
        columns,
        pageSize,
        sortModel,
        className,
        onRowClick,
        configuration,
        generateOwnIds,
        checkboxSelection,
        onSelectionModelChange,
        disableSelectionOnClick,
    }: ISharedDataGridProps) => {
        return (
            <DataGrid
                getRowId={(row) => (generateOwnIds ? uuidv4() : row._id)}
                onRowClick={onRowClick}
                rows={rows}
                columns={columns}
                sx={style}
                {...configuration}
                pageSize={pageSize ? pageSize : 10}
                className={className}
                checkboxSelection={checkboxSelection}
                disableSelectionOnClick={disableSelectionOnClick}
                onSelectionModelChange={onSelectionModelChange}
                sortModel={[
                    {
                        field: 'status',
                        sort: 'desc',
                    },
                ]}
            />
        );
    },
);

export { SharedDataGrid };
