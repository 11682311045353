import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSchedulerStyles } from '../../../style/common';
import { schedulerAppointmentColor } from '../../../core/utils';
import { ISchedulerAppointmentContent } from './dx-scheduler';

const SchedulerAppointment: FC<ISchedulerAppointmentContent> = ({ data }) => {
    const styles = useSchedulerStyles();
    const cardColor = schedulerAppointmentColor(data.status);

    return (
        <Grid
            className={styles.schedulerAppointment}
            sx={{
                backgroundColor: cardColor,
            }}
        >
            <Typography variant="body2" fontWeight="bold" color="white">
                {data.title}
            </Typography>
            <Typography variant="body2" color="white">
                {data.startHour} - {data.endHour}
            </Typography>
        </Grid>
    );
};

export { SchedulerAppointment };
