import { gql } from '@apollo/client';

export const UPDATE_SETTINGS = gql`
    mutation updateSettings($input: UpdateSettingInput!) {
        updateSettings(input: $input) {
            maxInterventionsAtTime
            minDaysLeftToCancelSchedules
        }
    }
`;
