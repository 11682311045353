import { gql } from '@apollo/client';

export const GET_DAYS_WITH_SCHEDULES = gql`
    query findDaysWithSchedules($filter: ScheduleDateFilter) {
        findDaysWithSchedules(filter: $filter) {
            day
            schedules {
                schedule {
                    _id
                    scheduledDate
                    scheduleEndDate
                    installationId
                    image
                    status
                    maintenance {
                        _id
                        name
                    }
                }
            }
        }
    }
`;
