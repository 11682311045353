import React, {
    Fragment,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { SharedHeader } from '../../components/shared-components/shared-header';
import { AssociateMaintenance } from '../maintenances/associate-maintenance';
import {
    INSTALLATIONS_FORM_NAMES,
    INSTALLATIONS_VIDEOS_FORM_NAMES,
    REQUIRED_FIELD,
    UNIQUE_MAINTENANCES_NAME,
} from '../../constants';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AlertModalContext } from '../../context/alertModal-context';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
    Routes,
    dataGridDefaults,
    displayConditionaly,
    fileHandler,
    getFullDate,
    getTimeFromDate,
    nPages,
    parseDateToYearMonth,
    schedulerAppointmentColor,
    uploadFileToServer,
} from '../../core/utils';
import useCommonStyles, { themeColors } from '../../style/common';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import List from '@mui/material/List';
import { IMaintenanceActionsForm } from '../maintenances';
import { useHistory, useParams } from 'react-router-dom';
import { InstallationService } from '../../services';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import {
    AlertModalSeverity,
    IPromptActions,
    IScheduledDataGrid,
    InstallationsVideos,
    MAINTENANCE_STATUS,
    MaintenanceColumns,
    MaintenancePeriodicity,
    ScheduleDataGrid,
    TechFile,
    UploadFileType,
    UploadImages,
} from '../../types';
import {
    ControlledInput,
    ImagePlaceholder,
    Modal,
    SharedDataGrid,
    Spacer,
    TechnicalFiles,
    UploadButton,
} from '../../components';
import {
    GET_INSTALLATION_BY_ID,
    GET_MAINTENANCES,
    GET_MAINTENANCES_BY_INSTALLATION_ID,
    GET_SCHEDULES_BY_INSTALLATION_ID,
    REMOVE_MAINTENANCE_FROM_INSTALLATION,
    UPDATE_INSTALLATION,
    UPDATE_SCHEDULE_BY_ID,
} from '../../graphql';

import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ConfirmationDialog from '../../components/shared-components/confirm-dialog';
import { SharedLoading } from '../../components/shared-components/loading';
import { SharedPagination } from '../../components/shared-components/pagination';
import { LooseAppointments } from '../../components/loose-appointments/LooseAppointments';
import { UserInfoModal } from '../../components/user-info-modal/UserInfoModal';
import InfoIcon from '@mui/icons-material/Info';
import { Reports } from '../../components/reports/Reports';

enum SCHEDULES_ACTIONS_CONTENT {
    CONFIRM = 'CONFIRM',
    SEND_REPORT = 'SEND_REPORT',
    VIEW_REPORT = 'VIEW_REPORT',
}

enum MODAL_TYPES {
    ASSOCIATE_MAINTENANCES = 'ASSOCIATE_MAINTENANCES',
    CANCEL_SCHEDULE = 'CANCEL_SCHEDULE',
    USER_INFO = 'USER_INFO',
    LOOSE_APPOINTMENTS = 'LOOSE_APPOINT',
}

interface FilteredInput {
    name: string;
    installationDate?: string;
    implementationDate: string;
}

const DATA_GRID_LIMIT = 5;

const InstallationDetails = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { id: installationId } = useParams<{ id: string }>();
    const { handleSubmit, control, reset } = useForm<IMaintenanceActionsForm>();
    const {
        handleSubmit: handleSubmitVideos,
        control: controlVideos,
        reset: resetVideos,
    } = useForm();
    const { showAlertModal } = useContext(AlertModalContext);

    const [renderModalType, setRenderModalType] = useState<MODAL_TYPES>(
        MODAL_TYPES.ASSOCIATE_MAINTENANCES,
    );
    const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [installationImage, setInstallationImage] = useState<UploadImages>();
    const [installationVideos, setInstallationVideos] = useState<
        InstallationsVideos[]
    >([]);
    const [files, setFiles] = useState<TechFile[]>([]);
    const [currentSelectedScheduleId, setCurrentSelectedScheduleId] =
        useState<string>('');
    const [selectedMaintenances, setSelectedMaintenances] = useState<string[]>(
        [],
    );
    const [deletedMaintenances, setDeletedMaintenances] = useState<string[]>(
        [],
    );
    const [loadingTechFile, setLoadingTechFile] = useState<boolean>(false);

    const [schedulesDataGridRows, setSchedulesDataGridRows] =
        useState<IScheduledDataGrid[]>();

    const [promptActions, setPromptActions] = useState<IPromptActions>({
        isVisible: false,
        title: '',
        message: '',
        onClose: () => undefined,
        onConfirm: () => undefined,
    });
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [reportsModalState, setReportsModalState] = useState<{
        isVisible: boolean;
        scheduleId?: string;
        reports?: string[];
    }>({
        isVisible: false,
        scheduleId: undefined,
    });
    const [datePickerValues, setDatePickerValues] = useState<Dayjs | string>(
        dayjs(new Date()),
    );

    const [cancelReason, setCancelReason] = useState<string>();

    const [removeMaintenanceFromInstallation] = useMutation(
        REMOVE_MAINTENANCE_FROM_INSTALLATION,
    );

    const [updateInstallation] = useMutation(UPDATE_INSTALLATION);
    const [updateScheduleById] = useMutation(UPDATE_SCHEDULE_BY_ID);
    const [getMaintenanceList, { data: maintenancesList }] =
        useLazyQuery(GET_MAINTENANCES);

    const [schedulesPagination, setSchedulesPagination] = useState(1);
    const { data: schedulesData, refetch: refetchSchedulesData } = useQuery(
        GET_SCHEDULES_BY_INSTALLATION_ID,
        {
            variables: {
                installationId,
                filter: {
                    step: schedulesPagination - 1,
                    term: '',
                    limit: DATA_GRID_LIMIT,
                },
            },
            onCompleted: (data) => {
                const scheduleData = data?.findSchedulesByInstallationId;
                const items = scheduleData?.schedules?.map((item: any) => {
                    return {
                        _id: item._id,
                        scheduleTime: getTimeFromDate(+item.scheduledDate),
                        scheduleDate: getFullDate(+item.scheduledDate),
                        name: item?.maintenance?.name,
                        status: item?.status,
                        report: item?.report,
                        reportUploader: item?.reportUploader,
                    };
                });
                setSchedulesDataGridRows(items);
            },
        },
    );

    const refetchSchedules = async () => {
        const { data } = await refetchSchedulesData();

        const findReportById =
            data?.findSchedulesByInstallationId?.schedules?.find(
                (_schedule) => _schedule._id === reportsModalState.scheduleId,
            );

        setReportsModalState((prev) => ({
            ...prev,
            reports: findReportById?.report,
        }));
    };

    const [isAbleToCreateLooseSchedules, setIsAbleToCreateLooseSchedule] =
        useState<boolean>(false);

    const {
        data: installationData,
        refetch,
        loading: loadingInstallations,
    } = useQuery(GET_INSTALLATION_BY_ID, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId,
        },
        onCompleted: (data) => {
            setInstallationVideos(data?.getInstallationById?.videos || []);
            setFiles(
                data?.getInstallationById?.files.map((file: TechFile) => ({
                    uri: file?.uri,
                    label: file?.label,
                    _id: file?._id,
                })),
            );
            reset(data?.getInstallationById);
            setInstallationImage({
                avatar: data?.getInstallationById?.image?.uri,
                _id: data?.getInstallationById?.image?._id,
                file: undefined,
            });
            setDatePickerValues(
                dayjs(
                    new Date(data?.getInstallationById?.implementationDate),
                ).format('YYYY-MM-DD'),
            );
        },
        onError: () => history.goBack(),
    });

    const {
        data: maintenancesData,
        refetch: refetchAssociatedMaintenances,
        loading: loadingMaintenances,
    } = useQuery(GET_MAINTENANCES_BY_INSTALLATION_ID, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId,
        },
        onCompleted: (data) => {
            const isAbleToSchedule =
                !!data.findMaintenancesByInstallationId.find(
                    (item: { periodicity: string }) =>
                        item.periodicity.toString() ===
                        MaintenancePeriodicity.UNIQUE,
                );

            setIsAbleToCreateLooseSchedule(isAbleToSchedule);
        },
    });

    const {
        sharedDataGrid,
        spacingTopSmall3,
        spacingBottomLarge,
        installationInfoWrapper,
        installationNameWrapper,
        installationFormWrapper,
        icon,
        spacingRightSmall2,
    } = useCommonStyles();

    const schedulesColumns: GridColDef[] = [
        {
            field: ScheduleDataGrid.NAME,
            headerName: t('GENERAL.NAME'),
            ...dataGridDefaults(),
            width: 200,
            minWidth: 100,
        },
        {
            field: ScheduleDataGrid.SCHEDULE_DATE,
            headerName: t('MAINTENANCE.SCHEDULE_DATE'),
            ...dataGridDefaults(),
            width: 170,
            minWidth: 100,
        },
        {
            field: ScheduleDataGrid.SCHEDULE_TIME,
            headerName: t('MAINTENANCE.SCHEDULE_TIME'),
            ...dataGridDefaults(),
            width: 170,
            minWidth: 100,
        },
        {
            field: MaintenanceColumns.STATUS,
            headerName: t('GENERAL.STATUS'),
            ...dataGridDefaults(),
            width: 160,
            minWidth: 100,
            renderCell(params) {
                const isStatusDone =
                    MAINTENANCE_STATUS.DONE === params.row.status;
                return (
                    <>
                        <Typography
                            color={schedulerAppointmentColor(params.row.status)}
                        >
                            {isStatusDone
                                ? t('MAINTENANCE.STATUS.DONE')
                                : t(`MAINTENANCE.STATUS.${params.row.status}`)}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: ScheduleDataGrid.ACTIONS,
            headerName: '',
            ...dataGridDefaults(),
            width: 300,
            align: 'right',
            flex: 0,
            renderCell: (params) => {
                let renderType: SCHEDULES_ACTIONS_CONTENT =
                    SCHEDULES_ACTIONS_CONTENT.CONFIRM;

                if (params.row.status === MAINTENANCE_STATUS.SCHEDULED) {
                    renderType = SCHEDULES_ACTIONS_CONTENT.CONFIRM;
                }

                if (
                    params.row.status === MAINTENANCE_STATUS.DONE &&
                    !params.row?.report
                ) {
                    renderType = SCHEDULES_ACTIONS_CONTENT.SEND_REPORT;
                }

                if (
                    params.row.status === MAINTENANCE_STATUS.DONE &&
                    params.row?.report
                ) {
                    renderType = SCHEDULES_ACTIONS_CONTENT.VIEW_REPORT;
                }

                return (
                    <>
                        {params.row.status !== MAINTENANCE_STATUS.CANCELLED &&
                            renderSchedulesDataGridActions(
                                params.row._id,
                                params.row?.report,
                                params?.row?.reportUploader,
                            )[renderType]}
                    </>
                );
            },
        },
    ];

    const associatedMaintenanceColumns: GridColDef[] = [
        {
            field: MaintenanceColumns.NAME,
            headerName: t('GENERAL.NAME'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return (
                    <Typography>
                        {params?.row?.name === UNIQUE_MAINTENANCES_NAME
                            ? t('MAINTENANCE.UNIQUE_MAINTENANCES_NAME')
                            : params?.row?.name}
                    </Typography>
                );
            },
        },
        {
            field: MaintenanceColumns.PERIODICITY,
            headerName: t('MAINTENANCE.PERIODICITY'),
            ...dataGridDefaults(),
            renderCell: (params) => {
                return (
                    <Typography>
                        {t(`MAINTENANCE.TIME.${params.row.periodicity}`)}
                    </Typography>
                );
            },
        },
        {
            field: MaintenanceColumns.NEXT_MAINTENANCE,
            headerName: t('GENERAL.NEXT'),
            ...dataGridDefaults(),
            width: 180,
            minWidth: 180,
            renderCell: (params) => {
                return (
                    <>
                        <Typography>
                            {parseDateToYearMonth(
                                params.row.nextMaintenanceDate,
                            )}
                        </Typography>
                    </>
                );
            },
        },
        {
            field: MaintenanceColumns.STATUS,
            headerName: t('GENERAL.STATUS'),
            ...dataGridDefaults(),
            renderCell(params) {
                return (
                    <>
                        {MAINTENANCE_STATUS.DONE === params.row.status && (
                            <Typography color={themeColors.success}>
                                {t('MAINTENANCE.STATUS.DONE')}
                            </Typography>
                        )}
                        {MAINTENANCE_STATUS.TO_SCHEDULE ===
                            params.row.status && (
                            <Typography color={themeColors.pending}>
                                {t('MAINTENANCE.STATUS.TO_SCHEDULE')}
                            </Typography>
                        )}
                        {MAINTENANCE_STATUS.SCHEDULED === params.row.status && (
                            <Typography color={themeColors.primary}>
                                {t('MAINTENANCE.STATUS.SCHEDULED')}
                            </Typography>
                        )}
                    </>
                );
            },
        },
        {
            field: MaintenanceColumns.ACTIONS,
            headerName: '',
            ...dataGridDefaults(),
            align: 'right',
            renderCell: (params) => {
                return (
                    <Button
                        size="large"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                            deleteMaintenanceAssociation(
                                params.row._id,
                                installationId,
                            )
                        }
                    >
                        {t('GENERAL.REMOVE')}
                    </Button>
                );
            },
        },
    ];

    const headerTitle = !installationData?.getInstallationById.name
        ? ''
        : ` - ${installationData?.getInstallationById.name}`;

    const uploadFilesHandler = (
        id: string,
        uploadFileType?: UploadFileType,
    ) => {
        if (uploadFileType !== UploadFileType.VIDEO) {
            updateInstallation({
                variables: {
                    installationId,
                    input: {
                        ...(uploadFileType === UploadFileType.IMAGE && {
                            image: id,
                        }),
                        ...(uploadFileType ===
                            UploadFileType.TECHNICAL_FILE && {
                            files: [
                                {
                                    label: t(
                                        'INSTALLATIONS.TECHNICAL_FILE_LABEL',
                                    ),
                                    _id: id,
                                },
                            ],
                        }),
                    },
                },
            });
        }
    };

    const removeFileHandler = (fileIndex: number) => {
        setPromptActions({
            isVisible: true,
            title: t('PROMPTS.REMOVE_TECHNICAL_FILE_TITLE'),
            message: t('PROMPTS.REMOVE_TECHNICAL_FILE_MESSAGE'),
            onClose: onDialogPromptClose,
            onConfirm: () => {
                setFiles(InstallationService.removeFile(files, fileIndex));
                const filteredInput = {
                    name: installationData.getInstallationById.name,
                    implementationDate: dayjs(datePickerValues).toISOString(),
                };
                setFiles((files: TechFile[]) => {
                    updateInstallationRequest(filteredInput, files, true);
                    return files;
                });
            },
        });
    };

    const updateFileLabelHandler = (fileIndex: number, label: string) => {
        setFiles(InstallationService.updateFileLabel(files, fileIndex, label));
    };

    const handleDatePickerChange = (date: Dayjs | null) => {
        setDatePickerValues(dayjs(date).format('YYYY-MM-DD'));
    };

    const updateInstallationRequest = async (
        filteredInput: FilteredInput,
        filesUpdated?: TechFile[],
        isRemoveFile?: boolean,
    ) => {
        let imageUploadHelper;

        const techFiles = filesUpdated ? filesUpdated : files;

        setIsLoading(true);

        if (installationImage && installationImage.file) {
            imageUploadHelper = await uploadFileToServer(
                installationImage.file,
            );
        }

        const image = imageUploadHelper?.data?._id || installationImage?._id;

        const videos = installationVideos.map((video) => ({
            title: video.title,
            url: video.url,
        }));

        updateInstallation({
            variables: {
                installationId,
                input: {
                    ...filteredInput,
                    files: InstallationService.parseFilesToUpload(techFiles),
                    image: image ? image : undefined,
                    maintenances: selectedMaintenances,
                    implementationDate: dayjs(datePickerValues).toISOString(),
                    videos,
                },
            },
            onCompleted: () => {
                refetch();
                refetchAssociatedMaintenances();
                refetchAssociatedMaintenances();
                setIsLoading(false);
                showAlertModal(
                    isRemoveFile
                        ? t('ALERTS.FILE_REMOVED_WITH_SUCCESS_MESSAGE')
                        : t('ALERTS.INSTALLATION_UPDATED_SUCCESSFULLY_MESSAGE'),
                    isRemoveFile
                        ? t('ALERTS.FILE_REMOVED_WITH_SUCCESS_TITLE')
                        : t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
                setIsLoading(false);
            },
        });
    };

    const formSubmitHandler = (data: {
        name: string;
        installationDate: string;
    }) => {
        const filteredInput = {
            name: data.name,
            installationDate: data.installationDate,
            implementationDate: dayjs(datePickerValues).toISOString(),
        };

        updateInstallationRequest(filteredInput);
    };

    const videoFormSubmitHandler = (data: { title: string; url: string }) => {
        if (
            data.url.startsWith('https://www.youtube.com/') ||
            data.url.startsWith('https://youtu.be')
        ) {
            const alreadyExists = installationVideos.find(
                (video) => video.url === data.url,
            );
            if (alreadyExists) {
                showAlertModal(
                    t('ALERTS.VIDEO_ALREADY_EXISTS'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
                return;
            }
            setInstallationVideos([
                ...installationVideos,
                {
                    _id: uuidv4(),
                    title: data.title,
                    url: data.url,
                },
            ]);
            resetVideos();
        } else {
            showAlertModal(
                t('ALERTS.INVALID_YOUTUBE_URL'),
                t('ALERTS.ERROR'),
                AlertModalSeverity.error,
            );
        }
    };

    const addMaintenanceCallback = (cbMaintenances: string[]) => {
        setSelectedMaintenances(cbMaintenances);
    };

    const fetchMaintenances = () => {
        getMaintenanceList({
            variables: {
                filter: {
                    term: '',
                    limit: 0,
                    step: 0,
                },
            },
            onCompleted: () => setIsDialogOpen(true),
        });
    };

    const updateSchedule = (scheduleId: string, input: any) => {
        updateScheduleById({
            variables: {
                scheduleId,
                input,
            },
            onCompleted: () => {
                showAlertModal(
                    t('ALERTS.SCHEDULED_WAS_SUCCESSFULLY_EDITED_MESSAGE'),
                    t('ALERTS.SUCCESS'),
                    AlertModalSeverity.success,
                );
                refetchSchedulesData();
            },
            onError: () => {
                showAlertModal(
                    t('ALERTS.'),
                    t('ALERTS.ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            },
        });

        setIsVideoUploading(false);
    };

    const uploadFile = useCallback(
        async (e: any, type: UploadFileType, scheduleId?: string) => {
            setIsVideoUploading(true);
            setLoadingTechFile(true);

            let file;

            try {
                file = fileHandler({
                    file: e?.target?.files[0],
                    fileType: type,
                });
            } catch (error) {
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
            }

            if (!file) {
                setIsVideoUploading(false);
                setLoadingTechFile(false);
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                );
                return null;
            }

            const res = await uploadFileToServer(file?.file).catch(() =>
                showAlertModal(
                    t('ALERTS.ERRORS_REQUEST_FAILED'),
                    t('ALERTS.ERROR'),
                    AlertModalSeverity.error,
                ),
            );
            if (!res) return;

            setLoadingTechFile(false);

            if (type === UploadFileType.VIDEO && scheduleId) {
                updateSchedule(scheduleId, {
                    report: res.data._id,
                });
                refetchSchedulesData();
            }

            if (type === UploadFileType.TECHNICAL_FILE)
                uploadFilesHandler(res.data._id, UploadFileType.TECHNICAL_FILE);

            if (type === UploadFileType.TECHNICAL_FILE)
                setFiles((prev) => [
                    ...prev,
                    {
                        label: t('INSTALLATIONS.TECHNICAL_FILE_LABEL'),
                        _id: res?.data?._id,
                        uri: res?.data?.uri,
                    },
                ]);
        },
        [],
    );

    function renderSchedulesDataGridActions(
        scheduleId: string,
        reportUrl: string,
        uploadedBy?: string,
    ) {
        const reportDescription = t('GENERAL.REPORT_UPLOADED_BY') + uploadedBy;

        return {
            [SCHEDULES_ACTIONS_CONTENT.CONFIRM]: (
                <>
                    <Button
                        color="error"
                        size="large"
                        onClick={() => {
                            setRenderModalType(MODAL_TYPES.CANCEL_SCHEDULE);
                            setCurrentSelectedScheduleId(scheduleId);
                            setIsDialogOpen(true);
                        }}
                    >
                        {t('GENERAL.CANCEL')}
                    </Button>
                    <Button
                        size="large"
                        onClick={() => {
                            updateSchedule(scheduleId, {
                                status: MAINTENANCE_STATUS.DONE,
                            });
                            refetchAssociatedMaintenances();
                        }}
                    >
                        {t('GENERAL.CONFIRM')}
                    </Button>
                </>
            ),
            [SCHEDULES_ACTIONS_CONTENT.SEND_REPORT]: (
                <Button
                    onClick={() =>
                        setReportsModalState({
                            isVisible: true,
                            scheduleId: scheduleId,
                            reports: reportUrl as any,
                        })
                    }
                >
                    {t('GENERAL.VIEW_REPORT')}
                </Button>
            ),
            [SCHEDULES_ACTIONS_CONTENT.VIEW_REPORT]: (
                <>
                    <Button
                        onClick={() =>
                            setReportsModalState({
                                isVisible: true,
                                scheduleId: scheduleId,
                                reports: reportUrl as any,
                            })
                        }
                    >
                        {t('GENERAL.MANAGE_REPORT')}
                    </Button>
                    <Tooltip title={reportDescription} arrow placement="top">
                        <InfoIcon className={clsx(icon)} fontSize="small" />
                    </Tooltip>
                </>
            ),
        };
    }

    function onDialogPromptClose() {
        setPromptActions((prev: any) => ({
            ...prev,
            isVisible: false,
        }));
    }

    const childRef = useRef();

    function renderModal() {
        return {
            [MODAL_TYPES.ASSOCIATE_MAINTENANCES]: [
                <Button
                    key={uuidv4()}
                    onClick={() =>
                        history.push(`${Routes.MAINTENANCES}/create`)
                    }
                    size="large"
                    startIcon={<AddCircleIcon />}
                >
                    {t('MAINTENANCE.CREATE_NEW_MAINTENANCE')}
                </Button>,
                <AssociateMaintenance
                    key="body"
                    callback={addMaintenanceCallback}
                    maintenances={
                        maintenancesList?.findMaintenances?.maintenances
                    }
                    alreadySelected={
                        installationData?.getInstallationById?.maintenances
                    }
                />,
            ],
            [MODAL_TYPES.CANCEL_SCHEDULE]: [
                <Fragment key={uuidv4()}></Fragment>,
                <Fragment key="modal">
                    <Typography variant="body2">
                        {t('SCHEDULES.CANCEL_SCHEDULE_MESSAGE')}
                    </Typography>
                    <Box sx={{ width: '80%' }}>
                        <TextField
                            label={t('SCHEDULES.CANCEL_REASON')}
                            className={spacingTopSmall3}
                            multiline
                            required
                            fullWidth
                            onChange={(e) => setCancelReason(e.target.value)}
                            rows={4}
                            error={!cancelReason?.length}
                        />
                    </Box>
                </Fragment>,
            ],
            [MODAL_TYPES.USER_INFO]: [
                <Fragment key={uuidv4()}></Fragment>,
                <UserInfoModal
                    key="modal"
                    userId={installationData?.getInstallationById?.user?._id}
                />,
            ],
            [MODAL_TYPES.LOOSE_APPOINTMENTS]: [
                <Fragment key={uuidv4()}></Fragment>,
                <LooseAppointments
                    key="modal"
                    installationId={installationId}
                    ref={childRef}
                    closeModal={() => setIsDialogOpen(false)}
                    refetch={refetchSchedulesData}
                />,
            ],
        };
    }

    const cancelScheduleHandler = (
        scheduleId: string,
        cancelReason?: string,
    ) => {
        setIsDialogOpen(false);
        updateSchedule(scheduleId, {
            status: MAINTENANCE_STATUS.CANCELLED,
            cancelReason: cancelReason
                ? cancelReason
                : t('SCHEDULES.NO_NOTE_REASON'),
        });
    };

    const deleteMaintenanceAssociation = (
        maintenanceId: string,
        installationId: string,
    ) => {
        setPromptActions({
            isVisible: true,
            title: t('PROMPTS.REMOVE_MAINTENANCE_FROM_INSTALLATION_TITLE'),
            message: t('PROMPTS.REMOVE_MAINTENANCE_FROM_INSTALLATION_MESSAGE'),
            onClose: onDialogPromptClose,
            onConfirm: () => {
                removeMaintenanceFromInstallation({
                    variables: {
                        maintenanceId,
                        installationId,
                    },
                    onCompleted: () => {
                        refetch();
                        setDeletedMaintenances([
                            ...deletedMaintenances,
                            maintenanceId,
                        ]);
                        showAlertModal(
                            t('ALERTS.MAINTENANCE_DELETED_MESSAGE'),
                            t('ALERTS.SUCCESS'),
                            AlertModalSeverity.success,
                        );
                        refetchAssociatedMaintenances();
                    },
                    onError: () => {
                        showAlertModal(
                            t('ALERTS.ERRORS_REQUEST_FAILED'),
                            t('ALERTS.ERROR'),
                            AlertModalSeverity.error,
                        );
                    },
                });
            },
        });
    };

    const triggerFormSubmission = async () => {
        setIsDialogOpen(false);
        await handleSubmit(formSubmitHandler as any)();
    };

    const modalContent = () => {
        return {
            [MODAL_TYPES.ASSOCIATE_MAINTENANCES]: {
                func: () => triggerFormSubmission(),
                title: t('MAINTENANCE.ASSOCIATE_NEW_MAINTENANCE'),
            },
            [MODAL_TYPES.CANCEL_SCHEDULE]: {
                func: () =>
                    cancelScheduleHandler(
                        currentSelectedScheduleId,
                        cancelReason,
                    ),
                title: t('SCHEDULES.CANCEL_SCHEDULE'),
            },
            [MODAL_TYPES.USER_INFO]: {
                func: () => setIsDialogOpen(false),
                title: t('GENERAL.USER_INFORMATION'),
            },
            [MODAL_TYPES.LOOSE_APPOINTMENTS]: {
                func: () => {
                    (childRef?.current as any)?.submitForm();
                },
                title: t('GENERAL.CREATE_LOOSE_APPOINTMENT'),
            },
        };
    };

    return (
        <>
            <Modal
                isOpen={isDialogOpen}
                onConfirm={modalContent()[renderModalType].func as () => void}
                close={() => setIsDialogOpen(false)}
                title={modalContent()[renderModalType].title as string}
                hideCancel={renderModalType === MODAL_TYPES.USER_INFO}
            >
                {renderModal()[renderModalType]}
            </Modal>
            <Modal
                isOpen={reportsModalState.isVisible}
                onConfirm={() =>
                    setReportsModalState({
                        isVisible: false,
                        scheduleId: undefined,
                    })
                }
                hideCancel
                title="Reports"
            >
                <UploadButton
                    icon={<FileUploadIcon />}
                    isLoading={isVideoUploading}
                    disabled={
                        reportsModalState?.reports &&
                        reportsModalState.reports?.length >= 3
                    }
                    onChange={async (e: any) => {
                        await uploadFile(
                            e,
                            UploadFileType.VIDEO,
                            reportsModalState.scheduleId,
                        );
                        await refetchSchedules();
                    }}
                >
                    {t('MAINTENANCE.SEND_REPORT')}
                </UploadButton>
                <Reports
                    refetchReports={refetchSchedules}
                    scheduleId={reportsModalState?.scheduleId}
                    reports={reportsModalState?.reports}
                />
            </Modal>

            <SharedHeader
                title={{
                    content: t('GENERAL.INSTALLATION') + headerTitle,
                    variant: 'h4',
                }}
                subtitle={installationData?.getInstallationById?.user?.fullname}
                userInformationCta={() => {
                    setRenderModalType(MODAL_TYPES.USER_INFO);
                    setIsDialogOpen(true);
                }}
            >
                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    form={INSTALLATIONS_FORM_NAMES.FORM_NAME}
                    loading={isLoading}
                >
                    {t('GENERAL.SAVE')}
                </LoadingButton>
            </SharedHeader>
            {displayConditionaly(
                loadingInstallations || loadingMaintenances,
                <SharedLoading />,
                <React.Fragment>
                    <hr className={spacingBottomLarge} />
                    <Grid container className={spacingTopSmall3}>
                        <Grid
                            item
                            className={clsx(
                                spacingBottomLarge,
                                installationInfoWrapper,
                            )}
                        >
                            <form
                                onSubmit={handleSubmit(
                                    formSubmitHandler as any,
                                )}
                                id={INSTALLATIONS_FORM_NAMES.FORM_NAME}
                                className={installationFormWrapper}
                            >
                                <Box className={installationNameWrapper}>
                                    <ControlledInput
                                        name={INSTALLATIONS_FORM_NAMES.NAME}
                                        label={t('GENERAL.NAME')}
                                        control={control}
                                        rules={REQUIRED_FIELD}
                                    />
                                </Box>
                                <Box className={spacingTopSmall3}>
                                    <DesktopDatePicker
                                        label={t(
                                            'INSTALLATIONS.INSTALLATION_DATE',
                                        )}
                                        inputFormat="YYYY/MM/DD"
                                        value={datePickerValues}
                                        onChange={handleDatePickerChange}
                                        renderInput={(params) => (
                                            <ControlledInput
                                                params={params}
                                                name={
                                                    INSTALLATIONS_FORM_NAMES.INSTALLATION_DATE
                                                }
                                                label={t('GENERAL.NAME')}
                                                control={control}
                                                rules={REQUIRED_FIELD}
                                                sx={{ width: '20vw' }}
                                                cValue={datePickerValues?.toString()}
                                            />
                                        )}
                                    />
                                </Box>
                            </form>
                            <ImagePlaceholder
                                image={installationImage}
                                setImage={setInstallationImage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SharedHeader
                                title={{
                                    content: t('INSTALLATIONS.SCHEDULES'),
                                    variant: 'h6',
                                }}
                            >
                                <LoadingButton
                                    variant="outlined"
                                    size="large"
                                    disabled={!isAbleToCreateLooseSchedules}
                                    onClick={() => {
                                        setIsDialogOpen(true);
                                        setRenderModalType(
                                            MODAL_TYPES.LOOSE_APPOINTMENTS,
                                        );
                                    }}
                                >
                                    {t('GENERAL.CREATE_LOOSE_APPOINTMENT')}
                                </LoadingButton>
                            </SharedHeader>
                            <SharedDataGrid
                                rows={schedulesDataGridRows ?? []}
                                columns={schedulesColumns}
                                configuration={{
                                    hideFooter: true,
                                    autoHeight: true,
                                }}
                                className={clsx(
                                    sharedDataGrid,
                                    spacingTopSmall3,
                                )}
                            />
                            <SharedPagination
                                count={nPages(
                                    schedulesData?.findSchedulesByInstallationId
                                        ?.listInfo?.total,
                                    DATA_GRID_LIMIT,
                                )}
                                page={schedulesPagination}
                                onChange={(_, page) =>
                                    setSchedulesPagination(page)
                                }
                            />
                            <Spacer space={6} />
                            <SharedHeader
                                title={{
                                    content: t(
                                        'MAINTENANCE.ASSOCIATED_MAINTENANCES',
                                    ),
                                    variant: 'h6',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={() => {
                                        fetchMaintenances();
                                        setRenderModalType(
                                            MODAL_TYPES.ASSOCIATE_MAINTENANCES,
                                        );
                                    }}
                                >
                                    {t('MAINTENANCE.ASSOCIATE_NEW_MAINTENANCE')}
                                </Button>
                            </SharedHeader>
                            <SharedDataGrid
                                columns={associatedMaintenanceColumns}
                                generateOwnIds
                                className={clsx(
                                    sharedDataGrid,
                                    spacingTopSmall3,
                                )}
                                rows={
                                    maintenancesData?.findMaintenancesByInstallationId ??
                                    []
                                }
                                configuration={{
                                    hideFooter: true,
                                    autoHeight: true,
                                }}
                            />
                            <Spacer space={6} />
                            <SharedHeader
                                title={{
                                    content: t('MAINTENANCE.TECHNICAL_FILES'),
                                    variant: 'h6',
                                }}
                            >
                                <UploadButton
                                    variant="contained"
                                    onChange={(e: any) => {
                                        uploadFile(
                                            e,
                                            UploadFileType.TECHNICAL_FILE,
                                        );
                                    }}
                                >
                                    {t('MAINTENANCE.ADD_NEW_FILE')}
                                </UploadButton>
                            </SharedHeader>

                            {files && files.length > 0 && (
                                <TechnicalFiles
                                    TechFile={files}
                                    removeFile={removeFileHandler}
                                    updateFileLabel={updateFileLabelHandler}
                                />
                            )}
                            {loadingTechFile && <CircularProgress />}
                            <Spacer space={6} />
                            <SharedHeader
                                title={{
                                    content: t('MAINTENANCE.VIDEOS'),
                                    variant: 'h6',
                                }}
                            ></SharedHeader>
                            <form
                                onSubmit={handleSubmitVideos(
                                    videoFormSubmitHandler as any,
                                )}
                                id={INSTALLATIONS_FORM_NAMES.FORM_NAME}
                            >
                                <Box>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <ControlledInput
                                                name={
                                                    INSTALLATIONS_VIDEOS_FORM_NAMES.TITLE
                                                }
                                                label={t('MAINTENANCE.TITLE')}
                                                control={controlVideos}
                                                rules={REQUIRED_FIELD}
                                                className={clsx(
                                                    spacingRightSmall2,
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ControlledInput
                                                name={
                                                    INSTALLATIONS_VIDEOS_FORM_NAMES.URL
                                                }
                                                label={t('MAINTENANCE.URL')}
                                                control={controlVideos}
                                                rules={REQUIRED_FIELD}
                                                className={clsx(
                                                    spacingRightSmall2,
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                size="large"
                                            >
                                                {t('MAINTENANCE.ADD_NEW_VIDEO')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                            >
                                {installationVideos.map((value) => (
                                    <ListItem
                                        key={value?._id}
                                        disableGutters
                                        secondaryAction={
                                            <IconButton
                                                aria-label="comment"
                                                onClick={() => {
                                                    setInstallationVideos(
                                                        installationVideos.filter(
                                                            (video) =>
                                                                video._id !==
                                                                value._id,
                                                        ),
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={value?.title}
                                            secondary={value?.url}
                                            onClick={() =>
                                                window.open(
                                                    value?.url,
                                                    '_blank',
                                                )
                                            }
                                            sx={{ cursor: 'pointer' }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                    <ConfirmationDialog
                        isOpen={promptActions?.isVisible}
                        close={onDialogPromptClose}
                        onConfirm={promptActions?.onConfirm}
                        title={promptActions?.title}
                        message={promptActions?.message ?? ''}
                    />
                </React.Fragment>,
            )}
        </>
    );
};

export { InstallationDetails };
